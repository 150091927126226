import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { ILottiePlayer } from './lottie-player.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-lottie-player',
    templateUrl: './lottie-player.component.html',
    styleUrls: ['./lottie-player.component.scss']
})
export class LottiePlayerComponent implements OnInit, ILottiePlayer {
    @Input() url: string;
    @Input() loop: boolean;
    @Input() width: string;
    @Input() height: string;
    @Input() autoplay: boolean;
    @Input() silentLoad: boolean;
    @Input() showControls: boolean;
    @Input() hideAfterComplete: boolean;

    @Output() onLoad = new EventEmitter<void>();

    anim: AnimationItem | undefined;
    animOptions: AnimationOptions;

    constructor() { }

    ngOnInit(): void {
        this.animOptions = {
            path: this.url,
            loop: this.loop,
            autoplay: this.autoplay,
        };
    }

    play() {
        if (!this.anim)
            return;

        this.anim.play();
    }

    stop() {
        if (!this.anim)
            return;

        this.anim.stop();
    }

    pause() {
        if (!this.anim)
            return;

        this.anim.pause();
    }
}
