import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './pages/login/login.component';
import { UnitsListComponent } from './pages/units/units-list/units-list.component';
import { GoalsListComponent } from './pages/goals/goals-list/goals-list.component';
import { SkillsListComponent } from './pages/skills/skills-list/skills-list.component';
import { UnitsEditorComponent } from './pages/units/units-editor/units-editor.component';
import { GoalsEditorComponent } from './pages/goals/goals-editor/goals-editor.component';
import { ContentListComponent } from './pages/content/content-list/content-list.component';
import { SkillsEditorComponent } from './pages/skills/skills-editor/skills-editor.component';
import { SelAgesListComponent } from './pages/sel-ages/sel-ages-list/sel-ages-list.component';
import { ContentEditorComponent } from './pages/content/content-editor/content-editor.component';
import { SelUnitsListComponent } from './pages/sel-units/sel-units-list/sel-units-list.component';
import { SelAgesEditorComponent } from './pages/sel-ages/sel-ages-editor/sel-ages-editor.component';
import { SelUnitsEditorComponent } from './pages/sel-units/sel-units-editor/sel-units-editor.component';
import { SelLessonsListComponent } from './pages/sel-lessons/sel-lessons-list/sel-lessons-list.component';
import { SelLessonsEditorComponent } from './pages/sel-lessons/sel-lessons-editor/sel-lessons-editor.component';
import { ContentListsListComponent } from './pages/content-lists/content-lists-list/content-lists-list.component';
import { ContentListsEditorComponent } from './pages/content-lists/content-lists-editor/content-lists-editor.component';
import { ContentSeriesEditorComponent } from './pages/content-series/content-series-editor/content-series-editor.component';
import { ContentSeriesListComponent } from './pages/content-series/content-series-list/content-series-list.component';
import { InteractiveActivitiesListComponent } from './pages/interactive-activities/IA-list/interactive-activities-list.component';
import { InteractiveActivitiesEditorComponent } from './pages/interactive-activities/IA-editor/interactive-activities-editor.component';
import { SccFeaturedContentComponent } from './pages/scc-featured-content/scc-featured-content.component';
import { CanDeactivateGuard } from './services/can-deactivate.guard';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '', component: ContentListComponent, canActivate: [AuthGuard] },
    { path: 'interactive-activities', component: InteractiveActivitiesListComponent, canActivate: [AuthGuard] },
    { path: 'goals', component: GoalsListComponent, canActivate: [AuthGuard] },
    { path: 'units', component: UnitsListComponent, canActivate: [AuthGuard] },
    { path: 'skills', component: SkillsListComponent, canActivate: [AuthGuard] },
    { path: 'contents', component: ContentListComponent, canActivate: [AuthGuard] },
    { path: 'sel-ages', component: SelAgesListComponent, canActivate: [AuthGuard] },
    { path: 'sel-units', component: SelUnitsListComponent, canActivate: [AuthGuard] },
    // { path: 'mini-games', component: MiniGamesListComponent, canActivate: [AuthGuard] }, -- disable mini games route for now
    { path: 'goals/:goalId', component: GoalsEditorComponent, canActivate: [AuthGuard] },
    { path: 'units/:unitId', component: UnitsEditorComponent, canActivate: [AuthGuard] },
    { path: 'sel-lessons', component: SelLessonsListComponent, canActivate: [AuthGuard] },
    { path: 'skills/:skillId', component: SkillsEditorComponent, canActivate: [AuthGuard] },
    { path: 'sel-ages/:ageId', component: SelAgesEditorComponent, canActivate: [AuthGuard] },
    { path: 'saved-lists', component: ContentListsListComponent, canActivate: [AuthGuard] },
    { path: 'sel-units/:unitId', component: SelUnitsEditorComponent, canActivate: [AuthGuard] },
    { path: 'content-series', component: ContentSeriesListComponent, canActivate: [AuthGuard] },
    // { path: 'mini-games/:gameId', component: MiniGamesEditorComponent, canActivate: [AuthGuard] }, -- disable mini games route for now
    { path: 'sel-lessons/:lessonId', component: SelLessonsEditorComponent, canActivate: [AuthGuard] },
    { path: 'contents/:contentType/:contentId', component: ContentEditorComponent, canActivate: [AuthGuard] },
    { path: 'saved-lists/:contentListId', component: ContentListsEditorComponent, canActivate: [AuthGuard] },
    { path: 'content-series/:contentSeriesId', component: ContentSeriesEditorComponent, canActivate: [AuthGuard] },
    { path: 'interactive-activities/:activityType/:activityId', component: InteractiveActivitiesEditorComponent, canActivate: [AuthGuard] },
    { 
        path: 'scc-featured-content',
        component: SccFeaturedContentComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
    },
    { path: 'interactive-vcw', loadChildren: () => import('./interactive-center/components/vcw/vcw.module').then(m => m.VcwModule) },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
