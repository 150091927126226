import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        SharedModule,
        ButtonModule,
        TextfieldModule
    ],
})
export class LoginModule { }