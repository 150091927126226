<div class="content" [ngClass]="{'opacity-5': loading}">
    <app-loader *ngIf="loading"></app-loader>
    <label *ngIf="label">{{label}}</label>
    <div *ngIf="(!selectedPicker || selectedPicker === 'content') && !content" class="selectors">
        <app-button
            type="primary"
            (click)="showLinkPicker()"
            iconUrl="../../../../assets/images/icons/link-tilted.svg"
        >
            External Link
        </app-button>
        <div>or</div>
        <app-button
            type="primary"
            (click)="showContentPicker()"
            iconUrl="../../../../assets/images/icons/folder.svg"
        >
            Select Content
        </app-button>
    </div>
    <div *ngIf="selectedPicker === 'link'" class="link-picker">
        <app-textfield
            size="small"
            label="URL"
            [value]="link"
            inlineLabel="true"
            (valueChange)="onLinkChange($event)"
        ></app-textfield>
        <app-button
            type="danger"
            [loading]="deleting"
            (click)="selectedPicker = undefined; link = undefined; onLinkChange(null)"
            iconUrl="../../../../assets/images/icons/delete.svg"
        >
            Remove
        </app-button>
    </div>
    <div *ngIf="content" class="content-picker">
        <app-textfield
            size="small"
            disabled="true"
            inlineLabel="true"
            label="Activity Type"
            [(value)]="content.interactiveActivityTypeTitle"
        ></app-textfield>
        <app-textfield
            size="small"
            disabled="true"
            inlineLabel="true"
            label="Activity Title"
            [(value)]="content.title"
        ></app-textfield>
        <app-button
            type="danger"
            [loading]="deleting"
            (click)="removeLinkedIa()"
            iconUrl="../../../../assets/images/icons/delete.svg"
        >
            Remove
        </app-button>
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>
</div>
<div class="overlay" *ngIf="selectedPicker === 'content'">
    <div class="modal-content">
        <div class="title">Interactive Activities</div>
        <app-select
            size="small"
            inlineLabel="true"
            label="Activity Type"
            [options]="typeOptions"
            (valueChange)="onTypeChange($event)"
        ></app-select>
        <div class="content">
            <list-toolbar 
                searchSize="small"
                showCancelButton="true"
                (onSearchChange)="onSearchChange($event)"
                (onCancelCallback)="selectedPicker = undefined;cleanIAListVariables()"
            ></list-toolbar>
            <ng-container *ngIf="!loading && activitiesList">
                <div class="ia-activity">

                <list-view 
                    [headers]="headersIa" 
                    [sorting]="sorting"
                    (onSortChange)="onSortChange($event)" 
                >
                    <div *ngFor="let ia of activitiesList.interactiveActivities;let i = index" class="row">
                        <div>{{ia.title}}</div>
                        <div>{{ia.interactiveActivityTypeTitle}}</div>
                        <div>{{ia.updatedAt}}</div>
                        <div>{{ia.createdAt}}</div>
                        <div class="linked-content"> 
                            <div class="copy-field">
                                <app-textfield [readonly]="true" [disabled]="!ia.contentTitle" [value]="ia.contentTitle ? ia.contentTitle : 'None'"
                                    size="small" iconUrl="../../../../assets/images/icons/redirect-link.svg"
                                    [iconLinkUrl]="ia.contentTitle ? ['/contents/' + ia.contentTypeSlug] + '/' + ia.contentId : ''">
                                </app-textfield>
                            </div>
                        </div>
                        <div title="Select Content">
                            <button (click)="onSelectContent(ia)">
                                <img src="../../../../assets/images/icons/add-content.svg" alt="add" />
                            </button>
                        </div>
                    </div>
                </list-view>
            </div>

            </ng-container>
            <app-loader *ngIf="loading"></app-loader>
            <list-paginator 
                [currentPage]="currentPage"
                [class]="loading ? 'hidden' : ''"
                (onPageChange)="onPageChange($event)"
                [totalPages]="activitiesList?.totalPages" 
            ></list-paginator>
        </div>
    </div>
</div>