import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { ListParams } from "../models/list-params/list-params.model";
import { CurriculumList } from "../models/curriculum/curriculum-list.model";

@Injectable({
    providedIn: 'root'
})
export class CurriculumService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<CurriculumList> {
        return this.http.get('curriculums', params);
    }

    get(curriculumId:string):Observable<any> {
        return this.http.get('curriculums/' + curriculumId);
    }
}
