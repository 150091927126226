import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {
    @Input() value = "";
    @Input() label:string;
    @Input() error:string;
    @Input() type = "text";
    @Input() iconUrl:string;
    @Input() disabled:boolean;
    @Input() placeholder = "";
    @Input() feedback:"success"|"info"|"warning"|"error";

    @Output() valueChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    onColorChange($event) {
        this.valueChange.emit($event);
    }
}
