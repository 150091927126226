import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { PlanList } from "../models/plan/plan-list.model";
import { PlanListParams } from "../models/plan/plan-list-params.model";

@Injectable({
    providedIn: 'root'
})
export class PlanService {
    constructor(private http:HttpService) { }

    list(params?:PlanListParams):Observable<PlanList> {
        return this.http.get('plans', params);
    }

    get(planId:string):Observable<any> {
        return this.http.get('plans/' + planId);
    }
}
