<div class="content {{layout}}">
    <label *ngIf="label">{{label}}</label>
    <div *ngIf="!content || !content.contentId" class="selectors">
        <app-button
            type="primary"
            (click)="pickerOpened = true"
            iconUrl="../../../../assets/images/icons/folder.svg"
        >
            Select Content
        </app-button>
        <div class="error-message" *ngIf="error">{{error}}</div>
    </div>
    <div *ngIf="content?.contentId" class="content-picker">
        <app-textfield
            disabled="true"
            label="Content Type"
            [(value)]="content.type"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            label="Content Title"
            [(value)]="content.title"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            *ngIf="content.url"
            label="Content Link"
            [(value)]="content.url"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-button
            type="danger"
            [loading]="deleting"
            iconUrl="../../../../assets/images/icons/delete.svg"
            (click)="onContentChange(undefined); pickerOpened = false"
        >
            Remove
        </app-button>
    </div>
</div>
<div class="overlay" *ngIf="pickerOpened">
    <div class="modal-content">
        <div class="title">{{title}}</div>
        <app-select
            size="small"
            label="Content type"
            [inlineLabel]="true"
            [(value)]="selectedContentType"
            [options]="availableContentTypes"
        ></app-select>
        <app-content-picker
            [content]="content"
            [pickerOpened]="true"
            *ngIf="selectedContentType === 'mc'"
            (onCancelCallback)="pickerOpened = false"
            (contentChange)="onContentChange($event, 'content')"
        ></app-content-picker>
        <app-mini-game-picker
            [pickerOpened]="true"
            [miniGame]="miniGame"
            *ngIf="selectedContentType === 'mg'"
            (onCancelCallback)="pickerOpened = false"
            (miniGameChange)="onContentChange($event, 'minigame')"
        ></app-mini-game-picker>
    </div>
</div>