<app-header>
    All Content
</app-header>
<div class="content" data-cy="content-list">
    <list-toolbar 
        newItemButtonLabel="New Content"
        [newItemOptions]="newContentOptions"
        (onSearchChange)="onSearchChange($event)"
    >
    </list-toolbar>
    <ng-container *ngIf="!loading && contentsList">
        <list-view 
            [headers]="headers" 
            [sorting]="sorting"
            (onSortChange)="onSortChange($event)" 
        >
            <div *ngFor="let c of contentsList.contents;let i = index" class="row">
                <div style="width: 120px; height: 80px;"><img [src]="c.thumbnail" /></div>
                <div><span *ngIf="isSmallScreen" [@expandCollapse]="isHovered[i] ? 'expanded' : 'collapsed'"
                          (mouseenter)="toggleHover(i)"
                          (mouseleave)="toggleHover(i)">{{c.title}}</span>
                          <span *ngIf="!isSmallScreen">{{c.title}}</span>
                </div>
                <div>{{c.type}}</div>
                <div>{{c.releaseDate}}</div>
                <div>
                    <div class="copy-field">
                        <app-textfield readonly="readonly" [value]="c.url">
                        </app-textfield>
                        <copy-to-clipboard [value]="c.url">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13V12C9 10.346 10.346 9 12 9H13V5.667C13 5.299 12.701 5 12.333 5H5.667C5.299 5 5 5.299 5 5.667V12.333C5 12.701 5.299 13 5.667 13H9ZM9 15H5.667C4.196 15 3 13.804 3 12.333V5.667C3 4.196 4.196 3 5.667 3H12.333C13.804 3 15 4.196 15 5.667V9H18C19.654 9 21 10.346 21 12V18C21 19.654 19.654 21 18 21H12C10.346 21 9 19.654 9 18V15ZM11 12C11 11.449 11.449 11 12 11H18C18.552 11 19 11.449 19 12V18C19 18.551 18.552 19 18 19H12C11.449 19 11 18.551 11 18V12Z"/>
                            </svg>
                        </copy-to-clipboard>
                    </div>
                </div>
                <div>
                    <img *ngIf="c.active == 'true'" src="../../../../assets/images/icons/status-active.svg" />
                    <img *ngIf="c.active == 'false'" src="../../../../assets/images/icons/status-inactive.svg" />
                </div>
                <div class="list-actions">
                    <a routerLink="/contents/{{c.typeSlug}}/{{c.contentId}}" title="Edit Content" data-cy="edit-content-btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0187 10.6787L13.3237 7.98367L15.2717 6.03467L17.9657 8.72867L16.0187 10.6787ZM9.07965 17.6247L6.10265 17.8957L6.36665 14.9397L11.9837 9.32267L14.6797 12.0187L9.07965 17.6247ZM19.4037 7.33767L19.4027 7.33667L16.6647 4.59867C15.9237 3.85967 14.6507 3.82467 13.9487 4.52967L4.95265 13.5257C4.62665 13.8507 4.42465 14.2827 4.38265 14.7397L4.00365 18.9097C3.97765 19.2047 4.08265 19.4967 4.29265 19.7067C4.48165 19.8957 4.73665 19.9997 4.99965 19.9997C5.03065 19.9997 5.06065 19.9987 5.09065 19.9957L9.26065 19.6167C9.71865 19.5747 10.1497 19.3737 10.4747 19.0487L19.4717 10.0517C20.1997 9.32167 20.1687 8.10367 19.4037 7.33767Z" />
                        </svg>
                    </a>
                    <app-spinner color="#8F9BB3" *ngIf="deletingContentId === c.contentId"></app-spinner>
                    <button (click)="confirmDelete(c.contentId, c.title)" *ngIf="deletingContentId !== c.contentId" data-cy="delete-content-btn">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 14C14 14.55 13.55 15 13 15C12.45 15 12 14.55 12 14V10C12 9.45 12.45 9 13 9C13.55 9 14 9.45 14 10V14ZM8 2.328C8 2.173 8.214 2 8.5 2H11.5C11.786 2 12 2.173 12 2.328V4H8V2.328ZM8 14C8 14.55 7.55 15 7 15C6.45 15 6 14.55 6 14V10C6 9.45 6.45 9 7 9C7.55 9 8 9.45 8 10V14ZM19 4H18H14V2.328C14 1.044 12.879 0 11.5 0H8.5C7.121 0 6 1.044 6 2.328V4H2H1C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6H2V17C2 18.654 3.346 20 5 20H15C16.654 20 18 18.654 18 17V6H19C19.55 6 20 5.55 20 5C20 4.45 19.55 4 19 4Z"/>
                        </svg>                         
                    </button>
                </div>
            </div>
        </list-view>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
    <list-paginator 
        [currentPage]="currentPage"
        [class]="loading ? 'hidden' : ''"
        (onPageChange)="onPageChange($event)"
        [totalPages]="contentsList?.totalPages" 
    ></list-paginator>
</div>
