import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-list-item-picker',
    templateUrl: './list-item-picker.component.html',
    styleUrls: ['./list-item-picker.component.scss']
})
export class ListItemPickerComponent {
    @Input() label:string;
    @Input() error:string;
    @Input() selectedOptions:Array<string>;
    @Input() layout:"default"|"alt" = "default";
    @Input() options:Array<{ id:string, label:string }>;
    @Input() groupedOptions:Array<{ 
        header:string, 
        options:Array<{ id:string, label:string }> 
    }>;

    @Output() selectedOptionsChange = new EventEmitter<Array<string>>();
    
    constructor() { }

    onChange(id:string) {
        if (!this.selectedOptions)
            this.selectedOptions = [];

        var index = this.selectedOptions.indexOf(id);
        if (index > -1)
            this.selectedOptions.splice(index, 1);
        else 
            this.selectedOptions.push(id);
        
        this.selectedOptionsChange.emit(this.selectedOptions);
    }

    optionIsChecked(id:string) {
        if (!this.selectedOptions)
            return false;

        return this.selectedOptions.includes(id);
    }
}
