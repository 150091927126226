import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
    @Input() headers;
    @Input() sorting:boolean;

    @Output() onSortChange = new EventEmitter<{ key?:string, direction?:boolean }>();

    currentSorting:{ key?:string, asc?:boolean } = {};

    constructor() { }

    ngOnInit(): void {
    }

    sortBy(key:string) {
        if (this.currentSorting.key == key)
            this.currentSorting.asc = !this.currentSorting.asc;
        else
            this.currentSorting = { key };

        this.onSortChange.emit(this.currentSorting);

    }
}
