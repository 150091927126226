<app-header>
    <a routerLink="/sel-lessons">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!lesson.lessonId ? "New" : ""}} SEL Lesson</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <div class="tabs">
        <button 
            (click)="activeTab = 0"
            [class]="activeTab === 0 ? 'active' : ''"
        >
            Lesson Info
        </button>
        <button
            (click)="activeTab = 1" 
            [class]="activeTab === 1 ? 'active' : ''"
        >
            Teaching Tools
        </button>
        <button
            (click)="activeTab = 2" 
            [class]="activeTab === 2 ? 'active' : ''"
        >
            Lesson Content
        </button>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngIf="activeTab === 0">
            <div class="flex-row">
                <app-select
                    size="small"
                    label="SEL Age"
                    [options]="availableAges"
                    [(value)]="lesson.unit.ageId"
                    (valueChange)="onAgeChange($event)"
                ></app-select>
                <app-select
                    size="small"
                    label="SEL Unit"
                    [(value)]="lesson.unit.unitId"
                    [options]="availableUnits"
                    (valueChange)="onUnitChange($event)"
                    [error]="emptyMandatoryFields.includes('unitId') ? 'This field is required.' : ''"
                ></app-select>
                <app-select
                    size="small"
                    [(value)]="lesson.order"
                    [options]="availableOrders"
                    label="Order in Parent Unit"
                ></app-select>
            </div>
            <div class="flex-row">
                <div class="column">
                    <app-textfield
                        size="small"
                        label="Title Prefix"
                        [(value)]="lesson.prefix"
                        [error]="emptyMandatoryFields.includes('titlePrefix') ? 'This field is required.' : ''"
                    ></app-textfield>
                    <app-textfield
                        label="Lesson title"
                        [(value)]="lesson.title"
                        [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                    ></app-textfield>
                    <app-textfield
                        label="Guiding question"
                        [(value)]="lesson.guidingQuestion"
                        [error]="emptyMandatoryFields.includes('guidingQuestion') ? 'This field is required.' : ''"
                    ></app-textfield>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab === 1">
            <div class="flex-row">
                <div class="column">
                    <app-richtextarea
                        label="Lesson goal"
                        placeholder="Place your text"
                        [(value)]="lesson.learningStandardGoal"
                        [error]="emptyMandatoryFields.includes('learningStandardGoal') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                    <app-richtextarea
                        label="Learning standard"
                        placeholder="Place your text"
                        [(value)]="lesson.learningStandardText"
                        [error]="emptyMandatoryFields.includes('learningStandardText') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                    <app-richtextarea
                        placeholder="Place your text"
                        label="Standardized benchmarks"
                        [(value)]="lesson.learningStandardBenchmark"
                        [error]="emptyMandatoryFields.includes('learningStandardBenchmark') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                    <app-richtextarea
                        placeholder="Place your text"
                        [(value)]="lesson.strategies"
                        label="Embedded SEL Strategies"
                        [error]="emptyMandatoryFields.includes('strategies') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                    <app-richtextarea
                        label="How to Teach"
                        placeholder="Place your text"
                        [(value)]="lesson.howToTeach"
                        [error]="emptyMandatoryFields.includes('howToTeach') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                </div>
                <div class="column">
                    <app-file-picker
                        type="pdf"
                        id="lesson-plan-pdf"
                        label="Full Lesson Plan"
                        [value]="lesson.lessonPlan"
                        (fileChange)="lesson.lessonPlanFile = $event; !$event && lesson.lessonPlan = ''"
                        [error]="emptyMandatoryFields.includes('lessonPlan') ? 'This field is required.' : ''"
                    ></app-file-picker>
                    <h3 class="radio-section-title">Using Skill Review Or Implementation Guide?</h3>
                    <app-radio-btn 
                        radioId="skill" 
                        [selected]="radioOptionSelected == 'skill'"
                        radioLabel="Skill Review" 
                        (radioSelectedData)="radioBtnEmmited($event)"></app-radio-btn>
                    <app-radio-btn 
                        radioId="implementation"
                        [selected]="radioOptionSelected == 'implementation'"
                        radioLabel="Implementation Guide"
                        (radioSelectedData)="radioBtnEmmited($event)"></app-radio-btn>
                    <app-radio-btn 
                        radioId="none"
                        [selected]="radioOptionSelected == 'none'"
                        radioLabel="None"
                        (radioSelectedData)="radioBtnEmmited($event)"></app-radio-btn>
                    <app-file-picker
                        type="pdf"
                        id="skill-review-pdf"
                        label="Skill Review/implementation guide (pdf) (Optional)"
                        [value]="lesson.skillReviewImplementationFile"
                        (fileChange)="lesson.skillReviewPdfFile = $event; !$event && lesson.skillReviewImplementationFile = ''"
                        [isDisabled]="radioOptionSelected == 'none'"
                    ></app-file-picker>
                    <app-tagfield
                        label="Key Terms"
                        [(values)]="lesson.keyTerms"
                        [error]="emptyMandatoryFields.includes('keyTerms') ? 'This field is required.' : ''"
                    ></app-tagfield>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab === 2">
            <app-wtd-section
                type="step"
                viewMode="steps"
                [whatToDo]="lesson"
                [errors]="emptyMandatoryFields"
                [numberOfFields]="StepsFieldsCount"
                (whatToDoChange)="onStepsChange($event)"
                [mainError]="emptyMandatoryFields.includes('steps') ? 'At least one field is required.' : ''"
            ></app-wtd-section>
        </div>
        <div class="dangerous-area" *ngIf="lesson.lessonId">
            <app-button
                type="danger"
                [loading]="deleting"
                (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
