import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { User } from "../models/user/user.model";
import { StorageService } from './storage.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    @Output() userChange = new EventEmitter<boolean>();

    private jwtHelper:JwtHelperService;
    private user: BehaviorSubject<any>;

    constructor(
        private http:HttpService, 
        private storage:StorageService
    ) {
        this.jwtHelper = new JwtHelperService();
        this.user = new BehaviorSubject({} as any);
    }

    isLogged = ():boolean => !!this.storage.getToken();

    clearUser() {
        this.user = new BehaviorSubject({} as any);
        this.storage.removeToken();
    }

    getUserDetail(objectId) {
        return this.http.get('users/' + objectId);
    }

    getLocalUser():User {
        return this.jwtHelper.decodeToken(this.storage.getToken());
    }
}
