<div 
    class="overlay"
    [hidden]="!alertDialog.type && !confirmDialog.type && 
        !(informativeModal?.loadingDialog?.type || informativeModal?.successDialog?.type || informativeModal?.errorDialog?.type)"
>
    <div class="dialog alert" [ngClass]="alertDialog.type" [hidden]="!alertDialog.type">
        <div data-cy="alert-dialog-message">{{alertDialog.message}}</div>
        <app-button 
            (click)="close()"
            [iconUrl]="getIconUrl()"
            [type]="alertDialog.type || ''"
            data-cy="dialog-ok-btn"
        >
            OKAY
        </app-button>
    </div>

    <div class="dialog confirm" [ngClass]="confirmDialog.type" [hidden]="!confirmDialog.type">
        <div
            class="header" 
            *ngIf="confirmDialog.headerMessage"
        >
            {{confirmDialog.headerMessage}}
        </div>
        <p class="warning-message">{{ confirmDialog.warningMessage }}</p>
        <div class="content">
            <img 
                *ngIf="confirmDialog.dialogIconUrl" 
                [src]="confirmDialog.dialogIconUrl" 
                alt="ico" 
            />
            <div class="message-container">
                <div
                    *ngIf="confirmDialog.title"
                    class="title" 
                    data-cy="confirm-dialog-title"
                >
                    {{confirmDialog.title}}
                </div>
                <div
                    *ngIf="confirmDialog.message"
                    class="message"
                    data-cy="confirm-dialog-message"
                >
                    {{confirmDialog.message}}
                </div>
                <div class="buttons" [ngClass]="{'mt-button': !confirmDialog.message}">
                    <app-button 
                        (click)="confirm()"
                        [type]="confirmDialog.type || 'primary'"
                        [iconUrl]="confirmDialog.confirmButtonIconUrl"
                        data-cy="confirm-dialog-btn"
                    >
                        {{confirmDialog.confirmButtonLabel}}
                    </app-button>
                    <app-button 
                        type="warning"
                        (click)="close()"
                        iconUrl="../../../../assets/images/icons/cancel.svg"
                    >
                        {{confirmDialog.cancelLabel}}
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div class="informative-modal loading" [hidden]="!informativeModal?.loadingDialog?.type">
        <h3 class="dialog-title" data-cy="loading-dialog-title">Save in progress</h3>
        <h3 class="dialog-message">{{informativeModal.loadingDialog?.message}}</h3>
        <app-loader></app-loader>
    </div>

    <div class="informative-modal success" [hidden]="!informativeModal?.successDialog?.type">
        <div class="title-icon">
            <h3 class="dialog-title" data-cy="dialog-title">Saved sucessfully</h3>
            <img 
                src="../../../assets/images/icons/dark-green-success-mark.svg"
                width="30"
                height="30"
                aria-label="success mark icon inside a dark green circle"
            />
        </div>
        <h3 *ngIf="informativeModal.successDialog?.message" class="dialog-message">{{informativeModal.successDialog?.message}}</h3>
        <app-button
            type="success"
            iconUrl="../../../assets/images/icons/check-mark.svg"
            (click)="close()"
        >OKAY
        </app-button>
    </div>

    <div class="informative-modal error" [hidden]="!informativeModal?.errorDialog?.type">
        <div class="title-icon">
            <h3 *ngIf="!informativeModal.errorDialog?.title" class="dialog-title" data-cy="dialog-title">Unable to save</h3>
            <h3 *ngIf="informativeModal.errorDialog?.title" class="dialog-title" data-cy="dialog-title">{{informativeModal.errorDialog?.title}}</h3>
            <img 
                src="../../../assets/images/icons/dark-red-error-mark.svg"
                width="30"
                height="30"
                aria-label="error mark icon inside a dark red circle"
            />
        </div>
        <h3 *ngIf="informativeModal.errorDialog?.message" class="dialog-message">{{informativeModal.errorDialog?.message}}</h3>
        <app-button
            type="danger"
            iconUrl="../../../assets/images/icons/check-mark.svg"
            (click)="close()"
            data-cy="modal-error-confirm-btn"
        >OKAY
        </app-button>
    </div>
</div>