import { InteractiveActivityType } from './interactive-activity-type.model';

export interface InteractiveActivity {
  title: string;
  description: string;
  instructions?: string;
  linkedContent: string;
  pdfUrl?: string;
  contentId?: string;
  contentTitle?: string;
  contentUrl?: string;
  contentTypeSlug?: string;
  createdAt?: string;
  createdBy?: string;
  interactiveActivityTypeTitle?: string;
  interactiveActivityUrl?: string;
  interactiveWorksheetsUrl?: string;
  updatedAt?: string;
  interactiveActivityTypeId?: string;
  interactiveActivityId?: string;
  configuration?: ActivityTypes;
}

export interface InteractiveActivityResponse {
    interactiveActivity: InteractiveActivity;
}

interface ActivityTypes {
  activityQa?: InteractiveActivityQa;
  activityFillBlanks?: InteractiveActivityFillBlanks;
  activitySpinner?: InteractiveActivitySpinner;
  activityMatching?: InteractiveActivityMatching;
  activitySorting?: InteractiveActivitySorting;
  activityFlashcards?: InteractiveActivityFlashCards;
  activityDiceRoller?: InteractiveActivityDiceRoller;
  activityMultipleChoice?: InteractiveActivityMultipleChoice;
  instructions?: string;
}

export interface ActivitiesResponseList {
  interactiveActivities: Array<ActivitiesData>;
  totalPages: number;
  totalResults: number;
}

export interface ActivityTypesResponse {
  interactiveActivityTypes: Array<{
    createdAt: string;
    createdBy: string;
    interactiveActivityTypeId: string;
    interactiveTypeUrl: string;
    title: string;
    updatedAt: string;
  }>;
  totalPages: number;
  totalResults: number;
}

interface ActivitiesData {
  contentId: string;
  contentTitle: string;
  createdAt: string;
  createdBy: string;
  interactiveActivityId: string;
  interactiveActivityTypeId: string;
  interactiveActivityTypeTitle: string;
  title: string;
  updatedAt: string;
}

export interface InteractiveActivityQa {
  items: Array<{ question: string }>;
  reviewPrompt: string;
  instructions: string;
}

export interface InteractiveActivityFillBlanks {
  items: Array<{
    beforeKeyword: string;
    keyword: string;
    afterKeyword: string;
  }>;
  isAutoCheck: boolean;
}

export interface InteractiveActivitySpinner {
  items: Array<{ scenario: string }>;
  reviewPrompt: string;
  instructions: string;
}

export interface InteractiveActivityMatching {
  items: Array<{
    left: string;
    right: string;
  }>;
  leftCategoryTitle: string;
  rightCategoryTitle: string;
  isAutoCheck: boolean;
}

export interface InteractiveActivitySorting {
  items: Array<{
    left: string;
    right: string;
  }>;
  leftCategoryTitle: string;
  rightCategoryTitle: string;
  isAutoCheck: boolean;
}

export interface InteractiveActivityFlashCards {
    items: Array<{
        frontTitle: string;
        frontImage: string;
        frontImageFile?: File;
        frontText: string;
        backTitle: string;
        backImage: string;
        backImageFile?: File;
        backText: string;
        frontTempImage?: string;
        backTempImage?: string;
    }>;
}

export interface InteractiveActivityDiceRoller {
    items: Array<{
        prompt: string;
        image: string;
        iconImageFile?: File;
    }>;
}

export interface InteractiveActivityMultipleChoice {
  items: Array<{
    prompt: string;
    answers: Array<{ value: string; isCorrect: boolean }>;
  }>;
}

export interface IATypes {
  id: number;
  label: string;
}

export enum InteractiveTypes {
  'qa' = 'Q&A',
  'fill-in-the-blanks' = 'Fill in the Blanks',
  'matching' = 'Matching',
  'spinner' = 'Spinner',
  'sorting' = 'Sorting',
  'flashcards' = 'Flashcards',
  'dice-roller' = 'Dice Roller',
  'multiple-choice' = 'Multiple Choice',
  'mixed-activity' = 'Mixed Activity',
}

export enum InteractiveTypesFilter {
  'Q&A' = 'qa',
  'Fill in the blanks' = 'fill-in-the-blanks',
  'Matching' = 'matching',
  'Spinner' = 'spinner',
  'Sorting' = 'sorting',
  'Flashcards' = 'flashcards',
  'Dice Roller' = 'dice-roller',
  'Multiple Choice' = 'multiple-choice',
}

export interface ActivityType { 
    interactiveActivityType: {
        configurationSchema: {
            title: string;
            description: string;
            type: string;
            additionalProperties: boolean;
            properties: {},
            required: []
        }
        createdAt: string;
        createdBy: string;
        interactiveActivityTypeId: string;
        interactiveTypeUrl: string;
        slug: string;
        title: string;
        updatedAt: string;
        worksheetCreationCharacteristics: string;
    }
}

export class MaxCharsObject {
    isMaxCharsReached: boolean;
    fieldName: string;
}
