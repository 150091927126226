import { Component, Input, OnInit } from '@angular/core';
import { ActivityTypesResponse } from 'src/app/models/interactive-activity/interactive-activity.model';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { InteractiveActivityTypesService } from 'src/app/services/interactive-activity-types.service';

@Component({
  selector: 'app-ia-types',
  templateUrl: './interactive-activity-types.component.html',
  styleUrls: ['./interactive-activity-types.component.scss'],
})
export class InteractiveActivityTypesComponent implements OnInit {
  constructor(private iaTypesService: InteractiveActivityTypesService) {}

  activityTypesHeader = [
    { label: 'Name', key: 'name', sortable: false },
    { label: 'Date Created', key: 'dateCreated', sortable: false },
    { label: 'Activity Build URL', key: 'activityUrl', sortable: false },
  ];
  activitiesList: ActivityTypesResponse;
  loading: boolean;
  searchTerm: string;
  currentPage = 1;
  sorting = false;
  sortingParams: { key?: string; asc?: boolean };

  ngOnInit(): void {
    this.loadInteractiveActivityTypes();
  }

  loadInteractiveActivityTypes(hideLoader?: boolean) {
    var params: ListParams = { page: this.currentPage };
    if (this.searchTerm) params.text = this.searchTerm;

    if (this.sortingParams) {
      params.orderBy = this.sortingParams.key;
      params.sort = this.sortingParams.asc ? 'asc' : 'desc';
    }

    if (!hideLoader) this.loading = true;

    this.iaTypesService.list().subscribe((iaTypes: ActivityTypesResponse) => {
      iaTypes.interactiveActivityTypes.forEach((iaType) => {
        iaType.createdAt = new Date(iaType.createdAt).toLocaleDateString(
          'en-US'
        );
      });
      this.activitiesList = iaTypes;
      this.loading = false;
      this.sorting = false;
    });
  }

  onSearchChange(term: string) {
    this.searchTerm = term;
    this.currentPage = 1;
    this.loadInteractiveActivityTypes();
  }

  onPageChange(newPage: number) {
    this.currentPage = newPage;
    this.loadInteractiveActivityTypes();
  }

  onSortChange(params: { key: string; asc: boolean }) {
    this.sorting = true;
    this.sortingParams = params;
    this.loadInteractiveActivityTypes(true);
  }
}
