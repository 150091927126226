<div class="label">Page</div>
<app-textfield 
    size="tiny" 
    type="number" 
    [readonly]="true"
    [value]="currentPage"
    data-cy="current-page"
>
</app-textfield>
<div class="label">of {{totalPages}}</div>
<div class="buttons">
    <button 
        class="prev active" 
        title="Previous page" 
        (click)="setPage(currentPage - 1)"
        [disabled]="currentPage <= 1"
        data-cy="prev-page-btn"
    >
    </button>
    <button 
        title="Next page"
        class="next active" 
        (click)="setPage(currentPage + 1)"
        [disabled]="currentPage >= totalPages"
        data-cy="next-page-btn"
    >
    </button>
</div>
