<div class="content">
    <label *ngIf="label">{{label}}</label>
    <div class="input-container">
        <input
            [value]="value"
            cpPosition="right"
            cpOutputFormat="hex"
            [(colorPicker)]="value" 
            [placeholder]="placeholder"
            [ngClass]="error ? 'error' : ''"
            (colorPickerChange)="onColorChange($event)"
        />
        <div [style.background]="value" class="current-color"></div>
        <div class="error-message" *ngIf="error">{{error}}</div>
    </div>
</div>