<app-header>
    <a routerLink="/goals">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!goal.goalId ? "New" : ""}} Social Communication Goal</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <app-select
                size="small"
                label="Parent Skill"
                [(value)]="goal.skillId"
                [options]="availableSkills"
                (valueChange)="onSkillChange($event)"
            ></app-select>
            <app-select
                size="small"
                [(value)]="goal.order"
                [options]="availableOrders"
                label="Order in Parent Skill"
            ></app-select>
        </div>
        <div class="flex-row">
            <div class="column">
                <app-textfield
                    label="Goal title"
                    [(value)]="goal.title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
                <app-textfield
                    label="Goal secondary title (optional)"
                    [(value)]="goal.subtitle"
                ></app-textfield>
                <app-richtextarea
                    label="Goal description"
                    placeholder="Place your text"
                    [(value)]="goal.description"
                ></app-richtextarea>
                <app-tagfield
                    label="Tags"
                    [(values)]="goal.tags"
                ></app-tagfield>
            </div>
            <div class="column">
                <div class="thumb-preview">
                    <video *ngIf="goal.videoLink" [src]="goal.videoLink" controls></video>
                    <img *ngIf="!goal.videoLink" src="../../../../assets/images/icons/video-placeholder.svg" />
                </div>
                <app-file-picker
                    id="video"
                    type="video"
                    [value]="goal.videoLink"
                    label="Britt's tips video"
                    (fileChange)="goal.videoFile = $event; !$event && goal.videoLink = ''"
                ></app-file-picker>
                <app-file-picker
                    id="image"
                    type="image"
                    [value]="goal.videoThumbnail"
                    label="Britt's Tips Video Thumbnail"
                    (fileChange)="goal.videoThumbnailFile = $event; !$event && goal.videoThumbnail = ''"
                    [error]="emptyMandatoryFields.includes('videoThumbnail') ? 'This field is required.' : ''"
                ></app-file-picker>
                <div class="thumb-picker">
                    <img [src]="goal.icon || '../../../../assets/images/icons/image-placeholder.svg'" />
                    <app-file-picker
                        id="icon"
                        type="image"
                        size="small"
                        label="Goal icon"
                        [value]="goal.icon"
                        (fileChange)="goal.iconFile = $event; !$event && goal.icon = ''"
                    ></app-file-picker>
                </div>
            </div>
        </div>
        <div class="flex-row">
            <app-named-file-list
                [values]="goal.downloads"
                entityName="Visual Support"
                [errors]="emptyMandatoryFields"
                (valuesChange)="goal.downloads = $event"
            ></app-named-file-list>
        </div>
        <div class="dangerous-area" *ngIf="goal.goalId">
            <app-button
                type="danger"
                [loading]="deleting"
                (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
            <div>
                Warning: Deleting a goal will REMOVE related units from the Full Curriculum page
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
