<div class="container">
    <div class="brand">
        <a routerLink="/" [title]="appVersion">
            <img src="../../../assets/images/logos/main-logo.svg" alt="logo" />
        </a>
    </div>
    <nav class="menu">
        <div class="menu-group">
            <ul>
                <li>
                    <span [class.active]="isHome()">
                        <a routerLink="/">All Content</a>
                    </span>
                </li>  
                <!-- <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/mini-games">Mini Games</a>
                    </span>
                </li> -- disable mini games route for now -->
            </ul>
        </div>
        <div class="menu-group">
            <div class="title">Collections</div>
            <ul>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/interactive-vcw" data-cy="interactive-vcw-btn">Interactive VCWs</a>
                    </span>
                </li>  
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/interactive-activities" data-cy="interactive-activities-btn">Interactive Activities</a>
                    </span>
                </li>  
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/content-series">Content Series</a>
                    </span>
                </li> 
            </ul>
        </div>
        <div class="menu-group">
            <div class="title">Social Communication</div>
            <ul>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/skills">SC Skills</a>
                    </span>
                </li>  
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/goals">SC Goals</a>
                    </span>
                </li>  
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/units">SC Units</a>
                    </span>
                </li>  
            </ul>
        </div>
        <div class="menu-group">
            <div class="title">SEL</div>
            <ul>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/sel-ages">SEL Ages</a>
                    </span>
                </li>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/sel-units">SEL Units</a>
                    </span>
                </li>  
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/sel-lessons">SEL Lessons</a>
                    </span>
                </li> 
            </ul>
        </div>
        <div class="menu-group">
            <div class="title">Content Curation</div>
            <ul>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/scc-featured-content" data-cy="scc-featured-content-menu-item">SCC Featured Content</a>
                    </span>
                </li>
            </ul>
            <ul>
                <li>
                    <span routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                        <a routerLink="/saved-lists" data-cy="saved-lists-menu-item">Saved Lists</a>
                    </span>
                </li>  
            </ul>
        </div>
    </nav>
</div>