<div 
    placement="top"
    trigger="click"
    tooltip-class="tp"
    hideDelayAfterClick="1500"
    (click)="copyToClipboard()" 
    tooltip="Link copied to clipboard"
>
    <ng-content></ng-content>
</div>
