import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Content } from 'src/app/models/content/content.model';

@Component({
    selector: 'content-video',
    templateUrl: './content-video.component.html',
    styleUrls: ['./content-video.component.scss']
})
export class ContentVideoComponent implements OnInit {
    @Input() content: Content;
    @Input() emptyMandatoryFields = Array<string>();

    @Output() contentChange = new EventEmitter<Content>();

    constructor() { }

    ngOnInit() {
        if (!this.content.subtitles || this.content.subtitles.length === 0)
            this.content.subtitles = [{ url: "", language: "English" }];
    }
}
