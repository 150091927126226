<div class="content {{size}} {{inlineLabel ? 'inline' : ''}}">
    <label *ngIf="label">{{label}} <b *ngIf="isRequired">*</b></label>
    <div>
        <div class="input-container" [ngClass]="{'input-disabled': isDisabled}">
            <div
                class="field"
                [class.error]="error"
            >
                <img *ngIf="type === 'video'" src="../../../../assets/images/icons/video-ico.svg" alt="video" />
                <img *ngIf="type === 'image'" src="../../../../assets/images/icons/image-ico.svg" alt="image" />
                <img *ngIf="type !== 'video' && type !== 'image'" src="../../../../assets/images/icons/file-ico.svg" alt="file" />
                <span [class]="fileName ? 'filled' : ''">
                    {{fileName || 'Select file...'}}
                </span>
            </div>
            <label [for]="'input-' + id">Browse</label>
            <input
                type="file"
                [id]="'input-' + id"
                [accept]="getMimeType()"
                (change)="onFileChange($event.target.files, $event.target.value.length)"
                [disabled]="isDisabled"
                data-cy="file-picker-input"
            />
        </div>
        <div *ngIf="value" class="selected-file-container">
            <img src="../../../../assets/images/icons/link.svg" alt="link" />
            <div class="selected-value" title="{{value}}">{{value}}</div>
            <app-button
                size="tiny"
                type="danger"
                (click)="removeFile()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
        </div>
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>
</div>