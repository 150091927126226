import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    constructor(
        private http: HttpClient
    ) { }

    downloadAwsLink(url: string, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.title = fileName
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    openContent(url: string) {
        if (url) {
            window.open(url, '_blank');
        }
    }

}
