import { Content } from 'src/app/models/content/content.model';
import { LessonStep } from 'src/app/models/sel-lesson/sel-lesson.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WhatToDoItem } from 'src/app/models/what-to-do/what-to-do-item.model';

@Component({
    selector: 'app-wtd-picker',
    templateUrl: './wtd-picker.component.html',
    styleUrls: ['./wtd-picker.component.scss']
})
export class WtdPickerComponent implements OnInit {
    @Input() errors: Array<string>;
    @Input() viewMode: "wtd" | "steps";
    @Input() item: WhatToDoItem | LessonStep;
    @Input() index: number;
    
    @Output() whatToDoChange = new EventEmitter<WhatToDoItem | LessonStep>();

    selectedType = "";
    wtdOptions = [
        { id: "", label: "None" },
        { id: "text", label: "Plain text" },
        { id: "worksheet", label: "Video Companion Worksheet" },
        { id: "download", label: "Plain text + Download" },
        { id: "video", label: "Review Video" },
        { id: "workbook", label: "Activity Workbook" },
        { id: "visualTool", label: "Visual Tools" },
        { id: "content", label: "Child content" },
    ];

    stepsOptions = [
        { id: "", label: "None" },
        { id: "content", label: "Content" },
        { id: "prompt", label: "Prompt" },
    ];

    stepsDefaultTitles = [
        { id: "-", label: "" },
        { id: "Warmup", label: "Warmup" },
        { id: "Watch", label: "Watch" },
        { id: "Discuss", label: "Discuss" },
        { id: "Review", label: "Review" },
        { id: "Reflect", label: "Reflect" },
        { id: "Learn", label: "Learn" },
        { id: "Practice", label: "Practice" },
        { id: "", label: "(Custom)" },
    ];

    constructor() { }

    ngOnInit(): void {
        if (!this.item)
            this.item = {};

        if (
            this.item.type === "content" ||
            this.item.type === "minigame" ||
            this.item.type === "interactiveactivity"
        ) {
            this.selectedType = "content";
            if (this.isWtdItem(this.item) && this.item.childDetails)
                this.item.childDetails.contentId = this.item.childId;
        } else {
            this.selectedType = this.item.type;
        }
    }

    onContentChange($event: { content: Content, type: string }) {
        if (!$event.content && this.isWtdItem(this.item)) {
            this.item.childDetails = undefined;
            this.item.childId = undefined;
            this.whatToDoChange.emit(this.item);

            return;
        }

        this.item.type = $event.type;
        if (this.isWtdItem(this.item)) {
            this.item.childId = $event.content.contentId;
            this.item.childDetails = {
                url: $event.content.url,
                type: $event.content.type,
                title: $event.content.title,
                contentId: $event.content.contentId,
                releaseDate: $event.content.releaseDate
            };
        } else {
            this.item.itemId = $event.content.contentId;
        }

        this.whatToDoChange.emit(this.item);
    }

    isWtdItem(t: WhatToDoItem | LessonStep): t is WhatToDoItem {
        return this.viewMode !== "steps";
    }

    onTypeChange(type: string) {
        this.item.type = type;
        if (this.isWtdItem(this.item)) {
            if (type === "worksheet") {
                this.item.text = "Review using Video Companion Worksheet";
            } else if (type === "download") {
                this.item.text = "Print Goal Poster to share with parents or teachers / Print Homework";
            } else if (type === "workbook") {
                this.item.text = "Review using Video Companion Workbook";
            } else if (type === "visualTool") {
                this.item.text = "Visual Tools: Download icon";
            } else {
                this.item.text = "";
            }
        } else {
            this.item.title = "-";

            if (type === "prompt")
                this.item.prompt = { text: "" };
            else
                this.item.showTooltip = false;
        }

        this.whatToDoChange.emit(this.item);
    }

    isCustomStepTitle(title: string): boolean {
        return !title || this.stepsDefaultTitles.every(t => t.id !== title);
    }

    getErrorsForField(fieldName: string): string {
        if (!this.errors)
            return '';

        return this.errors.some(e => e.indexOf(fieldName) >= 0) 
            ? 'This field is required.' 
            : '';
    }
}
