import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { MiniGame } from "../models/mini-games/mini-game.model";
import { ListParams } from "../models/list-params/list-params.model";
import { MiniGameList } from "../models/mini-games/mini-game-list.model";

@Injectable({
    providedIn: 'root'
})
export class MiniGameService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<MiniGameList> {
        return this.http.get('admin/mini-games', params);
    }

    get(miniGameId:string):Observable<any> {
        return this.http.get('admin/mini-games/' + miniGameId);
    }

    save(miniGame:MiniGame):Observable<any> {
        if (miniGame.miniGameId)
            return this.http.put('admin/mini-games/' + miniGame.miniGameId, miniGame);
        
        return this.http.post('admin/mini-games', miniGame);
    }

    delete(miniGameId:string):Observable<any> {
        return this.http.delete('admin/mini-games/' + miniGameId);
    }
}
