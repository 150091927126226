import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CopyToClipboardComponent } from './copy-to-clipboard.component';

@NgModule({
    declarations: [
        CopyToClipboardComponent
    ],
    imports: [
        CommonModule,
        TooltipModule
    ],
    exports: [
        CopyToClipboardComponent
    ]
})
export class CopyToClipboardModule { }