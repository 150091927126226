import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { ButtonModule } from '../form/button/button.module';
import { SharedModule } from '../shared.module';

@NgModule({
    declarations: [
        DialogComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule
    ],
    exports: [
        DialogComponent
    ]
})
export class DialogModule { }