import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractiveVcwPickerComponent } from './interactive-vcw-picker.component';
import { ButtonModule } from '../button/button.module';
import { SelectModule } from '../select/select.module';
import { ListViewModule } from '../../list/list-view/list-view.module';
import { TextfieldModule } from '../textfield/textfield.module';
import { ListToolbarModule } from '../../list/list-toolbar/list-toolbar.module';
import { ListPaginatorModule } from '../../list/list-paginator/list-paginator.module';



@NgModule({
  declarations: [
    InteractiveVcwPickerComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    SelectModule,
    ListViewModule,
    TextfieldModule,
    ListToolbarModule,
    ListPaginatorModule,
  ],
  exports: [
    InteractiveVcwPickerComponent
  ]
})
export class InteractiveVcwPickerModule { }
