import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { ActivitiesResponseList, IATypes, InteractiveTypesFilter } from 'src/app/models/interactive-activity/interactive-activity.model';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { InteractiveActivityService } from 'src/app/services/interactive-activity.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

@Component({
    selector: 'app-interactive-activities-list',
    templateUrl: './interactive-activities-list.component.html',
    styleUrls: ['./interactive-activities-list.component.scss']
})
export class InteractiveActivitiesListComponent implements OnInit {

    constructor(
        private dialogService: DialogService,
        private iaService: InteractiveActivityService,
        private authService: AuthService,
        private router: Router
    ) { }

    newActivityOptions = [
        {
            label: "Fill in the Blanks",
            url: "/interactive-activities/fill-in-the-blanks/new"
        },
        {
            label: "Matching",
            url: "/interactive-activities/matching/new"
        },
        {
            label: "Q&A",
            url: "/interactive-activities/qa/new"
        },
        {
            label: "Spinner",
            url: "/interactive-activities/spinner/new"
        },
        {
            label: "Sorting",
            url: "/interactive-activities/sorting/new"
        },
        {
            label: "Flashcards",
            url: "/interactive-activities/flashcards/new"
        },
        {
            label: "Dice Roller",
            url: "/interactive-activities/dice-roller/new"
        },
        {
            label: "Multiple Choice",
            url: "/interactive-activities/multiple-choice/new"
        },
    ];

    headers = [
        { label: "Title", key: "title", sortable: true },
        { label: "Activity Type", key: "interactiveActivityTypeTitle", sortable: true },
        { label: "Date Edited", key: "updatedAt", sortable: true },
        { label: "Date Created", key: "createdAt", sortable: true },
        { label: "Linked Content", key: "contentTitle", sortable: true },
        { label: "Actions" },
    ];

    typeOptions = [
        { id: 1, label: "All"},
        { id: 2, label: "Fill in the blanks" },
        { id: 3, label: "Matching" },
        { id: 4, label: "Q&A" },
        { id: 5, label: "Spinner" },
        { id: 6, label: "Sorting" },
        { id: 7, label: "Flashcards" },
        { id: 8, label: "Dice Roller" },
        { id: 9, label: "Multiple Choice" },
    ];
    loading: boolean;
    sorting = false;
    searchTerm: string;
    sortingParams: { key?: string, asc?: boolean };
    currentPage = 1;
    deletingContentId: string = '0';
    activitiesList: ActivitiesResponseList;
    iaTypes: typeof InteractiveTypesFilter = InteractiveTypesFilter;
    activitiesFilter: IATypes[];
    activeTab: number = 0;
    allIaTypes = [];


    ngOnInit(): void {
        Object.values(this.iaTypes).forEach(iaType => this.allIaTypes.push(iaType));
        this.loadActivitiesList();
    }

    loadActivitiesList(hideLoader?:boolean) {
        var params: ListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }
        if (this.activitiesFilter && this.activitiesFilter.length > 0) {
            params['interactiveActivityTypes'] = this.activitiesFilter.map(m => m);
        } else {
            params['interactiveActivityTypes'] = this.allIaTypes;
        }

        if (!hideLoader)
            this.loading = true;

        this.iaService.getList(params).subscribe(
            (response: ActivitiesResponseList) => {
                response.interactiveActivities.forEach(activity => {
                    activity.createdAt = new Date(activity.createdAt).toLocaleDateString('en-US');
                    activity.updatedAt = new Date(activity.updatedAt).toLocaleDateString('en-US');
                });
                this.activitiesList = response;
                this.sorting = false;
                this.loading = false;
            },
            error => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(error));
                if (error.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        )
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadActivitiesList();
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadActivitiesList();
    }

    onSortChange(params: { key: string, asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadActivitiesList(true);
    }
    
    onTypeChange(types: IATypes[]) {
        let translatedType = [];
        
        types.forEach((type) => {
            if (type.label.toLowerCase() !== 'all')
                translatedType.push(this.iaTypes[type.label])
        });

        this.activitiesFilter = translatedType;
        this.loadActivitiesList();
    }

    confirmDelete(activityId: string, activityTitle: string) {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(activityId),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
            activityTitle + " activity from the activities?",
            message: "This action cannot be undone."
        });
    }

    delete(activityId) {
        this.deletingContentId = activityId;
        this.iaService.delete(activityId).subscribe(
            () => {
                this.deletingContentId = null;
                this.dialogService.showSuccessAlert("Activity has been deleted.");
                this.resetVariables();
                this.loadActivitiesList();
            },
            error => {
                this.dialogService.showInformativeModal({ typeModal: 'errorDialog', props: { message: "Error on delete IA, please contact the support." } });
                throwError(error);
            }
        );
    }

    resetVariables(): void {
        this.searchTerm = '';
        this.currentPage = 1;
        this.sorting = false;
    }

}
