import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-named-file-list',
    templateUrl: './named-file-list.component.html',
    styleUrls: ['./named-file-list.component.scss']
})
export class NamedFileListComponent implements OnInit {
    @Input() id = "nfl";
    @Input() entityName:string;
    @Input() errors:Array<string> = [];
    @Input() values:Array<{title:string, url:string}>;

    items:Array<{title:string, url:string, file?:File}>;

    @Output() valuesChange = new EventEmitter<Array<{title:string, url:string, file?:File}>>();

    constructor() { }

    ngOnInit():void {
        this.items = this.values || [{ title: "", url: "" }];
    }

    addNew() {
        this.items.push({
            title: "", url: ""
        });
    }

    onFileChange(files:FileList, i:number) {
        this.items[i].url = "";
        this.items[i].file = files.item(0);
        this.valuesChange.emit(this.items);
    }

    onTitleChange($event:string, i:number) {
        this.items[i].title = $event;
        this.valuesChange.emit(this.items);
    }

    removeItem(i:number) {
        this.items.splice(i, 1);
        this.valuesChange.emit(this.items);
    }
}
