import { Component } from '@angular/core';
import { Goal } from 'src/app/models/goal/goal.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GoalService } from 'src/app/services/goal.service';
import { SkillService } from 'src/app/services/skill.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-goals-editor',
    templateUrl: './goals-editor.component.html',
    styleUrls: ['./goals-editor.component.scss']
})
export class GoalsEditorComponent {
    goal:Goal = {
        order: 0,
        title: "",
        goalId: "",
        skillId: "",
        downloads: []
    };
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    emptyMandatoryFields = Array<string>();
    availableOrders:Array<{id:string, label:string}> = [];
    availableSkills:Array<{id:string, label:string}> = [];
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private goalService:GoalService,
        private httpService:HttpService,
        private skillService:SkillService,
        private dialogService:DialogService
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;
            this.loadAvailableSkills(data.goalId);            
        });
    }

    loadAvailableSkills(goalId:string) {
        this.skillService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableSkills = response.skills.map(s => {
                    return { id: s.skillId, label: s.title };
                });

                if (goalId === "new" && this.availableSkills.length > 0) {
                    this.goal.skillId = this.availableSkills[0].id;
                    this.loadAvailableOrders(this.availableSkills[0].id, goalId);
                } else {
                    this.load(goalId);
                }
            });
    }

    loadAvailableOrders(parentSkillId:string, goalId?:string) {
        this.availableOrders = [];
        this.goalService.list({ skill: parentSkillId })
            .subscribe(response => {
                for (var i = 0; i < response.totalResults; i++) {
                    var order = (i + 1).toString();
                    this.availableOrders.push({
                        id: order, 
                        label: order
                    });
                }

                if (
                    !this.goal.order || !goalId || 
                    goalId === "new" || response.totalResults <= 0
                ) {
                    var newOrder = (this.availableOrders.length + 1).toString();
                    this.availableOrders.push({
                        id: newOrder, 
                        label: newOrder
                    });
                    this.goal.order = this.availableOrders.length;
                }

                this.loading = false;
            });
    }

    load(goalId:string) {
        this.goalService.get(goalId)
            .subscribe(response => {
                this.loading = false;
                this.goal = response.goal;
                if (!this.goal.tags)
                    this.goal.tags = [];

                this.loadAvailableOrders(this.goal.skillId, this.goal.goalId);
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        var mediaPromises = this.getMediaReadyForUpload();
        Promise.all(mediaPromises).then(() => {
            this.goalService.save(this.goal).subscribe(response => {
                this.saving = false;
                this.dialogService.showSuccessAlert("Save Successful.");
                if (response)
                    this.router.navigate(["/goals/" + response.goalId]);
            }, errors => {
                this.saving = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            });
        });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.goal.title)
            this.emptyMandatoryFields.push("title");
        if (this.goal.videoFile && (!this.goal.videoThumbnailFile || this.goal.videoThumbnail))
            this.emptyMandatoryFields.push("videoThumbnail");
        if (this.goal.downloads) {
            this.goal.downloads
                .forEach((d, i) => {
                    if (d.file && !d.title)
                        this.emptyMandatoryFields.push("Visual Support" + i);
                });
        }

        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
        
        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload():Promise<string>[] {
        var promises:Promise<string>[] = [];
        if (this.goal.videoFile) {
            promises.push(
                this.createPromiseForMedia(this.goal.videoFile, "video")
                    .then(url => { 
                        this.goal.videoLink = url; 
                        this.goal.videoFile = null;
                        return url;
                    })
            );
        }

        if (this.goal.videoThumbnailFile) {
            promises.push(
                this.createPromiseForMedia(this.goal.videoThumbnailFile, "image")
                    .then(url => {
                        this.goal.videoThumbnail = url;
                        this.goal.videoThumbnailFile = null;
                        return url;
                    })
            );
        }

        if (this.goal.iconFile) {
            promises.push(
                this.createPromiseForMedia(this.goal.iconFile, "image")
                    .then(url => {
                        this.goal.icon = url;
                        this.goal.iconFile = null;
                        return url;
                    })
            );
        }

        if (this.goal.downloads && this.goal.downloads.length > 0) {
            this.goal.downloads.filter(d => d.file).forEach(d => {
                promises.push(
                    this.createPromiseForMedia(d.file, "image")
                        .then(url => {
                            d.url = url;
                            d.file = null;
                            d.thumbnail = url.replace(/\.[^\.]+$/, ".png");
                            return url;
                        })
                );
            });
        }

        return promises;
    }
    
    createPromiseForMedia(file:File, type:"video"|"image"):Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url), 
                    error => reject(error)
                );
        });
    }

    delete() {
        this.deleting = true;
        this.goalService.delete(this.goal.goalId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/goals"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            headerMessage: "Warning: Deleting a goal will REMOVE " + 
                "related units from the Full Curriculum page",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.goal.title + " goal from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onSkillChange($event:string) {
        this.goal.order = 0;
        this.loadAvailableOrders($event, this.goal.goalId);
    }

    goBack() {
        this.router.navigate(["/goals"]);
    }
}
