<app-header>
    <a routerLink="/content-series">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!contentSeries.contentSeriesId ? "New Content Series" : contentSeries.title}}</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
        <app-button
            type="danger"
            [loading]="deleting"
            (click)="confirmDelete()"
            *ngIf="contentSeries.contentSeriesId"
            iconUrl="../../../../assets/images/icons/delete.svg"
        >
            Delete
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <div class="column">
                <app-select
                    size="small"
                    label="Content Type"
                    [(value)]="contentSeries.typeId"
                    [options]="availableContentTypes"
                ></app-select>
                <app-textfield
                    label="Title"
                    [(value)]="contentSeries.title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
                <app-richtextarea
                    label="Description"
                    placeholder="Place your text"
                    [(value)]="contentSeries.description"
                    [error]="emptyMandatoryFields.includes('description') ? 'This field is required.' : ''"
                ></app-richtextarea>
                <app-tagfield
                    label="Tags"
                    [(values)]="contentSeries.tags"
                ></app-tagfield>
            </div>
            <div class="column">
                <div class="thumb-preview">
                    <img [src]="contentSeries.thumbnail || '../../../../assets/images/icons/image-placeholder-medium.svg'" />
                </div>
                <app-file-picker
                    id="thumbnail"
                    type="image"
                    label="Static thumbnail"
                    [value]="contentSeries.thumbnail"
                    [error]="emptyMandatoryFields.includes('thumbnail') ? 'This field is required.' : ''"
                    (fileChange)="contentSeries.thumbnailFile = $event; !$event && contentSeries.thumbnail = ''"
                ></app-file-picker>
                <app-file-picker
                    id="video"
                    type="video"
                    label="Animated Thumbnail (MP4)"
                    [value]="contentSeries.animatedThumbnail"
                    (fileChange)="contentSeries.animatedThumbnailFile = $event; !$event && contentSeries.animatedThumbnail = ''"
                ></app-file-picker>
                <app-multi-select
                    size="small"
                    label="Curriculum"
                    [options]="availableCurriculums"
                    [(selectedOptions)]="contentSeries.curriculums"
                    *ngIf="availableCurriculums && contentSeries.curriculums"
                    [error]="emptyMandatoryFields.includes('curriculums') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-multi-select
                    size="small"
                    label="Plan"
                    [options]="availablePlans"
                    [(selectedOptions)]="contentSeries.plans"
                    *ngIf="availablePlans && contentSeries.plans"
                    [error]="emptyMandatoryFields.includes('plans') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-switch
                    id="active"
                    label="Active"
                    [(value)]="contentSeries.active"
                ></app-switch>
            </div>
        </div>
        <div class="contents">
            <div class="content-picker-row" *ngFor="let c of contentSeries.contentDetails;let i = index">
                <label *ngIf="contentSeries.contentDetails[i]?.contentId">{{i + 1}}</label>
                <app-content-picker
                    layout="row"
                    [type]="contentSeries.typeId"
                    [title]="getPickerTitle() + ' Content'"
                    [content]="contentSeries.contentDetails[i]"
                    (contentChange)="onContentDetailsChange($event, i)"
                    [error]="emptyMandatoryFields.includes('content' + i) ? 'This field is required.' : ''"
                ></app-content-picker>
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
