import { Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ContentService } from 'src/app/services/content.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { ContentList } from 'src/app/models/content/content-list.model';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
    selector: 'app-content-list',
    templateUrl: './content-list.component.html',
    styleUrls: ['./content-list.component.scss'],
    animations: [
        trigger('expandCollapse', [
          state('collapsed', style({ height: '20px',  whiteSpace: 'nowrap' })),
          state('expanded', style({ height: '*',  whiteSpace: 'normal' })),
          transition('collapsed <=> expanded', animate('.4s ease-in-out')),
        ]),
      ],
    })
export class ContentListComponent implements OnInit {
    newContentOptions = [
        { 
            label: "Video", 
            icon: "../../../assets/images/icons/video-content.svg", 
            url: "/contents/video/new" 
        },
        { 
            label: "Activity", 
            icon: "../../../assets/images/icons/activity-content.svg", 
            url: "/contents/activity/new" 
        },
        { 
            label: "Game", 
            icon: "../../../assets/images/icons/game-content.svg", 
            url: "/contents/game/new" 
        },
        { 
            label: "EDSW", 
            icon: "../../../assets/images/icons/edsw-content.svg", 
            url: "/contents/edsw/new" 
        },
    ];

    headers = [
        { label:"Preview", sortable: false },
        { label:"Title", key:"title", sortable: true },
        { label:"Type", key:"contentType", sortable: true },
        { label:"Date", key:"releaseDate", sortable: true },
        { label:"Content Link" },
        { label:"Status" },
        { label:"Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    deletingContentId:string;
    contentsList:ContentList;
    sortingParams:{ key?:string, asc?:boolean };
    isHovered: { [key: number]: boolean } = {};
    isSmallScreen = window.innerWidth < 1700;

    constructor(
        private router:Router,
        private authService:AuthService,
        private dialogService:DialogService,
        private contentService:ContentService,
    ) { }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.isSmallScreen = window.innerWidth < 1700;
    }

    ngOnInit(): void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:ListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;
        
        this.contentService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                response.contents.forEach(c => {
                    c.releaseDate = 
                        new Date(c.releaseDate).toLocaleDateString('en-US');
                });
                this.contentsList = response;
            }, 
            errors => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors));
                if (errors.status === 401 || 
                    errors.error?.code === "error-auth-session" ||
                    errors.error?.code === "session-validate-client"
                ) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    delete(contentId:string) {
        this.deletingContentId = contentId;
        this.contentService.delete(contentId).subscribe(() => {
            this.deletingContentId = null;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.loadList();
        });
    }

    confirmDelete(contentId:string, contentTitle:string) {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(contentId),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                contentTitle + " content from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    toggleHover(index: number) {
        this.isHovered[index] = !this.isHovered[index];
    }
}
