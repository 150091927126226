import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GoalService } from 'src/app/services/goal.service';
import { AuthService } from 'src/app/services/auth.service';
import { GoalList } from 'src/app/models/goal/goal-list.model';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { GoalListParams } from 'src/app/models/goal/goal-list-params.model';

@Component({
  selector: 'app-goals-list',
  templateUrl: './goals-list.component.html',
  styleUrls: ['./goals-list.component.scss']
})
export class GoalsListComponent implements OnInit {
    headers = [
        { label:"Goal Name", key:"title", sortable: true },
        { label:"Parent Skill", key:"skill", sortable: true },
        { label:"Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    goalsList:GoalList;
    sortingParams:{ key?:string, asc?:boolean };
    
    constructor(
        private router:Router,
        private authService:AuthService,
        private goalService:GoalService,
        private dialogService:DialogService,
    ) { }

    ngOnInit():void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:GoalListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.goalService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                this.goalsList = response;
            }, 
            errors => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors));
                if (errors.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }
}
