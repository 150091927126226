<div *ngIf="activityFlashcards.items" class="flashcards-container">
    <ng-container *ngFor="let card of activityFlashcards.items; let i = index">
        <h2 class="title" data-cy="flashcard-title">Flashcard {{ i + 1 }} <b *ngIf="i < 4">*</b></h2>
        <div class="cards-container">
            <div class="front-cards">
                <h3>Front View</h3>
                <app-textfield label="Front Title"
                    placeholder="Title for the front view of the flashcard"
                    [(value)]="card.frontTitle" maxChars="42"
                    [error]="emptyMandatoryFields.includes('frontTitle' + i) ? 'This field is required.' : ''"
                    (maxCharsReached)="maxCharsValidation($event, 'front-title-' + i)"
                    isRequired="true"
                    data-cy="front-title-input"
                ></app-textfield>
                <app-file-picker type="image" label="Front Image" [value]="card.frontImage"
                    (fileChange)="card.frontImageFile = $event; !$event && card.frontImage = ''; card.frontTempImage = 'temp-img'"
                    [id]="'frontTempImage' + i"
                    [error]="emptyMandatoryFields.includes('frontTempImage' + i) ? 'This field is required.' : ''"
                    isRequired="true"
                    data-cy="front-image-picker"
                >
                </app-file-picker>
                <app-textarea placeholder="Front (Place your Text)" [(value)]="card.frontText" maxChars="108"
                    label="Front Text"
                    [error]="emptyMandatoryFields.includes('frontText' + i) ? 'This field is required.' : ''"
                    [tooltip]="tooltipFlashcards['frontText']"
                    isRequired="true"
                    (maxCharsReached)="maxCharsValidation($event, 'front-text-' + i)"
                    data-cy="front-text-input"
                ></app-textarea>
            </div>
            <div class="back-cards">
                <h3>Back View</h3>
                <app-textfield label="Back Title"
                    placeholder="Title for the back view of the flashcard"
                    [(value)]="card.backTitle" maxChars="42"
                    [error]="emptyMandatoryFields.includes('backTitle' + i) ? 'This field is required.' : ''"
                    (maxCharsReached)="maxCharsValidation($event, 'back-title-' + i)"
                    isRequired="true"
                    data-cy="back-title-input"
                ></app-textfield>
                <app-file-picker type="image" label="Back Image" [value]="card.backImage"
                    (fileChange)="card.backImageFile = $event; !$event && card.backImage = ''; card.backTempImage = 'temp-img'"
                    [id]="'backTempImage' + i"
                    data-cy="back-image-picker"
                >
                </app-file-picker>
                <app-textarea 
                    placeholder="• If there is no image, the back text is limited to 255 characters.&#10;• If there's an image, the back text is limited to 108 characters."
                    [(value)]="card.backText" maxChars="255"
                    label="Back Text"
                    [error]="emptyMandatoryFields.includes('backText' + i) ? 'This field is required.' : ''"
                    [tooltip]="tooltipFlashcards['backText']"
                    (maxCharsReached)="maxCharsValidation($event, 'back-text-' + i)"
                    isRequired="true"
                    data-cy="back-text-input"
                ></app-textarea>
            </div>
        </div>
        <app-button
            type="transparent"
            iconUrl="../../../../assets/images/icons/trash.svg"
            (click)="deleteFlashcard(i)"
            data-cy="delete-flashcard-btn"
        >Delete Flashcard</app-button>
        <hr>
    </ng-container>
    <app-button
        [disableBtn]="activityFlashcards.items.length == 10"
        type="primary"
        iconUrl="../../../../assets/images/icons/plus.svg"
        (click)="addFlashcard()"
        data-cy="add-flashcard-btn"
    >
    Add Flashcard
    </app-button>
</div>
