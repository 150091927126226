import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Tooltip } from 'src/app/models/tooltip/tooltip.model';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
    @Input() value = "";
    @Input() label: string;
    @Input() error: string;
    @Input() type = "text";
    @Input() iconUrl: string;
    @Input() disabled: boolean;
    @Input() placeholder = "";
    @Input() size: "small" | "medium" | "large" = "medium";
    @Input() feedback: "success" | "info" | "warning" | "error";
    @Input() maxChars: number = null;
    @Input() isRequired: boolean = false;
    @Input() tooltip: Tooltip;

    @Output() valueChange = new EventEmitter<string>();
    @Output() maxCharsReached = new EventEmitter<boolean>();
    
    isMaxCharsReached: boolean;
    inputTextLength: number = 0;

    constructor() { }

    ngOnInit(): void {
        this.validateMaxChars(this.value?.length)
    }

    onChange(e: any) {
        this.valueChange.emit(e.target.value);
    }

    validateMaxChars(textLength: number): void {
        if (this.error && textLength > 0) {
            this.error = "";
        }

        this.inputTextLength = textLength;
        this.isMaxCharsReached = textLength > this.maxChars ? true : false;
        this.maxCharsReached.emit(this.isMaxCharsReached);

        this.error = this.isMaxCharsReached ? "Limit text to character count." : '';
    }

    validateRequired(textLength: number): void {
        if (textLength === 0) {
            this.error = "This field is required."
        }
        if (textLength > 0 && !this.maxCharsReached && this.error) {
            this.error = "";
        }
    }
}
