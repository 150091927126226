<div class="content">
    <label *ngIf="entityName">{{entityName}}s</label>
    <div class="input-row" *ngFor="let item of items;let i = index">
        <div class="input-container">
            <label class="index">{{i + 1}}</label>
            <app-textfield
                [value]="item.title"
                placeholder="{{entityName}} Name"
                (valueChange)="onTitleChange($event, i)"
                [error]="errors.includes(entityName + i) ? 'This field is required.' : ''"
            >
            </app-textfield>
            <ng-container *ngIf="!item.url">
                <div
                    class="field"
                    [ngClass]="error ? 'error' : ''"
                >
                    <span [class]="item.file?.name ? 'filled' : ''">
                        {{item.file?.name || 'Upload PDF or PNG'}}
                    </span>
                </div>
                <label class="upload-btn" [for]="'input-' + id + i">Browse</label>
            </ng-container>
            <ng-container *ngIf="item.url">
                <app-button
                    type="primary"
                    (click)="fileInput.click()"
                    iconUrl="../../../../assets/images/icons/update.svg"
                >
                    Update
                </app-button>
                <app-button
                    type="danger"
                    (click)="removeItem(i)"
                    iconUrl="../../../../assets/images/icons/delete.svg"
                >
                    Remove
                </app-button>
            </ng-container>
            <input
                type="file"
                #fileInput
                [id]="'input-' + id + i"
                accept="image/*,application/pdf"
                (change)="onFileChange($event.target.files, i)"
            />
        </div>
        <div *ngIf="item.url" class="selected-file-container">
            <img src="../../../../assets/images/icons/link.svg" alt="link" />
            <div class="selected-value">{{item.url}}</div>
        </div>
    </div>
    <app-button 
        type="primary" 
        (click)="addNew()"
        iconUrl="../../../../assets/images/icons/plus.svg"
    >
        {{entityName}}
    </app-button>
</div>