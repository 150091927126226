import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/models/content/content.model';
import { SelLesson } from 'src/app/models/sel-lesson/sel-lesson.model';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { SelLessonService } from 'src/app/services/sel-lesson.service';
import { UnitService } from 'src/app/services/unit.service';
import { isEmptyObject } from 'src/app/utils/emptyObject.validator';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-sel-lessons-editor',
    templateUrl: './sel-lessons-editor.component.html',
    styleUrls: ['./sel-lessons-editor.component.scss']
})
export class SelLessonsEditorComponent {
    readonly StepsFieldsCount = 5;
    readonly WeeklyContentCount = 2;

    lesson: SelLesson = {
        order: 0,
        steps: [],
        title: "",
        unitId: "",
        lessonId: "",
        unit: {
            title: "",
            unitId: "",
        },
    };
    activeTab = 0;
    saving: boolean;
    loading: boolean;
    deleting: boolean;
    emptyMandatoryFields = Array<string>();
    availableAges: Array<{ id: string, label: string }> = [];
    availableUnits: Array<{ id: string, label: string }> = [];
    availableOrders: Array<{ id: string, label: string }> = [];
    radioOptionSelected: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ageService: AgeTrackService,
        private unitService: UnitService,
        private httpService: HttpService,
        private dialogService: DialogService,
        private lessonService: SelLessonService,
        private curriculumService: CurriculumService,
    ) {
        this.route.params.subscribe(data => {
            this.loading = true;
            this.loadSelCurriculumData(data.lessonId);

            if (data.lessonId !== "new")
                this.load(data.lessonId);
        });
    }

    loadAvailableAges(lessonId: string, curriculumId: string) {
        this.ageService.list({ limit: 1000, curriculum: curriculumId })
            .subscribe(response => {
                this.availableAges = response.ages.map(a => {
                    return { id: a.ageId, label: a.title };
                });

                if (lessonId === "new" && this.availableAges.length > 0) {
                    this.lesson.unit.ageId = this.availableAges[0].id;
                    this.loadAvailableUnits(this.lesson.unit.ageId, lessonId);
                }
            });
    }

    loadAvailableUnits(ageId: string, lessonId: string) {
        this.availableUnits = [];
        this.unitService.listSel({ limit: 1000, age: ageId })
            .subscribe(response => {
                this.availableUnits = response.units.map(u => {
                    return { id: u.unitId, label: u.title };
                });

                if (
                    (!this.lesson.unitId || lessonId === "new") &&
                    this.availableUnits.length > 0
                ) {
                    this.lesson.order = 0;
                    this.lesson.unitId = this.availableUnits[0].id;
                }

                this.loadAvailableOrders(this.lesson.unitId, this.lesson.lessonId);
            });
    }

    loadAvailableOrders(parentUnitId: string, lessonId?: string) {
        this.availableOrders = [];

        if (!parentUnitId) {
            this.dialogService.showErrorAlert(
                "Warning: There is no Units available for the selected Age"
            );
            this.loading = false;
        }

        this.lessonService.list({ unit: parentUnitId })
            .subscribe(response => {
                for (var i = 0; i < response.totalResults; i++) {
                    var order = (i + 1).toString();
                    this.availableOrders.push({
                        id: order,
                        label: order
                    });
                }

                if (
                    lessonId === "new" || !lessonId ||
                    !this.lesson.order || response.totalResults <= 0
                ) {
                    var newOrder = (this.availableOrders.length + 1).toString();
                    this.availableOrders.push({
                        id: newOrder,
                        label: newOrder
                    });
                    this.lesson.order = this.availableOrders.length;
                }

                this.loading = false;
            });
    }

    loadSelCurriculumData(lessonId: string) {
        this.curriculumService.get(environment.selCurriculumSlug)
            .subscribe(response => {
                this.loadAvailableAges(lessonId, response.curriculum.curriculumId);
            });
    }

    load(lessonId: string) {
        this.lessonService.get(lessonId)
            .subscribe(response => {
                this.loading = false;
                this.lesson = response.lesson;
                this.radioOptionSelected = this.lesson.skillOrImplementation == null ? 'none' : this.lesson.skillOrImplementation;
                this.loadAvailableUnits(this.lesson.unit.ageId, lessonId);

                if (!this.lesson.steps)
                    this.lesson.steps = [];
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        this.checkSkillReviewFile();
        Promise.all(this.getMediaReadyForUpload())
            .then(() => {
                this.lesson.unitId = this.lesson.unit.unitId;
                this.lessonService.save(this.lesson).subscribe(response => {
                    this.saving = false;
                    this.dialogService.showSuccessAlert("Save Successful.");
                    if (response)
                        this.router.navigate(["/sel-lessons/" + response.lessonId]);
                }, errors => {
                    this.saving = false;
                    this.dialogService.showErrorAlert(formatError(errors?.error));
                });
            });
    }

    allFieldsAreValid(): boolean {
        this.emptyMandatoryFields = [];
        let tabWithErrors = -1;

        if (!this.lesson.title)
            this.emptyMandatoryFields.push("title");
        if (!this.lesson.unitId)
            this.emptyMandatoryFields.push("unitId");
        if (!this.lesson.guidingQuestion)
            this.emptyMandatoryFields.push("guidingQuestion");
        if (this.emptyMandatoryFields.length > 0)
            tabWithErrors = 0;

        if (!this.lesson.learningStandardGoal)
            this.emptyMandatoryFields.push("learningStandardGoal");
        if (!this.lesson.prefix)
            this.emptyMandatoryFields.push("titlePrefix");
        if (!this.lesson.learningStandardText)
            this.emptyMandatoryFields.push("learningStandardText");
        if (!this.lesson.learningStandardBenchmark)
            this.emptyMandatoryFields.push("learningStandardBenchmark");
        if (!this.lesson.strategies)
            this.emptyMandatoryFields.push("strategies");
        if (!this.lesson.lessonPlanFile && !this.lesson.lessonPlan)
            this.emptyMandatoryFields.push("lessonPlan");
        if (!this.lesson.keyTerms)
            this.emptyMandatoryFields.push("keyTerms");

        if (tabWithErrors === -1 && this.emptyMandatoryFields.length > 0)
            tabWithErrors = 1;

        const nonEmptySteps = this.lesson.steps.filter(s => !isEmptyObject(s));
        const hasStepContents = nonEmptySteps.every(step => step.content !== undefined);
        if (nonEmptySteps.length < 1 && !hasStepContents)
            this.emptyMandatoryFields.push("steps");

        if (tabWithErrors === -1 && this.emptyMandatoryFields.length > 0)
            tabWithErrors = 2;

        if (this.emptyMandatoryFields.length > 0) {
            if (tabWithErrors !== -1)
                this.activeTab = tabWithErrors;
                
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload():Promise<string>[] {
        var promises:Promise<string>[] = [];
        if (this.lesson.lessonPlanFile) {
            promises.push(
                this.createPromiseForMedia(this.lesson.lessonPlanFile, "image")
                    .then(url => { 
                        this.lesson.lessonPlan = url; 
                        this.lesson.lessonPlanFile = null;
                        return url;
                    })
            );
        }

        if (this.lesson.skillReviewPdfFile) {
            promises.push(
                this.createPromiseForMedia(this.lesson.skillReviewPdfFile, "file")
                    .then(url => {
                        this.lesson.skillReviewImplementationFile = url;
                        this.lesson.skillReviewPdfFile = null;
                        return url;
                    })
            );
        }

        this.lesson.steps.forEach(step => {
            if (step.type !== "prompt" || !step.prompt)
                return;

            const prompt = step.prompt;
            if (prompt.pdfFile) {
                promises.push(
                    this.createPromiseForMedia(prompt.pdfFile, "file")
                        .then(url => {
                            prompt.pdf = url;
                            prompt.pdfFile = null;
                            return url;
                        })
                );
            }
            
            if (prompt.animatedThumbnailFile) {
                promises.push(
                    this.createPromiseForMedia(prompt.animatedThumbnailFile, "file")
                        .then(url => {
                            prompt.animatedThumbnail = url;
                            prompt.animatedThumbnailFile = null;
                            return url;
                        })
                );
            }

            if (prompt.thumbnailFile) {
                promises.push(
                    this.createPromiseForMedia(prompt.thumbnailFile, "image")
                        .then(url => {
                            prompt.thumbnail = url;
                            prompt.thumbnailFile = null;
                            return url;
                        })
                );
            }
        });

        return promises;
    }

    createPromiseForMedia(
        file: File, 
        type: "video" | "image" | "file"
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url),
                    error => reject(error)
                );
        });
    }

    delete() {
        this.deleting = true;
        this.lessonService.delete(this.lesson.lessonId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/sel-lessons"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " +
                this.lesson.title + " lesson from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onUnitChange($event: string) {
        this.lesson.order = 0;
        this.loadAvailableOrders($event, this.lesson.lessonId);
    }

    onAgeChange($event: string) {
        this.lesson.unitId = "";
        this.loadAvailableUnits($event, this.lesson.lessonId);
    }

    onStepsChange($event: SelLesson) {
        this.lesson.steps = $event.steps;
    }

    goBack() {
        this.router.navigate(["/sel-lessons"]);
    }

    radioBtnEmmited(radioId: string) {
        this.radioOptionSelected = radioId;

        if (radioId === 'skill') {
            this.lesson.skillOrImplementation  = 'skill';
        } else if (radioId === 'implementation') {
            this.lesson.skillOrImplementation = 'implementation';
        } else {
            this.lesson.skillOrImplementation = null;
        }
    }

    checkSkillReviewFile() {
        if (this.lesson.skillOrImplementation == null)
            this.lesson.skillReviewImplementationFile = '';
    }
}