import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { ContentListComponent } from './content-list.component';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ListViewModule } from 'src/app/shared/list/list-view/list-view.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ListToolbarModule } from 'src/app/shared/list/list-toolbar/list-toolbar.module';
import { ListPaginatorModule } from 'src/app/shared/list/list-paginator/list-paginator.module';
import { CopyToClipboardModule } from 'src/app/shared/copy-to-clipboard/copy-to-clipboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        ContentListComponent
    ],
    imports: [
        SharedModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule,
        SpinnerModule,
        ListViewModule,
        TextfieldModule,
        ListToolbarModule,
        ListPaginatorModule,
        CopyToClipboardModule,
    ],
})
export class ContentListModule { }