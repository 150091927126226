import { MiniGame } from 'src/app/models/mini-games/mini-game.model';
import { MiniGameService } from 'src/app/services/mini-game.service';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { MiniGameList } from 'src/app/models/mini-games/mini-game-list.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-mini-game-picker',
    templateUrl: './mini-game-picker.component.html',
    styleUrls: ['./mini-game-picker.component.scss']
})
export class MiniGamePickerComponent implements OnInit {
    @Input() label:string;
    @Input() error:string;
    @Input() miniGame:MiniGame;
    @Input() pickerOpened:boolean;
    @Input() layout:"column"|"row" = "column";

    @Output() onCancelCallback = new EventEmitter<void>();
    @Output() miniGameChange = new EventEmitter<MiniGame>();

    headers = [
        { label:"Title", key:"title", sortable: true },
        { label:"Parent Game", key:"contentId", sortable: true },
        { label:"Date", key:"createdAt", sortable: true },
        { label:"Select" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    miniGamesList:MiniGameList;
    sortingParams:{ key?:string, asc?:boolean };

    constructor(private miniGameService:MiniGameService) { }

    ngOnInit(): void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:ListParams = { page: this.currentPage, limit: 10 };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.miniGameService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                response.miniGames.forEach(c => {
                    c.createdAt = 
                        new Date(c.createdAt).toLocaleDateString('en-US');
                });
                this.miniGamesList = response;
            });
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onCancel() {
        this.onCancelCallback.emit();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    onMiniChange(newContent) {
        this.miniGame = newContent;
        this.miniGameChange.emit(this.miniGame);
    }
}
