import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelAgesListComponent } from './sel-ages-list.component';
import { ListViewModule } from 'src/app/shared/list/list-view/list-view.module';
import { ListToolbarModule } from 'src/app/shared/list/list-toolbar/list-toolbar.module';
import { CopyToClipboardModule } from 'src/app/shared/copy-to-clipboard/copy-to-clipboard.module';

@NgModule({
    declarations: [
        SelAgesListComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        ListViewModule,
        ListToolbarModule,
        CopyToClipboardModule,
    ],
})
export class SelAgesListModule { }