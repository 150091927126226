import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivityMatching, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
  selector: 'app-activity-matching',
  templateUrl: './activity-matching.component.html',
  styleUrls: ['./activity-matching.component.scss'],
})
export class ActivityMatchingComponent implements OnInit {
  @Input() activityMatching: InteractiveActivityMatching;
  @Input() emptyMandatoryFields = Array<string>();

  @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

  constructor() {}

  ngOnInit(): void {
    if (Object.keys(this.activityMatching).length === 0) {
      this.activityMatching.items = [];
      this.activityMatching.items = Array.from({ length: 5 }, () => ({
        left: '',
        right: '',
      }));
      this.activityMatching.isAutoCheck = true;
      this.activityMatching.leftCategoryTitle = '';
      this.activityMatching.rightCategoryTitle = '';
    } else {
        if (this.activityMatching.items.length < 5) {
            let activityLength = this.activityMatching.items.length;
            let necessaryItems = Array.from({ length: (5 - activityLength) }, () => ({
                left: '',
                right: '',
              }));
            this.activityMatching.items = [...this.activityMatching.items, ...necessaryItems];
        }
    }
  }

  maxCharsValidation(isMaxReached: boolean, field: string) {
    this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field});
  }
}
