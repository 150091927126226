import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { ContentEditorComponent } from './content-editor.component';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { ContentEdswComponent } from './content-edsw/content-edsw.component';
import { ContentGameComponent } from './content-game/content-game.component';
import { TagfieldModule } from 'src/app/shared/form/tagfield/tagfield.module';
import { TextareaModule } from 'src/app/shared/form/textarea/textarea.module';
import { ContentVideoComponent } from './content-video/content-video.component';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ContentActivityModule } from './content-activity/content-activity.module';
import { WtdSectionModule } from 'src/app/shared/form/wtd-section/wtd-section.module';
import { MultiSelectModule } from 'src/app/shared/form/multi-select/multi-select.module';
import { RichtextareaModule } from 'src/app/shared/form/richtextarea/richtextarea.module';
import { ListItemPickerModule } from 'src/app/shared/form/list-item-picker/list-item-picker.module';
import { ActivityPickerModule } from 'src/app/shared/form/activity-picker/activity-picker.module';

@NgModule({
    declarations: [
        ContentEdswComponent,
        ContentGameComponent,
        ContentVideoComponent,
        ContentEditorComponent,
    ],
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        RouterModule,
        SelectModule,
        ButtonModule,
        TagfieldModule,
        TextareaModule,
        TextfieldModule,
        WtdSectionModule,
        MultiSelectModule,
        RichtextareaModule,
        ListItemPickerModule,
        ContentActivityModule,
        ActivityPickerModule
    ],
})
export class ContentEditorModule { }