import { Injectable } from '@angular/core';
import { AlertDialog } from '../models/dialog-message/alert-dialog.model';
import { ConfirmDialog } from '../models/dialog-message/confirm-dialog.model';
import { InformativeModal, InformativeModalProps, LoadingDialog } from '../models/dialog-message/loading-dialog.model';

export const alertDialog: AlertDialog = {};
export const confirmDialog: ConfirmDialog = {};
export const informativeModal: InformativeModal = {};

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    constructor() { }

    showInfoAlert(msg) {
        alertDialog.message = msg;
        alertDialog.type = "primary";
    }

    showSuccessAlert(msg) {
        alertDialog.message = msg;
        alertDialog.type = "success";
    }

    showErrorAlert(msg) {
        alertDialog.message = msg;
        alertDialog.type = "danger";
    }

    showConfirmationDialog(props: ConfirmDialog) {
        confirmDialog.type = props.type;
        confirmDialog.title = props.title;
        confirmDialog.message = props.message;
        confirmDialog.warningMessage = props.warningMessage;
        confirmDialog.dialogIconUrl = props.dialogIconUrl;
        confirmDialog.headerMessage = props.headerMessage;
        confirmDialog.onConfirmCallback = props.onConfirmCallback;
        confirmDialog.confirmButtonLabel = props.confirmButtonLabel;
        confirmDialog.confirmButtonIconUrl = props.confirmButtonIconUrl;
        confirmDialog.cancelLabel = (props.cancelLabel && props.cancelLabel !== "") ? props.cancelLabel : "Cancel";
    }

    showInformativeModal(informativeProps: InformativeModalProps) {
        informativeModal[informativeProps.typeModal] = {};
        informativeModal[informativeProps.typeModal].message = informativeProps.props.message;
        informativeModal[informativeProps.typeModal].type = informativeProps.typeModal;
        informativeModal[informativeProps.typeModal].title = informativeProps.props.title;
    }
}
