import { Component, OnInit } from '@angular/core';
import { alertDialog, confirmDialog, informativeModal,  } from 'src/app/services/dialog.service';
import { AlertDialog } from 'src/app/models/dialog-message/alert-dialog.model';
import { ConfirmDialog } from 'src/app/models/dialog-message/confirm-dialog.model';
import { InformativeModal } from 'src/app/models/dialog-message/loading-dialog.model';

@Component({
    selector: 'message-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
    public alertDialog: AlertDialog;
    public confirmDialog: ConfirmDialog;
    public informativeModal: InformativeModal;

    constructor() {
        this.alertDialog = alertDialog;
        this.confirmDialog = confirmDialog;
        this.informativeModal = informativeModal;
    }

    ngOnInit() {
    }

    confirm() {
        this.close();

        if (confirmDialog.onConfirmCallback)
            confirmDialog.onConfirmCallback();
    }

    close() {
        alertDialog.message = "";
        alertDialog.type = undefined;

        confirmDialog.message = "";
        confirmDialog.type = undefined;

        informativeModal.loadingDialog = {};
        informativeModal.successDialog = {};
        informativeModal.errorDialog = {};
    }

    getIconUrl() {
        if (alertDialog.type === "success")
            return "../../../../assets/images/icons/ok.svg";
        else if (alertDialog.type === "danger")
            return "../../../../assets/images/icons/error.svg";

        return null;
    }
}
