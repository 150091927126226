import { Component, Input, OnInit } from '@angular/core';
import { InteractiveActivityFillBlanks } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
  selector: 'app-activity-fill-blanks',
  templateUrl: './activity-fill-blanks.component.html',
  styleUrls: ['./activity-fill-blanks.component.scss'],
})
export class ActivityFillBlanksComponent implements OnInit {
  @Input() activityFillBlanks: InteractiveActivityFillBlanks;
  @Input() emptyMandatoryFields = Array<string>();

  constructor() {}

  ngOnInit(): void {
    if (Object.keys(this.activityFillBlanks).length === 0) {
        this.activityFillBlanks.items = [];
        this.activityFillBlanks.items = Array.from({length: 10}, () => ({beforeKeyword: '', keyword: '', afterKeyword: ''}))
        this.activityFillBlanks.isAutoCheck = true;
    } else {
        if (this.activityFillBlanks.items.length < 10) {
            let activityLength = this.activityFillBlanks.items.length;
            let necessaryItems = Array.from({length: (10 - activityLength)}, () => ({beforeKeyword: '', keyword: '', afterKeyword: ''}));
            this.activityFillBlanks.items = [...this.activityFillBlanks.items, ...necessaryItems];
        }
    }
  }
}
