import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SpinnerModule } from '../../spinner/spinner.module';

@NgModule({
    declarations: [
        ButtonComponent,
    ],
    imports: [
        CommonModule,
        TooltipModule,
        SpinnerModule,
    ],
    exports: [
        ButtonComponent
    ]
})
export class ButtonModule { }