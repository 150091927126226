import { Content } from 'src/app/models/content/content.model';
import { ContentService } from 'src/app/services/content.service';
import { ContentList } from 'src/app/models/content/content-list.model';
import { ContentListParams } from 'src/app/models/content/content-list-params.model';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-content-picker',
    templateUrl: './content-picker.component.html',
    styleUrls: ['./content-picker.component.scss'],
})
export class ContentPickerComponent implements OnInit, OnChanges {
    @Input() type: string;
    @Input() label: string;
    @Input() error: string;
    @Input() content: Content;
    @Input() pickerOpened: boolean;
    @Input() title: string = 'Content';
    @Input() selectorTitle: string = 'Select Content';
    @Input() layout: 'column' | 'row' = 'column';
    @Input() displayOriginalButton: boolean = true;
    @Input() displayBtnIcon: boolean = true;

    @Output() contentChange = new EventEmitter<Content>();
    @Output() onCancelCallback = new EventEmitter<void>();

    headers = [
        { label: 'Thumbnail', sortable: false },
        { label: 'Title', key: 'title', sortable: true },
        { label: 'Type', key: 'contentType', sortable: true },
        { label: 'Status', sortable: false },
        { label: 'Select' },
    ];

    currentPage = 1;
    searchTerm = '';
    sorting = false;
    loading = false;
    contentsList: ContentList;
    sortingParams: { key?: string; asc?: boolean };

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.loadList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type?.currentValue) this.loadList();
    }

    loadList(hideLoader?: boolean): void {
        var params: ContentListParams = { page: this.currentPage, limit: 10 };
        if (this.type) params.type = this.type;

        if (this.searchTerm) params.text = this.searchTerm;

        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? 'asc' : 'desc';
        }

        if (!hideLoader) this.loading = true;

        this.contentService.list(params).subscribe((response) => {
            this.sorting = false;
            this.loading = false;
            this.contentsList = response;
        });
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params: { key: string; asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    openModal() {
        this.pickerOpened = true;
    }

    onCancel() {
        this.onSearchChange(''); // clean the search after close the modal
        this.onCancelCallback.emit();
    }

    onContentChange(newContent) {
        this.content = newContent;
        this.contentChange.emit(this.content);
    }
}
