<app-header>
    <a routerLink="/sel-units">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back" />
    </a>
    <span *ngIf="!loading">{{!unit.unitId ? "New" : unit.title}} SEL Unit</span>
    <div *ngIf="!loading" class="actions">
        <app-button type="primary" [loading]="saving" (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg">
            Save
        </app-button>
        <app-button type="warning" (click)="goBack()" tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg">
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <div class="tabs">
        <button (click)="activeTab = 0" [class]="activeTab === 0 ? 'active' : ''">
            Unit Info
        </button>
        <button (click)="activeTab = 1" [class]="activeTab === 1 ? 'active' : ''">
            Support Bundle
        </button>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngIf="activeTab === 0">
            <div class="flex-row">
                <div class="column">
                    <app-select size="medium" label="Age" [(value)]="unit.ageId" [options]="availableAges"
                    (valueChange)="onAgeChange($event)"></app-select>
                    <app-select size="medium" [(value)]="unit.order" [options]="availableOrders" label="Order in Parent Age">
                    </app-select>
                    <hr class="divisor">
                    <app-textfield label="Unit Number (prefix)" [(value)]="unit.unitNumber"></app-textfield>
                    <app-textfield label="Unit title" [(value)]="unit.title"
                        [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''">
                    </app-textfield>
                    <app-richtextarea label="Unit Summary" [(value)]="unit.summary" placeholder="Place your text">
                    </app-richtextarea>
                    <app-file-picker type="pdf" id="summary-pdf" [value]="unit.pdfSummary"
                        label="Shareable Unit Summary PDF"
                        (fileChange)="unit.pdfSummaryFile = $event; !$event && unit.pdfSummary = ''"
                        [error]="emptyMandatoryFields.includes('pdfSummary') ? 'This field is required.' : ''">
                    </app-file-picker>
                    <app-file-picker type="pdf" id="bulletin-pdf" label="School Wide Bulletin Board"
                        [value]="unit.pdfBulletinBoardLink"
                        (fileChange)="unit.pdfBulletinBoardFile = $event; !$event && unit.pdfBulletinBoardLink = ''"
                        [error]="emptyMandatoryFields.includes('pdfBulletinBoard') ? 'This field is required.' : ''">
                    </app-file-picker>
                    <h4 class="assessments-observational-description">Assessments & observational checklist (optional)</h4>
                    <p class="assessments-observational-description-info">NOTE: As an internal rule, each Grade Unit can only have either Assessments or Observational Checklist. It cannot have both.</p>
                    <app-switch id="assessments" label="Assessments" [value]="isAssessments"
                        (valueChange)="updateAssesments($event)"></app-switch>
                    <app-file-picker type="pdf" id="pre-assessment-pdf" label="Pre-Assessment"
                        [value]="unit.unitPreAssessmentLink"
                        (fileChange)="unit.unitPreAssessmentFile = $event; !$event && unit.unitPreAssessmentLink = ''"
                        [error]="emptyMandatoryFields.includes('unitPreAssessment') ? 'This field is required.' : ''"
                        [isDisabled]="!isAssessments">
                    </app-file-picker>
                    <app-file-picker type="pdf" id="post-assessment-pdf" label="Post-Assessment"
                        [value]="unit.unitPostAssessmentLink"
                        (fileChange)="unit.unitPostAssessmentFile = $event; !$event && unit.unitPostAssessmentLink = ''"
                        [error]="emptyMandatoryFields.includes('unitPostAssessment') ? 'This field is required.' : ''"
                        [isDisabled]="!isAssessments">
                    </app-file-picker>
                    <p class="divider-section">Or</p>
                    <app-switch id="observationalChecklist" label="Observational Checklist" [value]="!isAssessments"
                        (valueChange)="updateAssesments(!$event)"></app-switch>
                        <!-- TODO: Update value for observational checklist -->
                    <app-file-picker type="pdf" id="pre-observational-checklist-pdf" label="Observational Checklist (Pre-Test)"
                        [value]="unit.observationalChecklistPre"
                        (fileChange)="unit.unitPreObservationalFile = $event; !$event && unit.observationalChecklistPre = ''"
                        [error]="emptyMandatoryFields.includes('unitPreObservational') ? 'This field is required.' : ''"
                        [isDisabled]="isAssessments">
                    </app-file-picker>
                    <app-file-picker type="pdf" id="post-observational-checklist-pdf" label="Observational Checklist (Post-Test)"
                        [value]="unit.observationalChecklistPost"
                        (fileChange)="unit.unitPostObservationalFile = $event; !$event && unit.observationalChecklistPost = ''"
                        [error]="emptyMandatoryFields.includes('unitPostObservational') ? 'This field is required.' : ''"
                        [isDisabled]="isAssessments">
                    </app-file-picker>
                </div>
                <div class="column">
                    <div class="drafts-container">
                        <div class="switch-label-section">
                            <app-switch id="active" label="Status" [value]="unit.status === 'active' ? true : false"
                            (valueChange)="updateSwitchStatus($event)"></app-switch>
                            <p *ngIf="unit.status === 'active'">Active</p>
                            <p *ngIf="unit.status !== 'active'">Draft</p>
                        </div>
                        <div class="draft-section">
                            <h4>Text Display For Drafts</h4>
                            <p>is the message our users will see for units that are still in Draft Mode.</p>
                            <app-textfield label="text display for drafts (line 1)" [(value)]="unit.textDisplayTitle" size="small"
                                [error]="emptyMandatoryFields.includes('textDisplayTitle') ? 'This field is required.' : ''" [disabled]="unit.status === 'active'"
                                placeholder="e.g. UNIT CONTENTS COMING SOON"
                            >
                            </app-textfield>
                            <app-textfield label="text display for drafts (line 2)" [(value)]="unit.textDisplaySubtitle" size="small"
                                [error]="emptyMandatoryFields.includes('textDisplaySubtitle') ? 'This field is required.' : ''" [disabled]="unit.status === 'active'"
                                placeholder="e.g. Check back on August 1, 2023"
                            >
                            </app-textfield>
                        </div>
                    </div>
                    <div class="thumb-picker">
                        <img [src]="unit.icon || '../../../../assets/images/icons/image-placeholder.svg'" />
                        <app-file-picker id="icon" type="image" size="small" [value]="unit.icon" label="Unit Topic Icon"
                            (fileChange)="unit.iconFile = $event; !$event && unit.icon = ''"></app-file-picker>
                    </div>
                    <div class="thumb-picker">
                        <app-lottie-player *ngIf="unit.mascot" height="120" [loop]="true" [autoplay]="true"
                            [url]="unit.mascot" [showControls]="true"></app-lottie-player>
                        <img *ngIf="!unit.mascot" src="../../../../assets/images/icons/image-placeholder.svg" />
                        <app-file-picker id="mascot" type="json" size="small" [value]="unit.mascot"
                            label="Mascot Lottie File (JSON) (Optional)"
                            (fileChange)="unit.mascotFile = $event; !$event && unit.mascot = ''"></app-file-picker>
                    </div>
                    <div class="thumb-picker" style="position: relative;">
                        <h3 class="completion-badge-text">Completion Badge</h3>
                        <img class="width-badge-image" [src]="unit.badgeImageLink || '../../../../assets/images/icons/image-placeholder.svg'" />
                        <div class="badge-section">
                            <app-file-picker id="badge" type="image" size="small" [value]="unit.badgeImageLink"
                                label="Badge Image"
                                (fileChange)="unit.badgeImageFile = $event; !$event && unit.badgeImageLink = ''">
                            </app-file-picker>
                            <app-file-picker id="badgePdf" type="file" size="small" [value]="unit.pdfBadgeLink" label="Badge PDF"
                                (fileChange)="unit.pdfBadgeFile = $event; !$event && unit.pdfBadgeLink = ''">
                            </app-file-picker>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab === 1">
            <div class="content-picker-row" *ngFor="let b of unit.bundles;let i = index">
                <label *ngIf="unit.bundles[i]?.bundleId || unit.bundles[i]?.contentId">{{i + 1}}</label>
                <app-content-picker layout="row" title="Support Bundle" [content]="unit.bundles[i]"
                    (contentChange)="onBundleChange($event, i)"></app-content-picker>
            </div>
        </div>
        <div class="dangerous-area" *ngIf="unit.unitId">
            <app-button type="danger" [loading]="deleting" (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg">
                Delete
            </app-button>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>