import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { AgeTrackList } from 'src/app/models/age-track/age-track-list.model';
import { AgeTrackListParams } from 'src/app/models/age-track/age-track-list-params.model';

@Component({
    selector: 'app-sel-ages-list',
    templateUrl: './sel-ages-list.component.html',
    styleUrls: ['./sel-ages-list.component.scss']
})
export class SelAgesListComponent implements OnInit {
    headers = [
        { label: "No", key: "order", sortable: true },
        { label: "Age Track", key: "title", sortable: true },
        { label: "Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    agesList: AgeTrackList;
    selCurriculumId: string;
    sortingParams: { key?: string, asc?: boolean };

    constructor(
        private router: Router,
        private authService: AuthService,
        private ageService: AgeTrackService,
        private dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this.loadList();
    }

    loadList(hideLoader?: boolean): void {
        var params: AgeTrackListParams = { 
            page: this.currentPage,
            curriculum: environment.selCurriculumSlug,
        };

        if (this.searchTerm)
            params.text = this.searchTerm;

        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.ageService.list(params)
            .subscribe(
                response => {
                    this.sorting = false;
                    this.loading = false;
                    this.agesList = response;
                },
                errors => {
                    this.sorting = false;
                    this.loading = false;
                    this.dialogService.showErrorAlert(formatError(errors));
                    if (errors.status === 401) {
                        this.authService.clearSession();
                        this.router.navigate(['/login']);
                    }
                }
            );
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params: { key: string, asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }
}
