import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Content } from 'src/app/models/content/content.model';

@Component({
    selector: 'content-game',
    templateUrl: './content-game.component.html',
    styleUrls: ['./content-game.component.scss']
})
export class ContentGameComponent implements OnInit {
    @Input() content:Content;
    @Input() emptyMandatoryFields = Array<string>();
    
    @Output() contentChange = new EventEmitter<Content>();

    constructor() { }

    ngOnInit() {
        if (!this.content.configFile)
            this.content.configFile = "";
    }
}
