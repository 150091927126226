import { 
    Input, 
    OnInit, 
    Output,
    Component, 
    EventEmitter, 
} from '@angular/core';
import { ActivitiesResponseList, IATypes, InteractiveActivity, InteractiveActivityResponse, InteractiveTypesFilter } from 'src/app/models/interactive-activity/interactive-activity.model';
import { InteractiveActivityListParams } from 'src/app/models/interactive-activity/interactive-activity-list-params.model';
import { InteractiveActivityService } from 'src/app/services/interactive-activity.service';

@Component({
    selector: 'app-activity-picker',
    templateUrl: './activity-picker.component.html',
    styleUrls: ['./activity-picker.component.scss']
})
export class ActivityPickerComponent implements OnInit {
    @Input() link: string;
    @Input() interactiveActivityId: string;
    @Input() label: string;
    @Input() error: string;
    @Output() linkChange = new EventEmitter<string>();
    @Output() interactiveActivityIdChange = new EventEmitter<string>();
    @Output() contentChange = new EventEmitter<InteractiveActivity>();
    
    headers = [
        { label:"Title", key:"title", sortable: true },
        { label:"Date", key:"releaseDate", sortable: true },
        { label:"Select" },
    ];
    headersIa = [
        { label: "Title", key: "title", sortable: true },
        { label: "Activity Type", key: "interactiveActivityTypeTitle", sortable: true },
        { label: "Date Edited", key: "updatedAt", sortable: true },
        { label: "Date Created", key: "createdAt", sortable: true },
        { label: "Linked Content", key: "contentTitle", sortable: true },
        { label: "Select" },
    ];
    content: InteractiveActivity;
    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    activitiesList: ActivitiesResponseList;
    typeOptions = [
        { id: 1, label: "All" },
        { id: 2, label: "Fill in the blanks" },
        { id: 3, label: "Matching" },
        { id: 4, label: "Q&A" },
        { id: 5, label: "Spinner" },
        { id: 6, label: "Sorting" },
        { id: 7, label: "Flashcards" },
        { id: 8, label: "Dice Roller" },
        { id: 9, label: "Multiple Choice" },
    ];
    selectedPicker: "link" | "content";
    sortingParams: { key?: string, asc?: boolean };
    activitiesFilter: IATypes[];
    iaTypes: typeof InteractiveTypesFilter = InteractiveTypesFilter;
    allIaTypes = [];

    constructor(private iaService: InteractiveActivityService) { }

    ngOnInit(): void {
        this.loading = true;
        Object.values(this.iaTypes).forEach(iaType => this.allIaTypes.push(iaType));
        this.interactiveActivityId ? this.loadListById(this.interactiveActivityId) : this.loadList();
        if (this.link)
            this.selectedPicker = "link";
        else if (this.content)
            this.selectedPicker = "content";
    }

    loadList(hideLoader?: boolean):void {
        var params: InteractiveActivityListParams = { page: this.currentPage, limit: 10 };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (this.activitiesFilter && this.activitiesFilter.length > 0) {
            params['interactiveActivityTypes'] = this.activitiesFilter.map(m => m);
        } else {
            params['interactiveActivityTypes'] = this.allIaTypes;
        }

        if (!hideLoader)
            this.loading = true;

        this.iaService.getList(params)
            .subscribe((response: ActivitiesResponseList) => {
                this.sorting = false;
                this.loading = false;
                console.log('response', response)

                response.interactiveActivities.forEach(c => {
                    c.createdAt = 
                        new Date(c.createdAt).toLocaleDateString('en-US');
                    c.updatedAt = 
                        new Date(c.updatedAt).toLocaleDateString('en-US');
                });
                this.activitiesList = response;
            });
    }

    loadListById(activityId: string) {
        this.iaService.getById(activityId).subscribe(
            (response: InteractiveActivityResponse) => {
                this.sorting = false;
                this.loading = false;

                this.content = response.interactiveActivity;
            }
        )
    }

    showLinkPicker() {
        this.selectedPicker = "link";
    }

    showContentPicker() {
        this.selectedPicker = "content";
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key: string, asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    onSelectContent(selectedContent: InteractiveActivity) {
        this.content = selectedContent;
        this.selectedPicker = undefined;
        this.contentChange.emit(selectedContent);
        this.interactiveActivityId = selectedContent.interactiveActivityId;
        this.interactiveActivityIdChange.emit(selectedContent.interactiveActivityId)
    }

    removeLinkedIa() {
        this.interactiveActivityId = null;
        this.content = undefined;
        this.selectedPicker = undefined;
        this.interactiveActivityIdChange.emit(null);

        if (!this.activitiesList)
            this.loadList();

        this.cleanIAListVariables();
    }

    onLinkChange(newLink) {
        this.link = newLink;
        this.linkChange.emit(newLink);
    }

    onTypeChange(typeId) {
        const typeFiltered = this.typeOptions.find(type => type.id == typeId)
        const iaType = this.iaTypes[typeFiltered.label];
        this.activitiesFilter = [];

        if (iaType)
            this.activitiesFilter.push(iaType);

        this.loadList();
    }

    cleanIAListVariables() {
        if (this.searchTerm || this.activitiesFilter.length > 0 || this.sortingParams) {
            this.searchTerm = undefined;
            this.activitiesFilter = [];
            this.sortingParams = undefined;
            this.loadList();
        }
    }
}
