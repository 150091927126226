import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
    @Input() id: string;
    @Input() label: string;
    @Input() value: boolean;
    @Input() activeLabel: string = null;
    @Input() inactiveLabel: string = null;
    @Input() onColor: "green" | string = null;
    @Input() offColor: string = null;
    @Input() onIcon: string = null;
    @Input() offIcon: string = null;
    @Input() defaultView: boolean = false;

    @Output() valueChange = new EventEmitter<boolean>();

    constructor() { }

    isChecked: boolean;

    ngOnInit(): void {
        this.isChecked = this.value ? true : false;
    }

    onChange(e: any) {
        let v = e.target.checked;
        this.valueChange.emit(v);
        this.isChecked = v;
    }
}
