<div *ngIf="activitySpinner.items" class="spinner-container">
    <h2 class="title">Scenarios</h2>
    <ng-container *ngFor="let scenario of activitySpinner.items; let i = index">
        <div class="question-container">
            <span class="index-number">{{ i + 1 }} <b *ngIf="i < 3">*</b></span>
            <app-textarea 
                placeholder="e.g. Bored"
                [(value)]="scenario.scenario"
                maxChars="120"
                [error]="emptyMandatoryFields.includes('scenario' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'scenario-' + i)"
                data-cy="scenario-input"
            >
            </app-textarea>
        </div>
        <hr>
    </ng-container>
    <div class="auto-check-section">
        <app-textarea 
            label="Review Prompt"
            data-cy="review-prompt-input"
            [placeholder]="'Big picture concepts you want your students to think about, or activities to do once the worksheet is completed.'
            + '&#10;e.g.: Our emotions change all the time. Recognizing them using our body and thoughts helps us feel in control.'"
            [(value)]="activitySpinner.reviewPrompt" maxChars="180"
            [error]="emptyMandatoryFields.includes('reviewPrompt') ? 'This field is required.' : ''"
            [isRequired]="true"
            (maxCharsReached)="maxCharsValidation($event, 'prompt-spinner')"
        ></app-textarea>
    </div>
</div>
