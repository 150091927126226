<div *ngIf="activityDiceRoller.items" class="diceroller-container">
    <ng-container *ngFor="let dieSide of activityDiceRoller.items; let i = index">
        <h2 class="title">Die Side {{ i + 1 }}</h2>
        <div class="die-sides-container">
            <app-textfield label="Prompt" placeholder="E.g.: “What's wrong?” (concerned)" [(value)]="dieSide.prompt"
                maxChars="45"
                isRequired="true"
                [error]="emptyMandatoryFields.includes('prompt' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'prompt-' + 1)"
                data-cy="prompt-input"
            ></app-textfield>
            <app-file-picker [id]="'iconImageFile' + i" type="image" label="Emoji or skills icon (OPTIONAL)" [value]="dieSide.image"
                (fileChange)="dieSide.iconImageFile = $event; !$event && dieSide.image = ''"
            >
            </app-file-picker>
        </div>
        <hr>
    </ng-container>
</div>