import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.scss']
})
export class TextfieldComponent implements OnInit {
    @Input() value = "";
    @Input() label: string;
    @Input() error: string;
    @Input() type = "text";
    @Input() iconUrl: string;
    @Input() readonly: boolean;
    @Input() disabled: boolean;
    @Input() placeholder = "";
    @Input() inlineLabel: boolean;
    @Input() feedback: "success" | "info" | "warning" | "error";
    @Input() size: "tiny" | "small" | "medium" | "large" | "full" = "medium";
    @Input() maxChars: number;
    @Input() isRequired: boolean = false;
    @Input() iconLinkUrl: string;
    @Input() inputPaddingRight: string;
    @Input() inputBackgroundColor: string;

    @Output() valueChange = new EventEmitter<string>();
    @Output() isFocusOut = new EventEmitter<boolean>();
    @Output() maxCharsReached = new EventEmitter<boolean>();

    debounceTimer: any;
    inputTextLength: number;
    isMaxCharsReached: boolean;

    constructor() { }

    ngOnInit(): void {
        this.validateMaxChars(this.value?.length)
        if (!this.value) {
            if ((this.type === "date" || this.type === "datetime-local"))
                this.value = new Date().toISOString();
            else
                this.value = "";
        }
    }

    onChange(e: Event & { target: HTMLInputElement }) {
        if (this.debounceTimer)
            clearInterval(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
            var v = e.target.value;
            if (v) {
                if (this.type === "datetime-local")
                    v = v.includes(":00.000Z") ? v : (v + ":00.000Z");
                else if (this.type === "date")
                    v = new Date(v).toISOString();
            }

            this.valueChange.emit(v);
        }, 400);
    }

    getDate(d: string) {
        if (this.type === "date")
            return d.replace(/T.+/, "");

        if (this.type === "datetime-local")
            return d.substr(0, d.lastIndexOf(":"));

        return d;
    }

    emitOutput(typeOutput: string): void {
        setTimeout(() => {
            if (typeOutput === 'blur')
                this.isFocusOut.emit(true);
        }, 300);
    }

    validateMaxChars(textLength: number): void {
        if (this.error && textLength > 0) {
            this.error = "";
        }

        this.inputTextLength = textLength;
        this.isMaxCharsReached = textLength > this.maxChars ? true : false;
        this.maxCharsReached.emit(this.isMaxCharsReached);

        this.error = this.isMaxCharsReached ? "Limit text to character count." : '';
    }

    validateRequired(textLength: number): void {
        if (this.isRequired) {
            if (textLength === 0) {
                this.error = "This field is required."
            }
            if (textLength > 0 && !this.maxCharsReached) {
                this.error = "";
            }
        }
    }
}
