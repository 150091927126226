import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentSeries } from 'src/app/models/content-series/content-series.model';
import { Content } from 'src/app/models/content/content.model';
import { ContentSeriesService } from 'src/app/services/content-series.service';
import { ContentTypeService } from 'src/app/services/content-type.service';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { PlanService } from 'src/app/services/plan.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

@Component({
    selector: 'app-content-series-editor',
    templateUrl: './content-series-editor.component.html',
    styleUrls: ['./content-series-editor.component.scss']
})
export class ContentSeriesEditorComponent {
    contentSeries:ContentSeries = {
        title: "",
        active: true,
        createdAt: "",
        contents: [""],
        contentDetails: [{
            url: "", 
            type: "",
            title: "", 
            contentId: "", 
            releaseDate: "", 
        }],
    };
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    emptyMandatoryFields = Array<string>();
    availablePlans:Array<{id:string, label:string}> = [];
    availableCurriculums:Array<{id:string, label:string}> = [];
    availableContentTypes:Array<{id:string, label:string}> = [];
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private httpService:HttpService,
        private planService:PlanService,
        private dialogService:DialogService,
        private curriculumService:CurriculumService,
        private contentTypeService:ContentTypeService,
        private contentSeriesService:ContentSeriesService,
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;   
            this.loadAvailablePlans(data.contentSeriesId);
            this.loadAvailableCurriculums(data.contentSeriesId);
            this.loadAvailableContentTypes(data.contentSeriesId);

            if (data.contentSeriesId !== "new")
                this.load(data.contentSeriesId);
        });
    }

    loadAvailableContentTypes(contentSeriesId:string) {
        this.contentTypeService.list()
            .subscribe(response => {
                this.availableContentTypes = response.contentTypes.map(c => {
                    return { id: c.contentTypeId, label: c.title };
                });

                if (contentSeriesId === "new")
                    this.contentSeries.typeId = this.availableContentTypes[0].id;
            });
    }

    loadAvailableCurriculums(contentSeriesId:string) {
        this.curriculumService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableCurriculums = response.curriculums.map(c => {
                    return { id: c.curriculumId, label: c.title };
                });

                if (contentSeriesId === "new")
                    this.contentSeries.curriculums = [];
            });
    }

    loadAvailablePlans(contentId?:string) {
        this.planService.list({ limit: 1000 })
            .subscribe(response => {
                this.availablePlans = response.plans.map(c => {
                    return { id: c.planId, label: c.title };
                });

                if (contentId === "new")
                    this.contentSeries.plans = [];

                this.loading = false;
            });
    }

    load(contentSeriesId:string) {
        this.contentSeriesService.get(contentSeriesId)
            .subscribe(response => {
                this.loading = false;
                this.contentSeries = response.contentSeries;
                if (!this.contentSeries.tags)
                    this.contentSeries.tags = [];
                if (!this.contentSeries.plans)
                    this.contentSeries.plans = [];
                if (!this.contentSeries.curriculums)
                    this.contentSeries.curriculums = [];

                if (!this.contentSeries.contentDetails)
                    this.contentSeries.contentDetails = [];

                this.contentSeries.contentDetails.push({
                    url: "", 
                    type: "",
                    title: "", 
                    contentId: "", 
                    releaseDate: "", 
                });
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        var mediaPromises = this.getMediaReadyForUpload();
        Promise.all(mediaPromises).then(() => {
            this.contentSeriesService.save(this.contentSeries).subscribe(response => {
                this.saving = false;
                this.dialogService.showSuccessAlert("Save Successful.");
                if (response)
                    this.router.navigate(["/content-series/" + response.contentSeriesId]);
            }, errors => {
                this.saving = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            });
        });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.contentSeries.title)
            this.emptyMandatoryFields.push("title");
        if (!this.contentSeries.thumbnailFile && !this.contentSeries.thumbnail)
            this.emptyMandatoryFields.push("thumbnail");
        if (!this.contentSeries.curriculums || this.contentSeries.curriculums.length <= 0)
            this.emptyMandatoryFields.push("curriculums");
        if (!this.contentSeries.plans || this.contentSeries.plans.length <= 0)
            this.emptyMandatoryFields.push("plans");
        
        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
        
        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload():Promise<string>[] {
        var promises:Promise<string>[] = [];
        if (this.contentSeries.thumbnailFile) {
            promises.push(
                this.createPromiseForMedia(this.contentSeries.thumbnailFile, "image")
                    .then(url => {
                        this.contentSeries.thumbnail = url;
                        this.contentSeries.thumbnailFile = null;
                        return url;
                    })
            );
        }

        if (this.contentSeries.animatedThumbnailFile) {
            promises.push(
                this.createPromiseForMedia(this.contentSeries.animatedThumbnailFile, "video")
                    .then(url => {
                        this.contentSeries.animatedThumbnail = url;
                        this.contentSeries.animatedThumbnailFile = null;
                        return url;
                    })
            );
        }

        return promises;
    }
    
    createPromiseForMedia(file:File, type:"video"|"image"):Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url), 
                    error => reject(error)
                );
        });
    }

    delete() {
        this.deleting = true;
        this.contentSeriesService
            .delete(this.contentSeries.contentSeriesId)
            .subscribe(() => {
                this.deleting = false;
                this.dialogService.showSuccessAlert("Delete Successful.");
                this.router.navigate(["/content-series"]);
            });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.contentSeries.title + " series from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onContentDetailsChange($event:Content, i:number) {
        if (!$event) {
            this.contentSeries.contents.splice(i, 1);
            this.contentSeries.contentDetails.splice(i, 1);
            return;
        }
        
        this.contentSeries.contents[i] = $event.contentId;
        this.contentSeries.contentDetails[i].url = $event.url;
        this.contentSeries.contentDetails[i].type = $event.type;
        this.contentSeries.contentDetails[i].title = $event.title;
        this.contentSeries.contentDetails[i].contentId = $event.contentId;

        this.contentSeries.contentDetails.push({
            url: "", 
            type: "",
            title: "", 
            contentId: "", 
            releaseDate: "", 
        });
    }

    getPickerTitle() {
        return this.availableContentTypes
            .find(ct => ct.id === this.contentSeries.typeId)?.label;
    }

    goBack() {
        this.router.navigate(["/content-series"]);
    }
}
