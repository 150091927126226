import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { AgeTrackList } from "../models/age-track/age-track-list.model";
import { AgeTrackListParams } from "../models/age-track/age-track-list-params.model";
import { AgeTrack } from "../models/age-track/age-track.model";

@Injectable({
    providedIn: 'root'
})
export class AgeTrackService {
    constructor(private http: HttpService) { }

    list(params?: AgeTrackListParams): Observable<AgeTrackList> {
        return this.http.get('admin/ages', params);
    }

    get(ageId: string): Observable<AgeTrack> {
        return this.http.get('admin/ages/' + ageId).pipe(
            map(response => response?.age)
        );
    }

    save(ageTrack: AgeTrack): Observable<any> {
        if (ageTrack.ageId)
            return this.http.put('admin/ages/' + ageTrack.ageId, ageTrack);

        return this.http.post('admin/ages', ageTrack);
    }

    delete(ageId: string): Observable<any> {
        return this.http.delete('admin/ages/' + ageId);
    }
}
