import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpService } from './services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './pages/login/login.module';
import { BrowserModule } from '@angular/platform-browser';
import { StorageService } from './services/storage.service';
import { MenuComponent } from './shared/menu/menu.component';
import { DialogModule } from './shared/dialog/dialog.module';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UnitsListModule } from './pages/units/units-list/units-list.module';
import { GoalsListModule } from './pages/goals/goals-list/goals-list.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SkillsListModule } from './pages/skills/skills-list/skills-list.module';
import { UnitsEditorModule } from './pages/units/units-editor/units-editor.module';
import { GoalsEditorModule } from './pages/goals/goals-editor/goals-editor.module';
import { ContentListModule } from './pages/content/content-list/content-list.module';
import { SkillsEditorModule } from './pages/skills/skills-editor/skills-editor.module';
import { SelAgesListModule } from './pages/sel-ages/sel-ages-list/sel-ages-list.module';
import { ContentEditorModule } from './pages/content/content-editor/content-editor.module';
import { SelUnitsListModule } from './pages/sel-units/sel-units-list/sel-units-list.module';
import { MiniGamesListModule } from './pages/mini-games/mini-games-list/mini-games-list.module';
import { SelUnitsEditorModule } from './pages/sel-units/sel-units-editor/sel-units-editor.module';
import { ContentListsModule } from './pages/content-lists/content-lists-list/content-lists.module';
import { SelLessonsListModule } from './pages/sel-lessons/sel-lessons-list/sel-lessons-list.module';
import { MiniGamesEditorModule } from './pages/mini-games/mini-games-editor/mini-games-editor.module';
import { SelLessonsEditorModule } from './pages/sel-lessons/sel-lessons-editor/sel-lessons-editor.module';
import { ContentSeriesListModule } from './pages/content-series/content-series-list/content-series-list.module';
import { ContentListsEditorModule } from './pages/content-lists/content-lists-editor/content-lists-editor.module';
import { ContentSeriesEditorModule } from './pages/content-series/content-series-editor/content-series-editor.module';
import { SelAgesEditorModule } from './pages/sel-ages/sel-ages-editor/sel-ages-editor.module';
import { InteractiveActivitiesListModule } from './pages/interactive-activities/IA-list/interactive-activities-list.module';
import { InteractiveActivitiesEditorModule } from './pages/interactive-activities/IA-editor/interactive-activities-editor.module';
import { SccFeaturedContentModule } from './pages/scc-featured-content/scc-featured-content.module';

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
    ],
    imports: [
        LottieModule.forRoot({ player: playerFactory }), 
        LottieCacheModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),    
        FormsModule,
        LoginModule,
        SharedModule,
        CommonModule,
        DialogModule,
        GoalsListModule,
        UnitsListModule,
        SkillsListModule,
        AppRoutingModule,
        HttpClientModule,
        ContentListModule,
        GoalsEditorModule,
        UnitsEditorModule,
        FontAwesomeModule,
        SelAgesListModule,
        SelUnitsListModule,
        SkillsEditorModule,
        ContentListsModule,
        ContentEditorModule,
        ReactiveFormsModule,
        MiniGamesListModule,
        SelAgesEditorModule,
        SelUnitsEditorModule,
        SelLessonsListModule,
        MiniGamesEditorModule,
        SelLessonsEditorModule,
        ContentSeriesListModule,
        BrowserAnimationsModule,
        ContentListsEditorModule,
        ContentSeriesEditorModule,
        InteractiveActivitiesListModule,
        InteractiveActivitiesEditorModule,
        SccFeaturedContentModule,
    ],
    providers: [
        StorageService,
        HttpService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
