import { Component } from '@angular/core';
import { Router } from '@angular/router';

const { version } = require('../../../../package.json');

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    appVersion = `v${version}`;

    constructor(private router:Router) { }
    
    isHome() {
        const url = this.router.url;
        return url === "/" || url.indexOf('/contents') >= 0;
    }
}
