import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
    selector: 'app-interactive-link-content',
    templateUrl: './interactive-link-content.component.html',
    styleUrls: ['./interactive-link-content.component.scss']
})
export class InteractiveLinkContentComponent implements OnInit {

    constructor(
        private globalService: GlobalService
    ) { }

    @Input() urlLink: string;
    @Input() pdfUrl: string;
    @Input() pdfName: string;

    ngOnInit(): void {
    }

    downloadFile() {
        this.globalService.downloadAwsLink(this.pdfUrl, this.pdfName)
    }

    openLink(link: string) {
        this.globalService.openContent(link);
    }

}
