import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentList } from 'src/app/models/content-list/content-list.model';
import { Content } from 'src/app/models/content/content.model';
import { ContentListService } from 'src/app/services/content-list.service';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { PlanService } from 'src/app/services/plan.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

@Component({
    selector: 'app-content-lists-editor',
    templateUrl: './content-lists-editor.component.html',
    styleUrls: ['./content-lists-editor.component.scss']
})
export class ContentListsEditorComponent {
    contentList:ContentList = {
        title: "",
        active: true,
        contents: [""],
        type: "system",
        contentListId: "",
        nextRotationContents: [""],
        releaseDate: new Date().toISOString(),
        nextRotationReleaseDate: new Date().toISOString(),
        contentsDetails: [
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
        ],
        nextRotationContentsDetails: [
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
        ]
    };
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    emptyMandatoryFields = Array<string>();
    availablePlans:Array<{id:string, label:string}> = [];
    availableCurriculums:Array<{id:string, label:string}> = [];
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private httpService:HttpService,
        private planService:PlanService,
        private dialogService:DialogService,
        private curriculumService:CurriculumService,
        private contentListService:ContentListService,
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;   
            this.loadAvailablePlans(data.contentListId);
            this.loadAvailableCurriculums(data.contentListId);   

            if (data.contentListId !== "new")
                this.load(data.contentListId);
        });
    }

    loadAvailableCurriculums(contentListId:string) {
        this.curriculumService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableCurriculums = response.curriculums.map(c => {
                    return { id: c.curriculumId, label: c.title };
                });

                if (contentListId === "new")
                    this.contentList.curriculums = [];
            });
    }

    loadAvailablePlans(contentId?:string) {
        this.planService.list({ limit: 1000 })
            .subscribe(response => {
                this.availablePlans = response.plans.map(c => {
                    return { id: c.planId, label: c.title };
                });

                if (contentId === "new")
                    this.contentList.plans = [];

                this.loading = false;
            });
    }

    load(contentListId:string) {
        this.contentListService.get(contentListId)
            .subscribe(response => {
                this.loading = false;
                this.contentList = response.contentList;
                if (!this.contentList.tags)
                    this.contentList.tags = [];
                if (!this.contentList.plans)
                    this.contentList.plans = [];
                if (!this.contentList.curriculums)
                    this.contentList.curriculums = [];
                if (!this.contentList.contentsDetails)
                    this.contentList.contentsDetails = [];

                this.contentList.contentsDetails.push({
                    url: "", 
                    type: "",
                    title: "", 
                    contentId: "", 
                    releaseDate: "", 
                });

                if (!this.contentList.nextRotationContentsDetails)
                    this.contentList.nextRotationContentsDetails = [];

                this.contentList.nextRotationContentsDetails.push({
                    url: "", 
                    type: "",
                    title: "", 
                    contentId: "", 
                    releaseDate: "", 
                });
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        var mediaPromises = this.getMediaReadyForUpload();
        Promise.all(mediaPromises).then(() => {
            this.contentListService.save(this.contentList).subscribe(response => {
                this.saving = false;
                this.dialogService.showSuccessAlert("Save Successful.");
                if (response)
                    this.router.navigate(["/saved-lists/" + response.contentListId]);
            }, errors => {
                this.saving = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            });
        });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.contentList.title)
            this.emptyMandatoryFields.push("title");
        if (!this.contentList.description)
            this.emptyMandatoryFields.push("description");
        if (!this.contentList.releaseDate)
            this.emptyMandatoryFields.push("releaseDate");
        if (!this.contentList.thumbnailFile && !this.contentList.thumbnail)
            this.emptyMandatoryFields.push("thumbnail");
        if (!this.contentList.guideFile && !this.contentList.guide)
            this.emptyMandatoryFields.push("guide");
        if (!this.contentList.curriculums || this.contentList.curriculums.length <= 0)
            this.emptyMandatoryFields.push("curriculums");
        if (!this.contentList.plans || this.contentList.plans.length <= 0)
            this.emptyMandatoryFields.push("plans");
        
        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload():Promise<string>[] {
        var promises:Promise<string>[] = [];
        if (this.contentList.thumbnailFile) {
            promises.push(
                this.createPromiseForMedia(this.contentList.thumbnailFile, "image")
                    .then(url => {
                        this.contentList.thumbnail = url;
                        this.contentList.thumbnailFile = null;
                        return url;
                    })
            );
        }

        if (this.contentList.guideFile) {
            promises.push(
                this.createPromiseForMedia(this.contentList.guideFile, "image")
                    .then(url => {
                        this.contentList.guide = url;
                        this.contentList.guideFile = null;
                        return url;
                    })
            );
        }

        if (this.contentList.nextRotationGuideFile) {
            promises.push(
                this.createPromiseForMedia(this.contentList.nextRotationGuideFile, "image")
                    .then(url => {
                        this.contentList.nextRotationGuide = url;
                        this.contentList.nextRotationGuideFile = null;
                        return url;
                    })
            );
        }

        return promises;
    }
    
    createPromiseForMedia(file:File, type:"file"|"image"):Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url), 
                    error => reject(error)
                );
        });
    }

    delete() {
        this.deleting = true;
        this.contentListService
            .delete(this.contentList.contentListId)
            .subscribe(() => {
                this.deleting = false;
                this.dialogService.showSuccessAlert("Delete Successful.");
                this.router.navigate(["/saved-lists"]);
            });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.contentList.title + " list from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onContentDetailsChange($event:Content, i:number) {
        if (!$event) {
            this.contentList.contents.splice(i, 1);
            this.contentList.contentsDetails.splice(i, 1);
            return;
        }
        
        this.contentList.contents[i] = $event.contentId;
        this.contentList.contentsDetails[i].url = $event.url;
        this.contentList.contentsDetails[i].type = $event.type;
        this.contentList.contentsDetails[i].title = $event.title;
        this.contentList.contentsDetails[i].contentId = $event.contentId;

        this.contentList.contentsDetails.push({
            url: "", 
            type: "",
            title: "", 
            contentId: "", 
            releaseDate: "", 
        });
    }

    onNextRotationContentDetailsChange($event:Content, i:number) {
        if (!$event) {
            this.contentList.nextRotationContents.splice(i, 1);
            this.contentList.nextRotationContentsDetails.splice(i, 1);
            return;
        }
        
        this.contentList.nextRotationContents[i] = $event.contentId;
        this.contentList.nextRotationContentsDetails[i].url = $event.url;
        this.contentList.nextRotationContentsDetails[i].type = $event.type;
        this.contentList.nextRotationContentsDetails[i].title = $event.title;
        this.contentList.nextRotationContentsDetails[i].contentId = $event.contentId;

        this.contentList.nextRotationContentsDetails.push({
            url: "", 
            type: "",
            title: "", 
            contentId: "", 
            releaseDate: "", 
        });
    }

    goBack() {
        this.router.navigate(["/saved-lists"]);
    }
}
