<img *ngIf="iconUrl" src="{{iconUrl}}" />
<div class="label-container">
    <div class="tooltip-section">
        <label *ngIf="label">{{label}} <b *ngIf="isRequired">*</b></label>
        <app-tooltip *ngIf="tooltip" [config]="tooltip"></app-tooltip>
    </div>
    <span *ngIf="maxChars" [ngClass]="{'font-red': isMaxCharsReached}">Characters: {{ inputTextLength }}/{{ maxChars }}</span>
</div>
<textarea 
    rows="5"
    [value]="value"
    [disabled]="disabled" 
    (input)="onChange($event)"
    [placeholder]="placeholder" 
    [ngClass]="[
        feedback || '',
        size,
        error ? 'error' : '',
        isMaxCharsReached ? 'error' : ''
    ]"
    (keyup)="maxChars && validateMaxChars($event.target.value.length)"
    (blur)="isRequired && validateRequired($event.target.value.length)"
></textarea>
<div class="error-message" *ngIf="error">{{error}}</div>
