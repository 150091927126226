import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WtdSectionComponent } from './wtd-section.component';
import { WtdPickerModule } from './wtd-picker/wtd-picker.module';

@NgModule({
    declarations: [
        WtdSectionComponent,
    ],
    imports: [
        CommonModule,
        WtdPickerModule
    ],
    exports: [
        WtdSectionComponent,
    ]
})
export class WtdSectionModule { }