import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { TagfieldModule } from 'src/app/shared/form/tagfield/tagfield.module';
import { TextareaModule } from 'src/app/shared/form/textarea/textarea.module';
import { ContentListsEditorComponent } from './content-lists-editor.component';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { MultiSelectModule } from 'src/app/shared/form/multi-select/multi-select.module';
import { RichtextareaModule } from 'src/app/shared/form/richtextarea/richtextarea.module';
import { ContentPickerModule } from 'src/app/shared/form/content-picker/content-picker.module';
import { CopyToClipboardModule } from 'src/app/shared/copy-to-clipboard/copy-to-clipboard.module';

@NgModule({
    declarations: [
        ContentListsEditorComponent
    ],
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        RouterModule,
        SelectModule,
        ButtonModule,
        TextareaModule,
        TagfieldModule,
        TextfieldModule,
        MultiSelectModule,
        RichtextareaModule,
        ContentPickerModule,
        CopyToClipboardModule,
    ],
})
export class ContentListsEditorModule { }