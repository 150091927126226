import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { ListParams } from "../models/list-params/list-params.model";
import { ContentTypeList } from "../models/content-type/content-type-list.model";

@Injectable({
    providedIn: 'root'
})
export class ContentTypeService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<ContentTypeList> {
        return this.http.get('content-types', params);
    }

    get(contentSlug:string):Observable<any> {
        return this.http.get('content-types?slug=' + contentSlug);
    }
}
