<div class="content">
    <label *ngIf="label">{{label}}</label>
    <div [class]="feedback" class="input-container">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" [attr.fill]="focusing ? '#2980B9' : '#8F9BB3'">
            <path d="M9.69824 13.9999L10.4272 9.99994H14.3022L13.5732 13.9999H9.69824ZM20.0002 13.9999H15.6982L16.4272 9.99994H20.0002C20.5502 9.99994 21.0002 9.54994 21.0002 8.99994C21.0002 8.44994 20.5502 7.99994 20.0002 7.99994H16.7902L17.4842 4.18894C17.5842 3.64094 17.2022 3.11194 16.6352 3.01594C16.0682 2.91894 15.5232 3.28894 15.4232 3.83794L14.6652 7.99994H10.7902L11.4842 4.18894C11.5842 3.64094 11.2022 3.11194 10.6352 3.01594C10.0682 2.91894 9.52324 3.28894 9.42324 3.83794L8.66524 7.99994H4.00024C3.45024 7.99994 3.00024 8.44994 3.00024 8.99994C3.00024 9.54994 3.45024 9.99994 4.00024 9.99994H8.30224L7.57324 13.9999H4.00024C3.45024 13.9999 3.00024 14.4499 3.00024 14.9999C3.00024 15.5499 3.45024 15.9999 4.00024 15.9999H7.20924L6.51524 19.8109C6.41624 20.3589 6.79824 20.8879 7.36524 20.9839C7.93124 21.0809 8.47724 20.7109 8.57624 20.1619L9.33424 15.9999H13.2092L12.5152 19.8109C12.4162 20.3589 12.7982 20.8879 13.3652 20.9839C13.9312 21.0809 14.4772 20.7109 14.5762 20.1619L15.3342 15.9999H20.0002C20.5502 15.9999 21.0002 15.5499 21.0002 14.9999C21.0002 14.4499 20.5502 13.9999 20.0002 13.9999Z" />
        </svg>            
        <input
            [ngClass]="size"
            [class.error]="error"
            [disabled]="disabled" 
            (keyup.enter)="addTag()"
            (focus)="focusing = true"
            (blur)="focusing = false"
            [(ngModel)]="currentValue"
            [placeholder]="placeholder"
        />
        <button (click)="addTag()" [disabled]="!currentValue">
            <img src="../../../../assets/images/icons/add.svg" alt="add tag" />
        </button>
    </div>
    <div class="tag-list">
        <div class="tag" *ngFor="let tag of values;let i = index;">
            <img src="../../../../assets/images/icons/hash.svg" />
            <span>{{tag}}</span>
            <button (click)="deleteTag(i)">
                <img src="../../../../assets/images/icons/close.svg" alt="delete tag" />
            </button>
        </div>
    </div>
</div>
<div class="error-message" *ngIf="error">{{error}}</div>