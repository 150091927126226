<loading *ngIf="!anim && !silentLoad"></loading>
<div *ngIf="showControls && anim" class="controls">
    <button *ngIf="anim.isPaused" (click)="play()">
        <img src="../../../assets/images/icons/play.svg" />
    </button>
    <button *ngIf="!anim.isPaused" (click)="pause()">
        <img src="../../../assets/images/icons/pause.svg" />
    </button>
</div>
<ng-lottie 
    [options]="animOptions"
    [width]="width ? width + 'px' : 'auto'"
    [height]="height ? height + 'px' : 'auto'"
    (complete)="hideAfterComplete && anim?.hide()"
    (animationCreated)="anim = $event; onLoad.emit()"
></ng-lottie>
