import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteractiveLinkContentComponent } from './interactive-link-content.component';
import { ButtonModule } from '../form/button/button.module';
import { CopyToClipboardModule } from '../copy-to-clipboard/copy-to-clipboard.module';
import { TextfieldModule } from '../form/textfield/textfield.module';



@NgModule({
    declarations: [
        InteractiveLinkContentComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        CopyToClipboardModule,
        TextfieldModule
    ],
    exports: [
        InteractiveLinkContentComponent
    ]
})

export class InteractiveLinkContentModule { }
