<div class="container" data-cy="login-container">
    <img src="../../../assets/images/logos/main-logo.svg" alt="logo"/>
    <form (submit)="tryLogin($event)">
        <app-textfield 
            size="small" 
            type="text" 
            placeholder="Email" 
            [(value)]="user.email"
            [error]="emptyMandatoryFields.includes('email') ? 'This field is required.' : ''"
            data-cy="email-input"
        ></app-textfield>
        <app-textfield 
            size="small" 
            type="password" 
            placeholder="Password" 
            [(value)]="user.password"
            [error]="emptyMandatoryFields.includes('password') ? 'This field is required.' : ''"
            data-cy="password-input"
        ></app-textfield>
        <app-button type="primary" [loading]="loading" data-cy="login-btn">Login</app-button>
    </form>
</div>
<footer>
    {{currYear}} &copy; EverydaySpeech 
</footer>