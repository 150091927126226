import { Component } from '@angular/core';
import { Unit } from 'src/app/models/unit/unit.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { environment } from 'src/environments/environment';
import { UnitService } from 'src/app/services/unit.service';
import { GoalService } from 'src/app/services/goal.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { Content } from 'src/app/models/content/content.model';

@Component({
    selector: 'app-units-editor',
    templateUrl: './units-editor.component.html',
    styleUrls: ['./units-editor.component.scss']
})
export class UnitsEditorComponent {
    unit:Unit = {
        order: 0,
        title: "",
        unitId: "",
        contents: ["", "", "", ""],
        contentDetails: [
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
            { contentId: "", url: "", type: "", title: "", releaseDate: "" },
        ]
    };
    contentsTitles = [
        "Lesson 1 Introduce",
        "Lesson 2 Practice",
        "Lesson 3 Apply",
        "Lesson 4 Review"
    ];
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    emptyMandatoryFields = Array<string>();
    availableAges:Array<{id:string, label:string}> = [];
    availableGoals:Array<{id:string, label:string}> = [];
    availableOrders:Array<{id:string, label:string}> = [];
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private ageService:AgeTrackService,
        private goalService:GoalService,
        private unitService:UnitService,
        private dialogService:DialogService,
        private curriculumService:CurriculumService,
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;     
            this.loadAvailableGoals(data.unitId);
        });
    }

    loadAvailableGoals(unitId:string) {
        this.goalService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableGoals = response.goals.map(g => {
                    return { id: g.goalId, label: g.title };
                });

                if (unitId === "new" && this.availableGoals.length > 0) {
                    this.unit.goalId = this.availableGoals[0].id;
                    this.loadScCurriculumData(unitId);
                } else {
                    this.load(unitId);
                }
            });
    }

    loadAvailableAges(unitId:string, curriculumId:string) {
        this.ageService.list({ limit: 1000, curriculum: curriculumId })
            .subscribe(response => {
                this.availableAges = response.ages.map(a => {
                    return { id: a.ageId, label: a.title };
                });

                if (unitId === "new" && this.availableAges.length > 0)
                    this.unit.ageId = this.availableAges[0].id;

                this.loadAvailableOrders(this.unit.goalId, this.unit.ageId, this.unit.unitId);
            });
    }

    loadAvailableOrders(parentGoalId:string, ageId:string, unitId?:string) {
        this.availableOrders = [];

        this.unitService.listSc({ goal: parentGoalId, age: ageId })
            .subscribe(response => {
                for (var i = 0; i < response.totalResults; i++) {
                    var order = (i + 1).toString();
                    this.availableOrders.push({
                        id: order, 
                        label: order
                    });
                }

                if (
                    unitId === "new" || !unitId || 
                    !this.unit.order || response.totalResults <= 0
                ) {
                    var newOrder = (this.availableOrders.length + 1).toString();
                    this.availableOrders.push({
                        id: newOrder, 
                        label: newOrder
                    });
                    this.unit.order = this.availableOrders.length;
                }

                this.loading = false;
            });
    }

    loadScCurriculumData(unitId:string) {
        this.curriculumService.get(environment.scCurriculumSlug)
            .subscribe(response => {
                this.unit.curriculumId = response.curriculum.curriculumId;
                this.loadAvailableAges(unitId, this.unit.curriculumId); 
            });
    }

    load(unitId:string) {
        this.unitService.get(unitId)
            .subscribe(response => {
                this.loading = false;
                this.unit = response.unit;

                if (!this.unit.contentDetails)
                    this.unit.contentDetails = [];

                if (this.unit.contentDetails.length > 4)
                    this.unit.contentDetails = this.unit.contentDetails.slice(0, 4);

                if (this.unit.contentDetails.length < 4) {
                    var contentsCount = this.unit.contentDetails.length;
                    for (var i = 0; i < 4 - contentsCount; i++) {
                        this.unit.contentDetails.push({ 
                            url: "", 
                            type: "",
                            title: "", 
                            contentId: "", 
                            releaseDate: "", 
                        });
                    }
                }
                this.loadAvailableAges(unitId, this.unit.curriculumId); 
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        this.unitService.save(this.unit).subscribe(response => {
            this.saving = false;
            this.dialogService.showSuccessAlert("Save Successful.");
            if (response)
                this.router.navigate(["/units/" + response.unitId]);
        }, errors => {
            this.saving = false;
            this.dialogService.showErrorAlert(formatError(errors?.error));
        });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.unit.title)
            this.emptyMandatoryFields.push("title");
        if (!this.unit.goalId)
            this.emptyMandatoryFields.push("goalId");
        for (var i = 0; i < this.unit.contents.length; i++) {
            !this.unit.contents[i] && 
            this.emptyMandatoryFields.push("content" + i);
        }
        
        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    delete() {
        this.deleting = true;
        this.unitService.delete(this.unit.unitId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/units"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.unit.title + " unit from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onGoalChange($event:string) {
        this.unit.order = 0;
        this.loadAvailableOrders($event, this.unit.ageId, this.unit.unitId);
    }

    onAgeChange($event:string) {
        this.unit.order = 0;
        this.loadAvailableOrders(this.unit.goalId, $event, this.unit.unitId);
    }

    onContentDetailsChange($event:Content, i:number) {
        if (!$event) {
            this.unit.contents[i] = "";
            this.unit.contentDetails[i].contentId = "";
            return;
        }
        
        this.unit.contents[i] = $event.contentId;
        this.unit.contentDetails[i].url = $event.url;
        this.unit.contentDetails[i].type = $event.type;
        this.unit.contentDetails[i].title = $event.title;
        this.unit.contentDetails[i].contentId = $event.contentId;
    }

    goBack() {
        this.router.navigate(["/units"]);
    }
}
