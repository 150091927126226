import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ListParams } from '../models/list-params/list-params.model';

@Injectable({
  providedIn: 'root',
})
export class InteractiveActivityTypesService {
  constructor(private http: HttpService) {}

  list(params?: ListParams): Observable<any> {
    return this.http.get('admin/interactive-activity-types', params);
  }

  get(activitySlug: string): Observable<any> {
    return this.http.get(
      'admin/interactive-activity-types/' + activitySlug
    );
  }
}
