<div class="fill-blanks-container">
    <h2 class="title">Questions</h2>
    <ng-container *ngFor="let question of activityFillBlanks.items; let i = index;">
        <div class="question-section">
            <span class="index-number">{{ i + 1 }}</span>
            <div class="label-container">
                <div class="label-section">
                    <h3>Before Keyword <b *ngIf="i < 2">*</b></h3>
                    <app-textfield [(value)]="question.beforeKeyword"
                        placeholder="e.g.: When my teacher is talking, I look with my"
                        [error]="i < 2 && emptyMandatoryFields.includes('beforeKeyword' + i) ? 'This field is required' : ''"
                        data-cy="before-keyword-input">
                    </app-textfield>
                </div>
                <div class="label-section">
                    <h3>keyword <b *ngIf="i < 2">*</b></h3>
                    <app-textfield [(value)]="question.keyword" placeholder="eyes"
                        [error]="i < 2 && emptyMandatoryFields.includes('keyword' + i)? 'This field is required' : ''"
                        data-cy="keyword-input">
                    </app-textfield>
                </div>
                <div class="label-section">
                    <h3>After Keyword <b *ngIf="i < 2">*</b></h3>
                    <app-textfield [(value)]="question.afterKeyword" placeholder="."
                        [error]="i < 2 && emptyMandatoryFields.includes('afterKeyword' + i) ? 'This field is required' : ''"
                        data-cy="after-keyword-input">
                    </app-textfield>
                </div>
                <hr>
            </div>
        </div>
    </ng-container>
    <div class="auto-check-section">
        <h3>Auto-check Feature</h3>
        <app-switch id="active" activeLabel="Active" [(value)]="activityFillBlanks.isAutoCheck"></app-switch>
        <p>By default, answers will be marked as correct and incorrect after user completes activity. If there are more
            than one possible solutions, please mark the inactivate the auto-check feature.</p>
    </div>
</div>