import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnChanges {
    @Input() label:string;
    @Input() error:string;
    @Input() selectLabel = "Select";
    @Input() selectedOptions:Array<string>;
    @Input() size:"small"|"medium"|"large" = "medium";
    @Input() options:Array<{ id:string, label:string }>;
    
    @Output() selectedOptionsChange = new EventEmitter<Array<string>>();
    
    @ViewChild('optionsContainer') optionsContainer;
    
    opened:boolean;
    currentSelectLabel:string;

    constructor() { }

    ngOnInit(): void {
        this.setSelectLabel();
    }

    ngOnChanges(changes:SimpleChanges) {
        if (changes.selectedOptions?.currentValue ||
            changes.options?.currentValue)
            this.setSelectLabel();
    }
    
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement):void {
        if (
            this.opened && 
            !this.optionsContainer.nativeElement.contains(targetElement)
        ) {
            this.opened = false;
        }
    }

    onChange(id:string) {
        if (!this.selectedOptions)
            this.selectedOptions = [];

        var index = this.selectedOptions.indexOf(id);
        if (index > -1)
            this.selectedOptions.splice(index, 1);
        else 
            this.selectedOptions.push(id);
        
        this.setSelectLabel();
        this.selectedOptionsChange.emit(this.selectedOptions);
    }

    optionIsChecked(id:string) {
        if (!this.selectedOptions)
            return false;

        return this.selectedOptions.includes(id);
    }

    setSelectLabel() {
        if (!this.selectedOptions || this.selectedOptions.length <= 0) {
            this.currentSelectLabel = this.selectLabel;
        } else if (this.selectedOptions.length === this.options.length) {
            this.currentSelectLabel = "All";
        } else {
            this.currentSelectLabel = this.options
                .filter(o => this.selectedOptions.includes(o.id))
                .map(so => so.label)
                .join(", ");
        }
    }
}
