import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivityDiceRoller, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
  selector: 'app-activity-dice-roller',
  templateUrl: './activity-dice-roller.component.html',
  styleUrls: ['./activity-dice-roller.component.scss'],
})
export class ActivityDiceRollerComponent implements OnInit {
  @Input() activityDiceRoller: InteractiveActivityDiceRoller;
  @Input() emptyMandatoryFields = Array<string>();
  
  @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

  constructor() {}

  ngOnInit(): void {
    if (Object.keys(this.activityDiceRoller).length === 0) {
      this.activityDiceRoller.items = [];
      this.activityDiceRoller.items = Array.from({ length: 6 }, () => ({
        prompt: '',
        image: '',
      }));
    } else {
        if (this.activityDiceRoller.items.length < 6) {
            let activityLength = this.activityDiceRoller.items.length;
            let necessaryItems = Array.from({ length: (6 - activityLength) }, () => ({
                prompt: '',
                image: '',
            }));
            this.activityDiceRoller.items = [...this.activityDiceRoller.items, ...necessaryItems];
        }
    }
  }

  maxCharsValidation(isMaxReached: boolean, field: string) {
    this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field });
  }
}
