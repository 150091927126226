export const environment = {
    name: "staging",
    production: "false",
    apiUrl: "https://staging-content-service.everydayspeech.com/",
    appId: "QPqBtJFqwkoFFr7tk2Fk2F0BFap0Bq37tQdT7tLQ9g",
    cloudinaryUrl: "https://api.cloudinary.com/v1_1/everyday-speech/upload",
    selCurriculumSlug: "social-emotional-learning",
    scCurriculumSlug: "social-communication-skills",
    ddApplicationId: "6058a9ce-550a-4880-931a-9f875e5276f5",
    ddToken: "pub69c1757964326395193787f77baab7ff",
    ddServiceName: "slp-cms-staging",
    ddEnv: "staging"
};