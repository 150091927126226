import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @ViewChild('userOptions') userOptions;
    
    user:User;
    opened:boolean;

    constructor(
        private router:Router,
        private userService:UserService,
        private authService:AuthService
    ) { }

    ngOnInit():void {
        this.user = this.userService.getLocalUser();
    }
    
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement):void {
        if (
            this.opened && 
            !this.userOptions.nativeElement.contains(targetElement)
        ) {
            this.opened = false;
        }
    }

    toggleOptions():void {
        this.opened = !this.opened;
    }

    logout():void {
        this.authService.clearSession();
        this.router.navigate(['login']);
    }
}
