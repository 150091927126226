<div class="thumb-preview">
    <pdf-viewer 
        *ngIf="content.url"
        [src]="content.url"
        [render-text]="true"
        [fit-to-page]="true"
        [zoom-scale]="'page-fit'"
    ></pdf-viewer>
    <img
        *ngIf="!content.url"
        src="../../../../assets/images/icons/video-image-placeholder.svg" 
    />
</div>
<app-file-picker
    id="pdf"
    type="pdf"
    label="Main content"
    [value]="content.url"
    (fileChange)="content.file = $event; !$event && content.url = ''"
    [error]="emptyMandatoryFields.includes('file') ? 'This field is required.' : ''"
></app-file-picker>
<app-textfield
    label="Title"
    [(value)]="content.title"
    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
    data-cy="title-input"
></app-textfield>
<app-richtextarea
    label="Description"
    data-cy="description-input"
    placeholder="Place your text"
    [(value)]="content.description"
></app-richtextarea>
<app-activity-picker
    label="Interactive Activity"
    [(link)]="content.interactiveActivityUrl"
    [(interactiveActivityId)]="content.interactiveActivityId"
></app-activity-picker>