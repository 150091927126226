import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { ButtonModule } from '../button/button.module';
import { SelectModule } from '../select/select.module';
import { TextfieldModule } from '../textfield/textfield.module';
import { ListViewModule } from '../../list/list-view/list-view.module';
import { MiniGamePickerComponent } from './mini-game-picker.component';
import { ListToolbarModule } from '../../list/list-toolbar/list-toolbar.module';
import { ListPaginatorModule } from '../../list/list-paginator/list-paginator.module';

@NgModule({
    declarations: [
        MiniGamePickerComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        SelectModule,
        ListViewModule,
        TextfieldModule,
        ListToolbarModule,
        ListPaginatorModule,
    ],
    exports: [
        MiniGamePickerComponent
    ]
})
export class MiniGamePickerModule { }