import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { Content } from "../models/content/content.model";
import { ContentList } from "../models/content/content-list.model";
import { ListParams } from "../models/list-params/list-params.model";

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    constructor(private http: HttpService) { }

    list(params?: ListParams): Observable<ContentList> {
        return this.http.get('admin/contents', params);
    }

    get(contentId: string): Observable<any> {
        return this.http.get('admin/contents/' + contentId);
    }

    save(content: Content): Observable<any> {
        if (content.contentId)
            return this.http.put('admin/contents/' + content.contentId, content);

        return this.http.post('admin/contents', content);
    }

    delete(contentId: string): Observable<any> {
        return this.http.delete('admin/contents/' + contentId);
    }
}
