import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Content } from 'src/app/models/content/content.model';

@Component({
    selector: 'content-activity',
    templateUrl: './content-activity.component.html',
    styleUrls: ['./content-activity.component.scss']
})
export class ContentActivityComponent {
    @Input() content: Content;
    @Input() emptyMandatoryFields = Array<string>();
    
    @Output() contentChange = new EventEmitter<Content>();

    constructor() { }
}
