<app-header>
    <a routerLink="/skills">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!skill.skillId ? "New" : ""}} Social Communication Skill</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            (click)="save()"
            [loading]="saving"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <app-select
            label="Order"
            [(value)]="skill.order"
            [options]="availableOrders"
        ></app-select>
        <app-textfield
            label="Skill name"
            [(value)]="skill.title"
            [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
        ></app-textfield>
        <app-textarea 
            [(value)]="skill.subtitle"
            placeholder="Place your text"
            label="Skill subtitle (optional)" 
        ></app-textarea>
        <app-color-picker
            placeholder="#000000"
            label="Primary color"
            [(value)]="skill.primaryColor"
            [error]="emptyMandatoryFields.includes('primaryColor') ? 'This field is required.' : ''"
        ></app-color-picker>
        <app-color-picker
            placeholder="#000000"
            label="Secondary color"
            [(value)]="skill.secondaryColor"
            [error]="emptyMandatoryFields.includes('secondaryColor') ? 'This field is required.' : ''"
        ></app-color-picker>
        <app-tagfield
            label="Tags"
            [(values)]="skill.tags"
        ></app-tagfield>
        <div class="dangerous-area" *ngIf="skill.skillId">
            <app-button
                type="danger"
                [loading]="deleting"
                (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
            <div>
                Warning: Deleting a skill will REMOVE related goals from the Full Curriculum page
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
