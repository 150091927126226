import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivitySpinner, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
  selector: 'app-activity-spinner',
  templateUrl: './activity-spinner.component.html',
  styleUrls: ['./activity-spinner.component.scss'],
})
export class ActivitySpinnerComponent implements OnInit {
  @Input() activitySpinner: InteractiveActivitySpinner;
  @Input() emptyMandatoryFields = Array<string>();

  @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

  constructor() {}

  ngOnInit(): void {
    if (Object.keys(this.activitySpinner).length === 0) {
      this.activitySpinner.items = [];
      this.activitySpinner.items = Array.from({ length: 3 }, () => ({
        scenario: '',
      }));
      this.activitySpinner.reviewPrompt = '';
      this.activitySpinner.instructions = '';
    } else {
        if (this.activitySpinner.items.length < 3) {
            let activityLength = this.activitySpinner.items.length;
            let necessaryItems = Array.from({ length: (3 - activityLength) }, () => ({
                scenario: '',
            }));
            this.activitySpinner.items = [...this.activitySpinner.items, ...necessaryItems];
        }
    }
  }

  maxCharsValidation(isMaxReached: boolean, field: string) {
    this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field});
  }
}
