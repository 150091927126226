import { Content } from 'src/app/models/content/content.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-wtd-content-picker',
    templateUrl: './wtd-content-picker.component.html',
    styleUrls: ['./wtd-content-picker.component.scss']
})
export class WtdContentPickerComponent {
    @Input() type: string;
    @Input() label: string;
    @Input() error: string;
    @Input() content: Content;
    @Input() title: string = "Child Content";
    @Input() layout: "column" | "row" = "column";

    @Output() contentChange = new EventEmitter<{ content: Content, type: any }>();

    availableContentTypes = [
        { id: "mc", label: "Main Content" },
        //{ id: "ia", label: "Interactive Activities" },
        { id: "mg", label: "Mini Games" },
    ];
    headers = [
        { label: "Title", key: "title", sortable: true },
        { label: "Type", key: "contentType", sortable: true },
        { label: "Select" },
    ];
    pickerOpened: boolean;
    selectedContentType = "mc";

    constructor() { }

    onContentChange(newContent, type) {
        this.content = newContent;
        if (type === "minigame")
            this.content.type = "Mini Game";
        else if (type === "interactivyActivity")
            this.content.type = "Interactive Activity";

        this.pickerOpened = false;
        this.contentChange.emit({ content: this.content, type });
    }
}
