import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortByNameAscPipe } from './sort-by-name-asc';


@NgModule({
  declarations: [
    SortByNameAscPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SortByNameAscPipe
  ]
})
export class PipesModule { }
