import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { InteractiveActivityMultipleChoice, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';
import { ActivityType, InteractiveActivity, InteractiveActivityResponse, InteractiveTypes } from 'src/app/models/interactive-activity/interactive-activity.model';
import { TooltipIA } from 'src/app/models/tooltip/tooltip.model';
import { DialogService } from 'src/app/services/dialog.service';
import { HttpService } from 'src/app/services/http.service';
import { InteractiveActivityTypesService } from 'src/app/services/interactive-activity-types.service';
import { InteractiveActivityService } from 'src/app/services/interactive-activity.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

export enum activities {
    "fill-in-the-blanks" = "activityFillBlanks",
    "qa" = "activityQa",
    "matching" = "activityMatching",
    "spinner" = "activitySpinner",
    "sorting" = "activitySorting",
    "flashcards" = "activityFlashcards",
    "dice-roller" = "activityDiceRoller",
    "multiple-choice" = "activityMultipleChoice"
}

@Component({
    selector: 'app-interactive-activities-editor',
    templateUrl: './interactive-activities-editor.component.html',
    styleUrls: ['./interactive-activities-editor.component.scss']
})
export class InteractiveActivitiesEditorComponent implements OnInit {

    activity: InteractiveActivity = {
        title: "",
        description: "",
        instructions: "",
        linkedContent: "",
        configuration: {},
    };
    loading: boolean;
    saving: boolean;
    activityTypeSlug: string;
    activityId: string;
    interactiveTypes: typeof InteractiveTypes = InteractiveTypes;
    tooltipIA = TooltipIA;
    errors = [];
    maxCharError = [];
    emptyMandatoryFields = Array<string>();
    typeId: string;
    worksheetCharacteristics: string;
    contentUrl: string;
    instructionsData = {
        'flashcards': {
            title: 'Activity Instructions (Static)',
            description: 'PDF:</br> <i>"1) Cut out the flashcards.</br>2) Glue or tape the cards together by folding them in half on the center fold-line."</i></br></br>'
            + 'Online interactive version: instructions are displayed throughout the experience.'
        },
        'dice-roller': {
            title: 'PDF DIE assembly instructions (static)',
            description: 'The PDF version has static instructions for the die assembly which are displayed next to the die pattern. They are not displayed in the online version.</br></br>'
            + '<i>“1) Cut out the dice.</br> 2) Assemble the paper dice by folding along the dotted lines and taping or gluing the sides containing the x icon.”</i>'
        },
        'multiple-choice': {
            title: 'Activity instructions (Static)',
            description: '“Select the best answer(s) for each question or statement.”'
        }
    }

    constructor(
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private router: Router,
        private iaService: InteractiveActivityService,
        private iaTypesService: InteractiveActivityTypesService,
        private httpService: HttpService
    ) {
        this.route.params.subscribe(data => {
            this.loading = true;
            this.activityTypeSlug = data.activityType;
            this.activityId = data?.activityId;
            this.loading = false;
        });
    }

    ngOnInit(): void {
        if (this.activityId !== 'new')
            this.getInteractiveActivity(this.activityId);
        else
        this.activity.configuration[activities[this.activityTypeSlug]] = {};
        
        this.getActivityType(this.activityTypeSlug);
    }

    getActivityType(slug: string) {
        this.iaTypesService.get(slug).subscribe(
            (iaType: ActivityType) => {
                this.typeId = iaType.interactiveActivityType.interactiveActivityTypeId;
                this.worksheetCharacteristics = iaType.interactiveActivityType.worksheetCreationCharacteristics;
            }
        )
    };

    getInteractiveActivity(activityId: string) {
        this.loading = true;
        this.iaService.getById(activityId).subscribe(
            (response: InteractiveActivityResponse) => {
                this.activity.title = response.interactiveActivity.title;
                this.activity.description = response.interactiveActivity.description;
                this.activity.instructions = response.interactiveActivity.configuration?.instructions;
                this.activity.interactiveActivityId = response.interactiveActivity.interactiveActivityId;
                this.activity.configuration[activities[this.activityTypeSlug]] = response.interactiveActivity.configuration;
                this.activity.contentTitle = response.interactiveActivity.contentTitle;
                this.activity.contentUrl = response.interactiveActivity.contentUrl;
                this.activity.contentTypeSlug = response.interactiveActivity.contentTypeSlug;
                this.activity.contentId = response.interactiveActivity.contentId;
                this.activity.interactiveWorksheetsUrl = response.interactiveActivity.interactiveWorksheetsUrl;
                this.activity.pdfUrl = response.interactiveActivity.pdfUrl;
                this.loading = false;
            },
            error => {
                this.dialogService.showErrorAlert(formatError(error));
                this.loading = false;
            }
        )
    }

    trySave() {
        const activityName = activities[this.activityTypeSlug];
        const activity = Object.assign(this.activity.configuration[activityName]);
        const isValidCommonFields = this.isValidEditorFields();
        const activityFields = this.getRequiredFields(this.activityTypeSlug);
        const isValidActivity = activityFields !== null 
            ? this.isValidActivity(
                    activity,
                    activityFields.fieldsOutsideArray,
                    activityFields.fieldsInsideArray,
                    activityFields.validationField,
                    activityFields.requiredFields
                )
            : this.dialogService.showInformativeModal({ typeModal: 'errorDialog', props: { message: "Error on activity data structure. Please contact the support." } });
        
        this.saving = true;
        if (!isValidActivity || !isValidCommonFields) {
            this.dialogService.showInformativeModal({ typeModal: 'errorDialog', props: { message: "Please fill the required fields." } });
            this.saving = false;
        } else {
            if (this.activityTypeSlug === 'multiple-choice') {
                if (this.hasAnswersNotFilled(activity)) {
                    this.saving = false;
                    return;
                }
            }
            if (this.maxCharError.length > 0) {
                this.dialogService.showConfirmationDialog({
                    type: "danger",
                    confirmButtonLabel: "Proceed to Save",
                    onConfirmCallback: () => this.proceedSaveIa(activity),
                    title: "Please Review",
                    message: "Some text fields have exceeded the prescribed character count limits. This can cause display issues in the PDF and interactive worksheet.",
                    cancelLabel: "Continue Editing"
                });
                this.saving = false;
                return;
            }
            this.proceedSaveIa(activity);
        }
    }

    proceedSaveIa(activity) {
        this.saving = true;
        if (this.activityTypeSlug)
        var mediaPromises = this.getMediaReadyForUpload(this.activityTypeSlug);

        Promise.all(mediaPromises).then(
            () => {
                this.saveNewIa(activity);
                },
            errors => {
                this.saving = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            }
        )
    }

    saveNewIa(activity: object) {
        this.activity.configuration[activities[this.activityTypeSlug]].instructions = this.activity.instructions;
            const object = {
                title: this.activity.title,
                description: this.activity.description,
                interactiveActivityTypeId: this.typeId,
                configuration: JSON.stringify(activity),
                ...(this.activity.interactiveActivityId && { interactiveActivityId: this.activity.interactiveActivityId })
            };
            this.iaService.save(object).subscribe(
                response => {
                    this.saving = false;
                    this.dialogService.showInformativeModal({ typeModal: 'successDialog', props: { message: "Your new interactive activity has been created." } });
                        if(response) {
                            this.activity.interactiveActivityId = response.interactiveActivityId
                            this.activity.interactiveWorksheetsUrl = response?.interactiveWorksheetsUrl
                            this.router.navigate([
                                `/interactive-activities/${this.activityTypeSlug}/${response.interactiveActivityId}`
                            ]);
                        }
                        else
                            this.getInteractiveActivity(this.activity.interactiveActivityId)
                },
                error => {
                    this.saving = false;
                    this.dialogService.showInformativeModal({ typeModal: 'errorDialog', props: { message: "Error on save new IA, please contact the support." } });
                    throwError(error);
                }
            )
    }

    goBack() {
        this.router.navigate(["/interactive-activities"]);
    }

    getMediaReadyForUpload(type: string): Promise<string>[] {
        let promises: Promise<string>[] = [];

        if (type === 'flashcards') {
            this.activity.configuration.activityFlashcards.items.forEach((item) => {
                if (item.frontImageFile) {
                    promises.push(this.createPromiseForMedia(item.frontImageFile, "image").then(url => {
                        item.frontImage = url;
                        item.frontImageFile = null;
                        return url;
                    }));
                }
                
                if (item.backImageFile) {
                    promises.push(this.createPromiseForMedia(item.backImageFile, "image").then(url => {
                        item.backImage = url;
                        item.backImageFile = null;
                        return url;
                    }));
                }
            })
        } else if (type === 'dice-roller') {
            this.activity.configuration.activityDiceRoller.items.forEach((item) => {
                if (item.iconImageFile) {
                    promises.push(this.createPromiseForMedia(item.iconImageFile, "image").then(url => {
                        item.image = url;
                        item.iconImageFile = null;
                        return url;
                    }));
                }
            })
        }

        return promises;
    }

    createPromiseForMedia(file: File, type: "file" | "image"): Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url), 
                    error => reject(error)
                );
        });
    }

    getRequiredFields(activitySlug: string): { validationField: string, fieldsOutsideArray?: string[], fieldsInsideArray: string[], requiredFields?: number} | null {
        if (activitySlug === 'fill-in-the-blanks') {
            return {validationField: 'items', fieldsInsideArray: ['afterKeyword', 'beforeKeyword', 'keyword'], requiredFields: 2};
        } else if (activitySlug === 'qa') {
            return {
                validationField: 'items',
                fieldsOutsideArray: ['reviewPrompt'],
                fieldsInsideArray: ['name'],
                requiredFields: 3
            };
        } else if (activitySlug === 'matching') {
            return {validationField: 'items', fieldsInsideArray: ['right', 'left'], fieldsOutsideArray: ['leftCategoryTitle', 'rightCategoryTitle'], requiredFields: 3};
        } else if (activitySlug === 'spinner') {
            return {validationField: 'items', fieldsInsideArray: ['scenario'], fieldsOutsideArray: ['reviewPrompt'], requiredFields: 3};
        } else if (activitySlug === 'sorting') {
            return {
                validationField: 'items',
                fieldsOutsideArray: ['leftCategoryTitle', 'rightCategoryTitle'],
                fieldsInsideArray: ['left', 'right'],
                requiredFields: 1
            };
        } else if (activitySlug === 'flashcards') {
            return {validationField: 'items', fieldsInsideArray: ['frontTitle', 'frontText', 'frontTempImage', 'backTitle', 'backText'], requiredFields: 4};
        } else if (activitySlug === 'dice-roller') {
            return {validationField: 'items', fieldsInsideArray: ['prompt'], requiredFields: 6};
        } else if (activitySlug === 'multiple-choice') {
            return {validationField: 'items', fieldsInsideArray: ['prompt', 'value', 'isCorrect'], requiredFields: 5};
        } else {
            return null;
        }
    }

    isValidEditorFields(): boolean {
        if (this.errors.includes('maxChars'))
            return false;
        
        this.errors = [];
        this.activity.title == '' ? this.errors.push('title') : '';
        this.activity.description == '' ? this.errors.push('description') : '';
        this.activeInstructionsArea() && this.activity.instructions == '' ? this.errors.push('instructions') : '';
        return this.errors.length === 0 ? true : false;
    }

    activeInstructionsArea(): boolean {
        return this.activityTypeSlug === 'qa' || this.activityTypeSlug === 'spinner'
        || this.activityTypeSlug === 'dice-roller' ? true : false;
    }

    activeStaticInstructions(): boolean {
        return this.activityTypeSlug === 'flashcards' 
            || this.activityTypeSlug === 'multiple-choice'
            || this.activityTypeSlug === 'dice-roller' ? true : false;
    }

    isValidActivity(activityData: {items: [any]}, outsideArrayFields: Array<string>, insideArrayFields: Array<string>, arrayName: string, requiredFields: number ): boolean {
        this.emptyMandatoryFields = [];
        const activitiesRequired = requiredFields;
            if (insideArrayFields.length > 0) {
                for (var i = 0; i < insideArrayFields.length; i++) {
                    let fieldToCheck = insideArrayFields[i];
    
                    for (var x = 0; x < activitiesRequired; x++) {
                        if (activityData.items[x] !== undefined) {
                            if (activityData.items[x][fieldToCheck] === '' || activityData.items[x][fieldToCheck] === null)
                                this.emptyMandatoryFields.push(fieldToCheck + x);
                        }
                    }
                }
            }
            if (outsideArrayFields?.length > 0) {
                for (var i = 0; i < outsideArrayFields.length; i++) {
                    let fieldToCheck = outsideArrayFields[i];
                    if (activityData[fieldToCheck] === '' || activityData[fieldToCheck] === null) {
                        this.emptyMandatoryFields.push(fieldToCheck);
                    }
                }
            }
            if (this.activityTypeSlug === 'multiple-choice') {
                for (var i = 0; i < activitiesRequired; i++) {
                    for (var x = 0; x < activityData.items[i].answers.length; x++) {
                        if (activityData.items[i].answers[x].value === '' || activityData.items[i].answers[x].value === null) {
                            this.emptyMandatoryFields.push('value' + i + x);
                        }
                    }
                }
            } 

        return this.emptyMandatoryFields.length === 0;
    }

    maxCharsValidation(maxCharsObject: MaxCharsObject) {
        const errorIndex = this.maxCharError.findIndex(f => f.fieldName === maxCharsObject.fieldName);
        if (maxCharsObject.isMaxCharsReached) {
            if (errorIndex == -1)
                this.maxCharError.push(maxCharsObject)
            
        } else {
            if (errorIndex !== -1)
                this.maxCharError.splice(errorIndex, 1);
        }
    }

    hasAnswersNotFilled(activity: InteractiveActivityMultipleChoice): boolean {
        const answers = activity.items.map(item => item.answers);
        var answersNotFilled = [];
        answers.forEach((answer, index) => {
            var hasCorrectAnswer = false;
            for (var i = 0; i < answer.length; i++) {
                if (answer[i].isCorrect) {
                    hasCorrectAnswer = true;
                }
            }

            if (!hasCorrectAnswer) {
                answersNotFilled.push(index + 1);
            }
        });

        if (answersNotFilled.length > 0) {
            this.dialogService.showInformativeModal(
                { 
                    typeModal: 'errorDialog',
                    props: { 
                        title: 'Missing Correct Answer',
                        message: "You need at least 1 correct answer for each question. Please review the following questions: " + answersNotFilled.join(', ') + "."
                    }
                });
            return true
        }
        return false;
    }

}
