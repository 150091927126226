<header>
    <div 
        *ngFor="let h of headers" 
        (click)="h.sortable && sortBy(h.key)"
        [ngClass]="[
            h.sortable ? 'sortable' : '', 
            h.sortable && currentSorting?.key === h.key ? 'sorted' : '',
            h.sortable && currentSorting?.key === h.key ? (currentSorting.asc ? 'asc' : 'desc') : '',
        ]"
    >
        {{h.label}}
        <i *ngIf="h.sortable && (!sorting || currentSorting?.key !== h.key)"></i>
        <app-spinner 
            color="#0466C8" 
            *ngIf="sorting && currentSorting?.key === h.key"
        ></app-spinner>
    </div>
</header>
<div class="body" data-cy="list-view">
    <ng-content></ng-content>
</div>