<app-header>
    <a routerLink="/saved-lists">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!contentList.contentListId ? "New Saved List" : contentList.title}}</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
        <app-button
            type="danger"
            [loading]="deleting"
            (click)="confirmDelete()"
            *ngIf="contentList.contentListId"
            iconUrl="../../../../assets/images/icons/delete.svg"
        >
            Delete
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <div class="column">
                <app-textfield
                    label="Title"
                    [(value)]="contentList.title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
                <div class="thumb-preview">
                    <img [src]="contentList.thumbnail || '../../../../assets/images/icons/image-placeholder-large.svg'" />
                </div>
                <app-file-picker
                    id="thumbnail"
                    type="image"
                    label="Thumbnail"
                    [value]="contentList.thumbnail"
                    [error]="emptyMandatoryFields.includes('thumbnail') ? 'This field is required.' : ''"
                    (fileChange)="contentList.thumbnailFile = $event; !$event && contentList.thumbnail = ''"
                ></app-file-picker>
                <div class="list-url" *ngIf="contentList.slug">
                    <label>List URL</label>
                    <div class="copy-field">
                        <app-textfield readonly="readonly" [value]="'https://slp.everydayspeech.com/saved-lists/' + contentList.slug">
                        </app-textfield>
                        <copy-to-clipboard [value]="'https://slp.everydayspeech.com/saved-lists/' + contentList.slug">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 13V12C9 10.346 10.346 9 12 9H13V5.667C13 5.299 12.701 5 12.333 5H5.667C5.299 5 5 5.299 5 5.667V12.333C5 12.701 5.299 13 5.667 13H9ZM9 15H5.667C4.196 15 3 13.804 3 12.333V5.667C3 4.196 4.196 3 5.667 3H12.333C13.804 3 15 4.196 15 5.667V9H18C19.654 9 21 10.346 21 12V18C21 19.654 19.654 21 18 21H12C10.346 21 9 19.654 9 18V15ZM11 12C11 11.449 11.449 11 12 11H18C18.552 11 19 11.449 19 12V18C19 18.551 18.552 19 18 19H12C11.449 19 11 18.551 11 18V12Z"/>
                            </svg>
                        </copy-to-clipboard>
                    </div>
                </div>
            </div>
            <div class="column">
                <app-tagfield
                    label="Tags"
                    [(values)]="contentList.tags"
                ></app-tagfield>
                <app-multi-select
                    size="small"
                    label="Curriculum"
                    [options]="availableCurriculums"
                    [(selectedOptions)]="contentList.curriculums"
                    *ngIf="availableCurriculums && contentList.curriculums"
                    [error]="emptyMandatoryFields.includes('curriculums') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-multi-select
                    size="small"
                    label="Plan"
                    [options]="availablePlans"
                    [(selectedOptions)]="contentList.plans"
                    *ngIf="availablePlans && contentList.plans"
                    [error]="emptyMandatoryFields.includes('plans') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-switch
                    id="active"
                    label="Active"
                    [(value)]="contentList.active"
                ></app-switch>
            </div>
        </div>
        <div class="rotation">
            <div class="title">Current Rotation</div>
            <div class="flex-row">
                <div class="column">
                    <app-richtextarea
                        label="Description"
                        placeholder="Place your text"
                        [(value)]="contentList.description"
                        [error]="emptyMandatoryFields.includes('description') ? 'This field is required.' : ''"
                    ></app-richtextarea>
                    <app-file-picker
                        id="guide"
                        type="pdf"
                        label="Guide"
                        [value]="contentList.guide"
                        (fileChange)="contentList.guideFile = $event; !$event && contentList.guide = ''"
                        [error]="emptyMandatoryFields.includes('guide') ? 'This field is required.' : ''"
                    ></app-file-picker>
                </div>
                <div class="column">
                    <app-textfield
                        size="small"
                        type="datetime-local"
                        label="Release Date and Time (EST)"
                        [(value)]="contentList.releaseDate"
                        iconUrl="../../../../assets/images/icons/calendar.svg"
                        [error]="emptyMandatoryFields.includes('releaseDate') ? 'This field is required.' : ''"
                    ></app-textfield>
                </div>
            </div>            
            <div class="content-picker-row" *ngFor="let c of contentList.contentsDetails;let i = index">
                <label *ngIf="contentList.contentsDetails[i]?.contentId">{{i + 1}}</label>
                <app-content-picker
                    layout="row"
                    title="Current Rotation"
                    [content]="contentList.contentsDetails[i]"
                    (contentChange)="onContentDetailsChange($event, i)"
                    [error]="emptyMandatoryFields.includes('contents' + i) ? 'This field is required.' : ''"
                ></app-content-picker>
            </div>
        </div>
        <div class="rotation" *ngIf="contentList.contentListId">
            <div class="title">Next Rotation</div>
            <div class="flex-row">
                <div class="column">
                    <app-richtextarea
                        label="Description"
                        placeholder="Place your text"
                        [(value)]="contentList.nextRotationDescription"
                    ></app-richtextarea>
                    <app-file-picker
                        type="pdf"
                        label="Guide"
                        id="next-guide"
                        [value]="contentList.nextRotationGuide"
                        (fileChange)="contentList.nextRotationGuideFile = $event; !$event && contentList.nextRotationGuide = ''"
                    ></app-file-picker>
                </div>
                <div class="column">
                    <app-textfield
                        size="small"
                        type="datetime-local"
                        label="Release Date and Time (EST)"
                        [(value)]="contentList.nextRotationReleaseDate"
                        iconUrl="../../../../assets/images/icons/calendar.svg"
                        [error]="emptyMandatoryFields.includes('nextRotationReleaseDate') ? 'This field is required.' : ''"
                    ></app-textfield>
                </div>
            </div>            
            <div class="content-picker-row" *ngFor="let c of contentList.nextRotationContentsDetails;let i = index">
                <label *ngIf="contentList.nextRotationContentsDetails[i]?.contentId">{{i + 1}}</label>
                <app-content-picker
                    layout="row"
                    title="Next Rotation"
                    [content]="contentList.nextRotationContentsDetails[i]"
                    (contentChange)="onNextRotationContentDetailsChange($event, i)"
                    [error]="emptyMandatoryFields.includes('nextRotationContents' + i) ? 'This field is required.' : ''"
                ></app-content-picker>
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
