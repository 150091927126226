import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ButtonModule } from '../../../button/button.module';
import { SelectModule } from '../../../select/select.module';
import { WtdContentPickerComponent } from './wtd-content-picker.component';
import { ContentPickerModule } from '../../../content-picker/content-picker.module';
import { MiniGamePickerModule } from '../../../mini-game-picker/mini-game-picker.module';
import { TextfieldModule } from '../../../textfield/textfield.module';

@NgModule({
    declarations: [
        WtdContentPickerComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        SelectModule,
        SharedModule,
        TextfieldModule,
        ContentPickerModule,
        MiniGamePickerModule,
    ],
    exports: [
        WtdContentPickerComponent,
    ]
})
export class WtdContentPickerModule { }