import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { SelUnitsListComponent } from './sel-units-list.component';
import { ListViewModule } from 'src/app/shared/list/list-view/list-view.module';
import { ListToolbarModule } from 'src/app/shared/list/list-toolbar/list-toolbar.module';
import { ListPaginatorModule } from 'src/app/shared/list/list-paginator/list-paginator.module';
import { CopyToClipboardModule } from 'src/app/shared/copy-to-clipboard/copy-to-clipboard.module';

@NgModule({
    declarations: [
        SelUnitsListComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        ListViewModule,
        ListToolbarModule,
        ListPaginatorModule,
        CopyToClipboardModule,
    ],
})
export class SelUnitsListModule { }