import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { environment } from 'src/environments/environment';
import { UnitService } from 'src/app/services/unit.service';
import { Content } from 'src/app/models/content/content.model';
import { DialogService } from 'src/app/services/dialog.service';
import { SelUnit } from 'src/app/models/sel-unit/sel-unit.model';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-sel-units-editor',
    templateUrl: './sel-units-editor.component.html',
    styleUrls: ['./sel-units-editor.component.scss']
})
export class SelUnitsEditorComponent {
    unit: SelUnit = {
        order: 0,
        title: "",
        unitId: "",
        bundles: [{
            bundleId: "",
            contentId: "",
            contentType: "",
            contentTitle: "",
        }],
        status: ""
    };
    activeTab = 0;
    saving: boolean;
    loading: boolean;
    deleting: boolean;
    emptyMandatoryFields = Array<string>();
    availableAges: Array<{ id: string, label: string }> = [];
    availableOrders: Array<{ id: string, label: string }> = [];
    isAssessments: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ageService: AgeTrackService,
        private unitService: UnitService,
        private dialogService: DialogService,
        private curriculumService: CurriculumService,
        private httpService: HttpService,
    ) {
        this.route.params.subscribe(data => {
            this.loading = true;
            if (data.unitId === "new")
                this.loadSelCurriculumData(data.unitId);
            else
                this.load(data.unitId);
        });
    }

    loadAvailableAges(unitId: string, curriculumId: string) {
        this.ageService.list({ limit: 1000, curriculum: curriculumId })
            .subscribe(response => {
                this.availableAges = response.ages.map(a => {
                    return { id: a.ageId, label: a.title };
                });

                if (unitId === "new" && this.availableAges.length > 0) {
                    this.unit.ageId = this.availableAges[0].id;
                    this.loadAvailableOrders(this.availableAges[0].id, unitId);
                } else {
                    this.loadAvailableOrders(this.unit.ageId, unitId);
                }
            });
    }

    loadAvailableOrders(parentAgeId: string, unitId?: string) {
        this.availableOrders = [];

        this.unitService.listSel({ age: parentAgeId })
            .subscribe(response => {
                for (var i = 0; i < response.totalResults; i++) {
                    var order = (i + 1).toString();
                    this.availableOrders.push({
                        id: order,
                        label: order
                    });
                }

                if (
                    unitId === "new" || !unitId ||
                    !this.unit.order || response.totalResults <= 0
                ) {
                    var newOrder = (this.availableOrders.length + 1).toString();
                    this.availableOrders.push({
                        id: newOrder,
                        label: newOrder
                    });
                    this.unit.order = this.availableOrders.length;
                }

                this.loading = false;
            });
    }

    loadSelCurriculumData(unitId: string) {
        this.curriculumService.get(environment.selCurriculumSlug)
            .subscribe(response => {
                this.unit.curriculumId = response.curriculum.curriculumId;
                this.loadAvailableAges(unitId, this.unit.curriculumId);
            });
    }

    load(unitId: string) {
        this.unitService.get(unitId)
            .subscribe(response => {
                this.loading = false;
                this.unit = response.unit;
                this.isAssessments = !(this.unit.observationalChecklistPre || this.unit.observationalChecklistPost) ? true : false;
                this.loadAvailableAges(unitId, this.unit.curriculumId);

                if (!this.unit.bundles)
                    this.unit.bundles = [];

                this.unit.bundles.push({
                    bundleId: "",
                    contentId: "",
                    contentType: "",
                    contentTitle: "",
                });
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        const emptyBundle = this.unit.bundles.findIndex(f => f.contentId === '');
        this.unit.bundles.splice(emptyBundle, 1);

        this.saving = true;
        Promise.all(this.getMediaReadyForUpload())
            .then(() => this.unitService.save(this.unit).subscribe(response => {
                this.saving = false;
                this.dialogService.showSuccessAlert("Save Successful.");
                
                if (response) 
                    this.router.navigate(["/sel-units/" + response.unitId]);
                else {
                    this.load(this.unit.unitId)
                }

            }, errors => {
                this.saving = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            }));
    }

    getMediaReadyForUpload(): Promise<string>[] {
        var promises: Promise<string>[] = [];
        if (this.unit.pdfSummaryFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.pdfSummaryFile, "file")
                    .then(url => {
                        this.unit.pdfSummary = url;
                        this.unit.pdfSummaryFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.pdfBulletinBoardFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.pdfBulletinBoardFile, "file")
                    .then(url => {
                        this.unit.pdfBulletinBoardLink = url;
                        this.unit.pdfBulletinBoardFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.unitPreAssessmentFile) {
            this.unit.observationalChecklistPre = '';
            promises.push(
                this.createPromiseForMedia(this.unit.unitPreAssessmentFile, "file")
                    .then(url => {
                        this.unit.unitPreAssessmentLink = url;
                        this.unit.unitPreAssessmentFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.unitPostAssessmentFile) {
            this.unit.observationalChecklistPost = '';
            promises.push(
                this.createPromiseForMedia(this.unit.unitPostAssessmentFile, "file")
                    .then(url => {
                        this.unit.unitPostAssessmentLink = url;
                        this.unit.unitPostAssessmentFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.unitPreObservationalFile) {
            this.unit.unitPreAssessmentLink = '';
            promises.push(
                this.createPromiseForMedia(this.unit.unitPreObservationalFile, "file")
                    .then(url => {
                        this.unit.observationalChecklistPre = url;
                        this.unit.unitPreObservationalFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.unitPostObservationalFile) {
            this.unit.unitPostAssessmentLink = '';
            promises.push(
                this.createPromiseForMedia(this.unit.unitPostObservationalFile, "file")
                    .then(url => {
                        this.unit.observationalChecklistPost = url;
                        this.unit.unitPostObservationalFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.iconFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.iconFile, "image")
                    .then(url => {
                        this.unit.icon = url;
                        this.unit.iconFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.mascotFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.mascotFile, "file")
                    .then(url => {
                        this.unit.mascot = url;
                        this.unit.mascotFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.badgeImageFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.badgeImageFile, "image")
                    .then(url => {
                        this.unit.badgeImageLink = url;
                        this.unit.badgeImageFile = null;
                        return url;
                    })
            );
        }
        if (this.unit.pdfBadgeFile) {
            promises.push(
                this.createPromiseForMedia(this.unit.pdfBadgeFile, "image")
                    .then(url => {
                        this.unit.pdfBadgeLink = url;
                        this.unit.pdfBadgeFile = null;
                        return url;
                    })
            );
        }

        return promises;
    }

    createPromiseForMedia(
        file: File,
        type: "video" | "image" | "file"
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url),
                    error => reject(error)
                );
        });
    }

    allFieldsAreValid(): boolean {
        this.emptyMandatoryFields = [];
        if (!this.unit.title)
            this.emptyMandatoryFields.push("title");

        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    delete() {
        this.deleting = true;
        this.unitService.delete(this.unit.unitId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/sel-units"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " +
                this.unit.title + " SEL Unit from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onAgeChange($event: string) {
        this.unit.order = 0;
        this.loadAvailableOrders($event, this.unit.unitId);
    }

    onBundleChange($event: Content, i: number) {
        if (!$event) {
            this.unit.bundles.splice(i, 1);
            return;
        }

        this.unit.bundles[i].contentType = $event.type;
        this.unit.bundles[i].contentTitle = $event.title;
        this.unit.bundles[i].contentId = $event.contentId;

        this.unit.bundles.push({
            bundleId: "",
            contentId: "",
            contentType: "",
            contentTitle: "",
        });
    }

    goBack() {
        this.router.navigate(["/sel-units"]);
    }

    updateSwitchStatus(unitStatus: boolean) {
        this.unit.status = unitStatus === true ? 'active' : 'draft';
    }

    updateAssesments(status: boolean) {
        this.isAssessments = status === true ? true : false;

        if (this.isAssessments && (this.unit.unitPreAssessmentLink || this.unit.unitPostAssessmentLink)) {
            this.unit.unitPreAssessmentFile = null;
            this.unit.unitPostAssessmentFile = null;
        }

        if (!this.isAssessments && (this.unit.observationalChecklistPre || this.unit.observationalChecklistPost)) {
            this.unit.unitPreObservationalFile = null;
            this.unit.unitPostObservationalFile = null;
        }
    }
}
