import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { UnitsEditorComponent } from './units-editor.component';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ContentPickerModule } from 'src/app/shared/form/content-picker/content-picker.module';

@NgModule({
    declarations: [
        UnitsEditorComponent
    ],
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        RouterModule,
        SelectModule,
        ButtonModule,
        TextfieldModule,
        ContentPickerModule,
    ],
})
export class UnitsEditorModule { }