import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { isEmptyObject } from "../utils/emptyObject.validator";
import { SelLesson } from "../models/sel-lesson/sel-lesson.model";
import { SelLessonList } from "../models/sel-lesson/sel-lesson-list.model";
import { SelLessonListParams } from "../models/sel-lesson/sel-lesson-list-params.model";

@Injectable({
    providedIn: 'root'
})
export class SelLessonService {
    constructor(private http: HttpService) { }

    list(params?: SelLessonListParams): Observable<SelLessonList> {
        return this.http.get('admin/lessons', params);
    }

    get(lessonId: string): Observable<any> {
        return this.http.get('admin/lessons/' + lessonId);
    }

    save(lesson: SelLesson): Observable<any> {
        const filteredLesson = Object.assign({}, lesson);
        filteredLesson.steps = lesson.steps.filter(s => !isEmptyObject(s));
        filteredLesson.steps?.forEach((step: any) => {
            if (step.type === "content" && step.content) {
                step.itemId = step.content.contentId;
                return;
            }

            if (step.type !== "prompt" || !step.prompt)
                return;

            step.pdf = step.prompt.pdf;
            step.text = step.prompt.text;
            step.thumbnail = step.prompt.thumbnail;
            step.animatedThumbnail = step.prompt.animatedThumbnail;
        });
        if (filteredLesson.lessonId)
            return this.http.put('admin/lessons/' + filteredLesson.lessonId, filteredLesson);

        return this.http.post('admin/lessons', filteredLesson);
    }

    delete(lessonId: string): Observable<any> {
        return this.http.delete('admin/lessons/' + lessonId);
    }
}
