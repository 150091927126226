<div class="container" [class.error]="mainError">
    <label *ngIf="label">{{label}}</label>
    <div class="wtd-row" *ngFor="let item of items;let i = index">
        <label class="index">{{i + 1}}</label>
        <app-wtd-picker
            [item]="item"
            [viewMode]="viewMode"
            [errors]="getErrorsForIndex(i)"
            [index]="i"
            (whatToDoChange)="onWhatToDoChange($event, i)"
        ></app-wtd-picker>
    </div>
</div>
<div class="error-message" *ngIf="mainError">{{mainError}}</div>