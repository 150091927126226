import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
    @Input() value = "";
    @Input() label:string;
    @Input() error:string;
    @Input() disabled:boolean;
    @Input() inlineLabel:boolean;
    @Input() size:"small"|"medium"|"large" = "medium";
    @Input() options:Array<{id:string, value:string}>;
    @Input() feedback:"success"|"info"|"warning"|"error";

    @Output() valueChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    onChange(e:any) {
        this.value = e.target.value;
        this.valueChange.emit(this.value);
    }
}
