import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent implements OnInit {

    @Input() radioId: string;
    @Input() radioLabel: string;
    @Input() selected: boolean;

    @Output() radioSelectedData = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {
    }

    radioTouched(radioId: string) {
        this.radioSelectedData.emit(radioId)
    }

}
