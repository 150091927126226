import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ListParams } from '../models/list-params/list-params.model';
import { ContentList } from '../models/content-list/content-list.model';
import { ContentLists } from '../models/content-list/content-lists.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class ContentListService {
    constructor(private http: HttpService) {}

    list(params?: ListParams): Observable<ContentLists> {
        return this.http.get('admin/content-lists', params);
    }

    get(contentListId: string): Observable<any> {
        return this.http.get('admin/content-lists/' + contentListId);
    }

    save(contentList: ContentList): Observable<any> {
        const filteredContentList = Object.assign({}, contentList);
        filteredContentList.contents = filteredContentList.contents.filter(
            (i) => i
        );

        if (contentList.contentListId) {
            return this.http.put(
                'admin/content-lists/' + contentList.contentListId,
                filteredContentList
            );
        }

        return this.http.post('admin/content-lists', filteredContentList);
    }

    delete(contentListId: string): Observable<any> {
        return this.http.delete('admin/content-lists/' + contentListId);
    }

    getFeaturedList(): Observable<any> {
        let params = new HttpParams().set('typeFilter', 'featured');
        return this.http.get('admin/content-lists', params);
    }
}
