import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CanComponentDeactivate } from 'src/app/interfaces/can-component-deactivate';
import { Content } from 'src/app/models/content/content.model';
import { ContentListService } from 'src/app/services/content-list.service';
import { ContentService } from 'src/app/services/content.service';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { DialogService } from 'src/app/services/dialog.service';
import { PlanService } from 'src/app/services/plan.service';
import { ContentPickerComponent } from 'src/app/shared/form/content-picker/content-picker.component';

@Component({
    selector: 'app-scc-featured-content',
    templateUrl: './scc-featured-content.component.html',
    styleUrls: ['./scc-featured-content.component.scss'],
})
export class SccFeaturedContentComponent implements OnInit, CanComponentDeactivate {
    constructor(
        private contentListService: ContentListService,
        private contentService: ContentService,
        private curriculumService: CurriculumService,
        private planService: PlanService,
        private dialogService: DialogService,
        private router: Router
    ) {}

    isEditingMode: boolean;
    availableUnits: Array<{ id: string; label: string }> = [
        { id: 'featured', label: 'Featured' },
        { id: 'new', label: 'New' },
    ];
    @ViewChild('contentPickerComponent', { static: false })
    contentPickerComponent: ContentPickerComponent;
    currentIndexChanging: number = 0;
    contentListData: any;
    contentListBackupData: any;
    sccCurriculumId: string = '';
    availablePlans: Array<string> = [];
    isLoading: boolean = true;
    isFeaturedContentChanged: boolean = false;
    nextUrl: string;
    redirectActivate: boolean;
    firstContentData: Content;

    ngOnInit(): void {
        this.getFeaturedContents();
        this.loadAvailableCurriculums();
        this.loadAvailablePlans();
    }

    async getFeaturedContents(): Promise<void> {
        let featuredContentId: string = '';
        await this.contentListService
            .getFeaturedList()
            .toPromise()
            .then(
                (data) =>
                    (featuredContentId = data.contentLists.map((m) => m.contentListId))
            );
        this.firstContentData = await this.contentService
            .list()
            .toPromise()
            .then((content) => content.contents[0]);
        this.contentListService.get(featuredContentId).subscribe((data) => {
            this.contentListData = data.contentList;
            this.contentListBackupData = JSON.parse(JSON.stringify(this.contentListData));
            this.initializeContentListData();
        });
    }

    initializeContentListData(): void {
        this.contentListData.contents = this.contentListData.contents ?? [];
        this.contentListData.contentsDetails = this.contentListData.contentsDetails ?? [];
        this.contentListData.curriculums = this.contentListData.curriculums ?? [];
        this.contentListData.plans = this.contentListData.plans ?? [];
        // in case where the featured list has less than 4 items then the FE add the necessary items
        if (this.contentListData.contentsDetails.length < 4)
            this.fillContentsDetailsIfNeeded();
        if (this.contentListData.contents.length < 4)
            this.fillContentsIfNeeded();

        this.isLoading = false;
    }

    fillContentsDetailsIfNeeded() {
        const remainingItemsToFill = 4 - this.contentListData.contentsDetails.length;
        for (var i = 0; i < remainingItemsToFill; i++) {
            this.contentListData.contentsDetails.push({
                contentId: this.firstContentData.contentId,
                title: this.firstContentData.title,
                url: this.firstContentData.url,
                type: this.firstContentData.type,
                featureTag: 'new'
            })
        }
    }

    fillContentsIfNeeded() {
        const remainingItemsToFill = 4 - this.contentListData.contents.length;
        for (var i = 0; i < remainingItemsToFill; i++) {
            this.contentListData.contents.push(this.firstContentData.contentId);
        }
    }

    saveFeaturedContent(): void {
        this.isLoading = true;
        this.contentListData.thumbnail = '';
        this.contentListData.description = '';
        this.contentListData.type = 'featured';
        this.contentListData.featureTag = 'featured';
        this.contentListData.curriculums.push(this.sccCurriculumId);
        this.contentListData.plans = this.availablePlans;
        this.contentListData.contentTags = this.contentListData.contentsDetails.map(m => {
            return {
                uuid: m.contentId,
                featureTag: m.featureTag
            }
        });
        
        this.contentListService.save(this.contentListData).subscribe(
            () => {
                this.isLoading = false;
                this.dialogService.showSuccessAlert("Save Successful.");
                this.isEditingMode = false;
            },
            (err) => {
                console.log('error', err);
                this.isLoading = false;
            }
        );
    }

    canDeactivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (this.redirectActivate) {
            return true;
        }
        this.nextUrl = nextState.url;

        return this.showLeaveConfirmationDialogAndNavigate()
    }

    showLeaveConfirmationDialogAndNavigate(): boolean {
        if (this.isEditingMode && this.isFeaturedContentChanged) {
            this.dialogService.showConfirmationDialog({
                type: "danger",
                confirmButtonLabel: "Continue",
                cancelLabel: "Keep Editing",
                warningMessage: "Warning: Your changes will not be saved.",
                onConfirmCallback: () => {
                    this.redirectActivate = true;
                    this.router.navigate([this.nextUrl]);
                },
                dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
                title: "Are you sure you want to leave?",
            });
            return false
        } else {
            return true
        }
    }

    loadAvailableCurriculums(): void {
        this.curriculumService.list({ limit: 1000 }).subscribe((response) => {
            this.sccCurriculumId = response.curriculums.find(
                (f) => f.slug === 'social-communication-skills'
            ).curriculumId;
        });
    }

    loadAvailablePlans(): void {
        this.planService.list({ limit: 1000 }).subscribe((response) => {
            this.availablePlans = response.plans.map((c) => c.planId);
        });
    }

    openContentPicker(index: number): void {
        if (this.contentPickerComponent) {
            this.contentPickerComponent.openModal();
            this.currentIndexChanging = index;
        }
    }

    enableEditMode(): void {
        this.isEditingMode = true;
    }

    disableEditMode(): void {
        this.isEditingMode = false;
        this.contentListData = this.contentListBackupData;
        this.initializeContentListData();
        this.setIndexDefault();
    }

    onTagChanged(tagType: string, i: number): void {
        this.isFeaturedContentChanged = true;
        this.contentListData.contentsDetails[i].featureTag = tagType;
    }

    updateFeaturedContent(event: Content): void {
        this.isFeaturedContentChanged = true;
        const contentId = this.contentListData.contentsDetails[this.currentIndexChanging].contentId;
        const index = this.contentListData.contents.findIndex(content => content === contentId);
        if (index !== -1) {
            this.contentListData.contents[index] = event.contentId;
        } else {
            this.contentListData.contents.push(event.contentId);
        }

        if (this.contentListData.contentsDetails[this.currentIndexChanging]) {
            const contentId = this.contentListData.contentsDetails[this.currentIndexChanging].contentId;
            this.contentListData.contentsDetails[this.currentIndexChanging].url = event.url;
            this.contentListData.contentsDetails[this.currentIndexChanging].type = event.type;
            this.contentListData.contentsDetails[this.currentIndexChanging].title = event.title;
            this.contentListData.contentsDetails[this.currentIndexChanging].contentId = event.contentId;
        } else {
            const contentDetailsObj = {
                url: event.url,
                type: event.type,
                title: event.title,
                contentId: event.contentId,
            };
            this.contentListData.contentsDetails.push(contentDetailsObj);
        }
    }

    dialogSaveValidation(): void {
        this.dialogService.showConfirmationDialog({
            type: "primary",
            confirmButtonLabel: "Save",
            cancelLabel: "Keep Editing",
            warningMessage: "Warning: Saving this list will change the current Featured Content.",
            onConfirmCallback: () => this.saveFeaturedContent(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/save.svg",
            title: "Are you sure you want to save this list?",
        });
    }

    setIndexDefault(): void {
        this.currentIndexChanging = 0;
    }
}
