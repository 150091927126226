import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
    @Input() tooltip: string;
    @Input() iconUrl: string;
    @Input() loading: boolean;
    @Input() layout: "filled" | "outline" | "ghost" | "border" = "filled";
    @Input() size: "tiny" | "small" | "medium" | "large" | "giant" = "medium";
    @Input() type: "basic" | "primary" | "success" | "info" | "warning" | "danger" | "transparent" = "basic";
    @Input() disableBtn: boolean;

    constructor() { }

}
