<app-select
    size="small"
    [(value)]="selectedType"
    (valueChange)="onTypeChange($event)"
    [options]="isWtdItem(item) ? wtdOptions : stepsOptions"
></app-select>
<div class="field type-{{selectedType}}">
    <ng-container *ngIf="selectedType === 'text'">
        <app-textfield
            size="medium"
            label="Main text"
            inlineLabel="true"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
    </ng-container>
    <ng-container *ngIf="selectedType === 'worksheet'">
        <app-textfield
            size="medium"
            label="Main text"
            inlineLabel="true"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-file-picker
            type="pdf"
            label="PDF File"
            inlineLabel="true"
            id="wtd-worksheet"
            [file]="item.file"
            [value]="item.url"
            [error]="getErrorsForField('pdf')"
            (fileChange)="item.file = $event; !$event && item.url = ''"
        ></app-file-picker>
    </ng-container>
    <ng-container *ngIf="selectedType === 'download'">
        <app-textfield
            size="medium"
            label="Main text"
            inlineLabel="true"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-file-picker
            type="pdf"
            label="PDF File"
            id="wtd-download"
            inlineLabel="true"
            [file]="item.file"
            [value]="item.url"
            [error]="getErrorsForField('pdf')"
            (fileChange)="item.file = $event; !$event && item.url = ''"
        ></app-file-picker>
        <app-file-picker
            type="image"
            label="Thumbnail"
            inlineLabel="true"
            id="wtd-download-thumbnail"
            [value]="item.thumbnail"
            [file]="item.thumbnailFile"
            (fileChange)="item.thumbnailFile = $event; !$event && item.thumbnail = ''"
        ></app-file-picker>
    </ng-container>
    <ng-container *ngIf="selectedType === 'video'">
        <app-textfield
            size="medium"
            label="Main text"
            inlineLabel="true"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-textfield
            size="medium"
            inlineLabel="true"
            label="Helper text"
            [(value)]="item.helperText"
        ></app-textfield>
        <app-textfield
            size="medium"
            inlineLabel="true"
            label="Video title"
            [(value)]="item.title"
            [error]="getErrorsForField('title')"
        ></app-textfield>
        <app-file-picker
            type="video"
            id="wtd-video"
            label="Video File"
            inlineLabel="true"
            [file]="item.file"
            [value]="item.url"
            [error]="getErrorsForField('video')"
            (fileChange)="item.file = $event; !$event && item.url = ''"
        ></app-file-picker>
    </ng-container>
    <ng-container *ngIf="selectedType === 'workbook'">
        <app-textfield
            size="medium"
            inlineLabel="true"
            label="PDF Download"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-file-picker
            type="pdf"
            label="PDF File"
            id="wtd-workbook"
            inlineLabel="true"
            [file]="item.file"
            [value]="item.url"
            [error]="getErrorsForField('pdf')"
            (fileChange)="item.file = $event; !$event && item.url = ''"
        ></app-file-picker>
        <app-textfield
            size="medium"
            inlineLabel="true"
            label="Interactive Workbook URL"
            [(value)]="item.secondaryUrl"
            [error]="getErrorsForField('secondaryUrl')"
        ></app-textfield>
    </ng-container>
    <ng-container *ngIf="selectedType === 'visualTool'">
        <app-textfield
            size="medium"
            inlineLabel="true"
            label="Main Text"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-file-picker
            type="pdf+image"
            inlineLabel="true"
            label="Visual Tool"
            id="wtd-visual-tool"
            [file]="item.file"
            [value]="item.url"
            [error]="getErrorsForField('pdf')"
            (fileChange)="item.file = $event; !$event && item.url = ''"
        ></app-file-picker>
        <app-file-picker
            type="image"
            label="Thumbnail"
            inlineLabel="true"
            id="wtd-thumbnail"
            [value]="item.thumbnail"
            [file]="item.thumbnailFile"
            (fileChange)="item.thumbnailFile = $event; !$event && item.thumbnail = ''"
        ></app-file-picker>
    </ng-container>
    <ng-container *ngIf="selectedType === 'content' && isWtdItem(item)">
        <app-textfield
            size="medium"
            label="Main Text"
            inlineLabel="true"
            [(value)]="item.text"
            [error]="getErrorsForField('text')"
        ></app-textfield>
        <app-wtd-content-picker
            [content]="item.childDetails"
            [error]="getErrorsForField('childId')"
            (contentChange)="onContentChange($event)"
        ></app-wtd-content-picker>
    </ng-container>
    <ng-container *ngIf="!isWtdItem(item)">
        <ng-container 
            *ngIf="selectedType === 'content' || selectedType === 'prompt'"
        >
            <div class="flex-row padded">
                <app-select
                    size="small"
                    label="Title"
                    inlineLabel="true"
                    [options]="stepsDefaultTitles"
                    (valueChange)="item.title = $event"
                    [value]="isCustomStepTitle(item.title) ? '' : item.title"
                ></app-select>
                <app-textfield
                    size="small"
                    inlineLabel="true"
                    [(value)]="item.title"
                    [error]="getErrorsForField('title')"
                    *ngIf="isCustomStepTitle(item.title)"
                ></app-textfield>
            </div>
            <app-textfield
                size="small"
                inlineLabel="true"
                label="Time estimate"
                [(value)]="item.subtitle"
                [error]="getErrorsForField('subtitle')"
            ></app-textfield>
        </ng-container>
        <ng-container *ngIf="selectedType === 'content'">
            <div class="switch-tooltip">
                <app-switch
                    [id]="'switchLesson' + index"
                    label="Show Tooltip"
                    [(value)]="item.showTooltip"
                ></app-switch> 
                <p>
                    NOTE: If there is a Skill Review PDF in the page, tooltip should not be present.
                </p>
            </div>
            <app-wtd-content-picker
                [content]="item.content"
                [error]="getErrorsForField('content')"
                (contentChange)="onContentChange($event)"
            ></app-wtd-content-picker>
        </ng-container>
        <ng-container *ngIf="selectedType === 'prompt'">
            <app-richtextarea
                label="Prompt Text"
                [(value)]="item.prompt.text"
                placeholder="Place your text"
                [error]="getErrorsForField('text')"
            ></app-richtextarea>
            <app-file-picker
                type="image"
                size="small"
                id="thumb-image"
                inlineLabel="true"
                label="Thumbnail image"
                [value]="item.prompt.thumbnail"
                [error]="getErrorsForField('thumbnail')"
                (fileChange)="item.prompt.thumbnailFile = $event; !$event && item.prompt.thumbnail = ''"
            ></app-file-picker>
            <app-file-picker
                type="json"
                size="small"
                inlineLabel="true"
                id="anim-thumb-image"
                label="Animated Thumbnail"
                [value]="item.prompt.animatedThumbnail"
                [error]="getErrorsForField('animatedThumbnail')"
                (fileChange)="item.prompt.animatedThumbnailFile = $event; !$event && item.prompt.animatedThumbnail = ''"
            ></app-file-picker>
            <app-file-picker
                type="pdf"
                size="small"
                id="pdf-image"
                inlineLabel="true"
                label="PDF Download"
                [value]="item.prompt.pdf"
                [error]="getErrorsForField('pdf')"
                (fileChange)="item.prompt.pdfFile = $event; !$event && item.prompt.pdf = ''"
            ></app-file-picker>
        </ng-container>
    </ng-container>
    
</div>