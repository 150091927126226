import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-paginator',
    templateUrl: './list-paginator.component.html',
    styleUrls: ['./list-paginator.component.scss']
})
export class ListPaginatorComponent {
    @Input() totalPages = 1;
    @Input() currentPage = 1;
    
    @Output() onPageChange = new EventEmitter<number>();
    
    constructor() { }

    setPage(newPage:number) {
        if (newPage <= 1)
            newPage = 1;
        if (newPage >= this.totalPages)
            newPage = this.totalPages;

        this.currentPage = newPage;
        this.onPageChange.emit(this.currentPage);
    }
}
