<div class="container {{error ? 'error' : ''}} {{size}}">
    <label *ngIf="label">{{label}}</label>
    <div #optionsContainer class="options-container {{opened ? 'opened' : ''}}">
        <div class="select" (click)="opened = !opened">{{currentSelectLabel}}</div>
        <div class="options" *ngIf="options && opened">
            <div class="option" *ngFor="let o of options">
                <input 
                    [id]="o.id"
                    type="checkbox" 
                    [checked]="optionIsChecked(o.id)"
                    (input)="onChange(o.id, $event.target.checked)"
                />
                <label [for]="o.id" class="checkbox"></label>
                <label [for]="o.id" class="label">
                    {{o.label}}
                </label>
            </div>
        </div>
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>
</div>