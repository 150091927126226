import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { ListParams } from "../models/list-params/list-params.model";
import { ContentSeries } from "../models/content-series/content-series.model";
import { ContentSeriesList } from "../models/content-series/content-series-list.model";

@Injectable({
    providedIn: 'root'
})
export class ContentSeriesService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<ContentSeriesList> {
        return this.http.get('admin/content-series', params);
    }

    get(contentSeriesId:string):Observable<any> {
        return this.http.get('admin/content-series/' + contentSeriesId);
    }

    save(contentSeries:ContentSeries):Observable<any> {
        const filteredContentSeries = Object.assign({}, contentSeries);
        filteredContentSeries.contents = 
            filteredContentSeries.contents.filter(i => i);

        if (contentSeries.contentSeriesId) {
            return this.http.put('admin/content-series/' + 
                contentSeries.contentSeriesId, filteredContentSeries);
        }
        
        return this.http.post('admin/content-series', filteredContentSeries);
    }

    delete(contentSeriesId:string):Observable<any> {
        return this.http.delete('admin/content-series/' + contentSeriesId);
    }
}
