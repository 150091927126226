import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { Unit } from "../models/unit/unit.model";
import { UnitList } from "../models/unit/unit-list.model";
import { environment } from "src/environments/environment";
import { UnitListParams } from "../models/unit/unit-list-params.model";

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    constructor(private http:HttpService) { }

    listSc(params?:UnitListParams):Observable<UnitList> {
        const curriculumId = environment.scCurriculumSlug;
        return this.http.get('admin/units?curriculum=' + curriculumId, params);
    }

    listSel(params?:UnitListParams):Observable<UnitList> {
        const curriculumId = environment.selCurriculumSlug;
        return this.http.get('admin/units?curriculum=' + curriculumId, params);
    }

    get(unitId:string):Observable<any> {
        return this.http.get('admin/units/' + unitId);
    }

    save(unit:Unit):Observable<any> {
        if (unit.unitId)
            return this.http.put('admin/units/' + unit.unitId, unit);
        
        return this.http.post('admin/units', unit);
    }

    delete(unitId:string):Observable<any> {
        return this.http.delete('admin/units/' + unitId);
    }
}
