import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-richtextarea',
    templateUrl: './richtextarea.component.html',
    styleUrls: ['./richtextarea.component.scss']
})
export class RichtextareaComponent implements OnInit {
    @Input() value = "";
    @Input() label:string;
    @Input() error:string;
    @Input() placeholder:string;
    @Input() size:"small"|"medium"|"large" = "medium";

    @Output() valueChange = new EventEmitter<string>();

    loaded = false;

    config:any = {
        height: '108px',
        toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['para', ['ul', 'ol', 'paragraph']],
        ]
    };

    constructor() { 
    }

    ngOnInit() {
        this.config = { 
            ...this.config, 
            placeholder: this.placeholder 
        };  

        setTimeout(() => this.loaded = true, 1);
    }

    onChange(newContent) {
        this.valueChange.emit(newContent);
    }
}
