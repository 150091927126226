<div *ngIf="activityQa.items" class="qa-container">
    <h2 class="title">Questions</h2>
    <ng-container *ngFor="let question of activityQa.items; let i = index">
        <div class="question-container">
            <span class="index-number">{{ i + 1 }} <b *ngIf="i < 3">*</b></span>
            <app-textarea
                placeholder="Write your statement and question here. e.g.: Your muscles get tight and your heart beats faster. Which emotion could you be feeling?"
                maxChars="180"
                data-cy="question-input"
                [(value)]="question.question"
                (maxCharsReached)="maxCharsValidation($event, 'question-input-' + i)"
                [error]="i < 3 && emptyMandatoryFields.includes('question' + i) ? 'This field is required.' : ''"
            >
            </app-textarea>
        </div>
        <hr>
    </ng-container>
    <div class="auto-check-section">
        <app-textarea
            label="Review Prompt"
            maxChars="180"
            data-cy="review-prompt-input"
            [placeholder]="'Big picture concepts you want your students to think about, or activities to do once the worksheet is completed.'
            + '&#10;e.g.: Our emotions change all the time. Recognizing them using our body and thoughts helps us feel in control.'"
            [(value)]="activityQa.reviewPrompt"
            [error]="emptyMandatoryFields.includes('reviewPrompt') ? 'This field is required.' : ''"
            (maxCharsReached)="maxCharsValidation($event, 'review-prompt-qa')"
            [isRequired]="true"
        >
        </app-textarea>
    </div>
</div>