import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityMultipleChoiceComponent } from './activity-multiple-choice/activity-multiple-choice.component';
import { ActivityQaComponent } from './activity-qa/activity-qa.component';
import { ActivityFillBlanksComponent } from './activity-fill-blanks/activity-fill-blanks.component';
import { ActivitySpinnerComponent } from './activity-spinner/activity-spinner.component';
import { ActivityMatchingComponent } from './activity-matching/activity-matching.component';
import { ActivitySortingComponent } from './activity-sorting/activity-sorting.component';
import { ActivityFlashcardsComponent } from './activity-flashcards/activity-flashcards.component';
import { ActivityDiceRollerComponent } from './activity-dice-roller/activity-dice-roller.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { TagfieldModule } from 'src/app/shared/form/tagfield/tagfield.module';
import { TextareaModule } from 'src/app/shared/form/textarea/textarea.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { WtdSectionModule } from 'src/app/shared/form/wtd-section/wtd-section.module';
import { MultiSelectModule } from 'src/app/shared/form/multi-select/multi-select.module';
import { RichtextareaModule } from 'src/app/shared/form/richtextarea/richtextarea.module';
import { ListItemPickerModule } from 'src/app/shared/form/list-item-picker/list-item-picker.module';
import { ContentActivityModule } from '../../content/content-editor/content-activity/content-activity.module';
import { InteractiveActivitiesEditorComponent } from './interactive-activities-editor.component';
import { InteractiveLinkContentModule } from 'src/app/shared/interactive-link-content/interactive-link-content.module';
import { TooltipModule } from 'src/app/shared/tooltip/tooltip.module';


@NgModule({
    declarations: [
        InteractiveActivitiesEditorComponent,
        ActivityMultipleChoiceComponent,
        ActivityQaComponent,
        ActivityFillBlanksComponent,
        ActivitySpinnerComponent,
        ActivityMatchingComponent,
        ActivitySortingComponent,
        ActivityFlashcardsComponent,
        ActivityDiceRollerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RouterModule,
        SelectModule,
        ButtonModule,
        TagfieldModule,
        TextareaModule,
        TextfieldModule,
        WtdSectionModule,
        MultiSelectModule,
        RichtextareaModule,
        ListItemPickerModule,
        ContentActivityModule,
        InteractiveLinkContentModule,
        TooltipModule
    ]
})
export class InteractiveActivitiesEditorModule { }
