import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Tooltip } from 'src/app/models/tooltip/tooltip.model';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {
    @Input() config: Tooltip;

    @Output() onToggle = new EventEmitter<boolean>();

    @ViewChild('tooltipContainer') tooltipContainer;

    active = false;
    size: "medium" | "large" | "extra-large";
    position: "left" | "right";

    ngOnInit(): void {
        this.size = this.config?.size || "medium";
        this.position = this.config?.position || "right";
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLDivElement): void {
        if (
            this.active &&
            !this.tooltipContainer.nativeElement.contains(targetElement)
        ) {
            this.active = false;
            this.onToggle.emit(this.active);
        }
    }

    toggle(): void {
        this.active = !this.active;
        this.onToggle.emit(this.active);
    }
}
