import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivitySorting, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
    selector: 'app-activity-sorting',
    templateUrl: './activity-sorting.component.html',
    styleUrls: ['./activity-sorting.component.scss']
})
export class ActivitySortingComponent implements OnInit {

    @Input() activitySorting: InteractiveActivitySorting;
    @Input() emptyMandatoryFields = Array<string>();
   
    @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

    constructor() { }

    ngOnInit(): void {
        if (Object.keys(this.activitySorting).length === 0) {
            this.activitySorting.items = [];
            this.activitySorting.items = Array.from({length: 6}, () => ({left: '', right: ''}))
            this.activitySorting.isAutoCheck = true;
            this.activitySorting.leftCategoryTitle = '';
            this.activitySorting.rightCategoryTitle = '';
        } else {
            if (this.activitySorting.items.length < 6) {
                let activityLength = this.activitySorting.items.length;
                let necessaryItems = Array.from({length: (6 - activityLength)}, () => ({left: '', right: ''}));
                this.activitySorting.items = [...this.activitySorting.items, ...necessaryItems];
            }
        }
    }

    maxCharsValidation(isMaxReached: boolean, field: string) {
        this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field });
    }

}
