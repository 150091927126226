import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './list-view.component';
import { SpinnerModule } from '../../spinner/spinner.module';

@NgModule({
    declarations: [
        ListViewComponent
    ],
    imports: [
        CommonModule,
        SpinnerModule
    ],
    exports: [
        ListViewComponent
    ]
})
export class ListViewModule { }