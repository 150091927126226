import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { SelLessonService } from 'src/app/services/sel-lesson.service';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { SelLessonList } from 'src/app/models/sel-lesson/sel-lesson-list.model';

@Component({
    selector: 'app-sel-lessons-list',
    templateUrl: './sel-lessons-list.component.html',
    styleUrls: ['./sel-lessons-list.component.scss']
})
export class SelLessonsListComponent implements OnInit {
    headers = [
        { label: "Title", key: "title", sortable: true },
        { label: "Age", key: "ageTitle", sortable: true },
        { label: "Sel Unit", key: "unitTitle", sortable: true },
        { label: "Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    lessonsList: SelLessonList;
    sortingParams: { key?: string, asc?: boolean };

    constructor(
        private router: Router,
        private authService: AuthService,
        private dialogService: DialogService,
        private lessonService: SelLessonService
    ) { }

    ngOnInit(): void {
        this.loadList();
    }

    loadList(hideLoader?: boolean): void {
        var params: ListParams = { page: this.currentPage };
        if (this.searchTerm)
            params.text = this.searchTerm;

        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.lessonService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                this.lessonsList = response;
            },
                errors => {
                    this.sorting = false;
                    this.loading = false;
                    this.dialogService.showErrorAlert(formatError(errors));
                    if (errors.status === 401) {
                        this.authService.clearSession();
                        this.router.navigate(['/login']);
                    }
                }
            );
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params: { key: string, asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }
}
