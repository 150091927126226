<div class="{{inlineLabel ? 'inline' : ''}}">
    <div class="label-container">
        <label *ngIf="label" data-cy="text-field-label">{{label}} <b *ngIf="isRequired">*</b></label>
        <span *ngIf="maxChars" [ngClass]="{'error-message': isMaxCharsReached}">Characters: {{
            inputTextLength }}/{{
            maxChars }}</span>
    </div>
    <div class="input-container">
        <img *ngIf="iconUrl && !iconLinkUrl" src="{{iconUrl}}" class="img-no-link" />
        <a *ngIf="iconUrl && iconLinkUrl" [routerLink]="iconLinkUrl" target="_blank"><img src="{{iconUrl}}" /></a>
        <input [type]="type" [disabled]="disabled" [readonly]="readonly" (input)="onChange($event)"
            [placeholder]="placeholder" [ngClass]="[feedback || '', size, error ? 'error' : '']"
            [ngStyle]="inputPaddingRight && {'padding-right': inputPaddingRight}"
            [style.backgroundColor]="inputBackgroundColor"
            [value]="type === 'date' || type === 'datetime-local' ? getDate(value) : value"
            (keyup)="maxChars && validateMaxChars($event.target.value.length)"
            (blur)="validateRequired($event.target.value.length)" data-cy="input-field" />
    </div>
    <div class="error-message" *ngIf="error">{{error}}</div>

</div>