<div #tooltipContainer class="eds-tooltip">
    <div class="tooltip-toggle" (mouseenter)="config.isHoverEnabled && active = true"
        (mouseleave)="config.isHoverEnabled && active = false" (click)="!config.isHoverEnabled && toggle()">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path [attr.fill]="active ? '#000610' : '#001233'" fill-rule="evenodd" clip-rule="evenodd"
                d="M6.33592 4.33439C6.33592 3.96639 6.63459 3.66772 7.00259 3.66772C7.37059 3.66772 7.66926 3.96639 7.66926 4.33439C7.66926 4.70239 7.37059 5.00106 7.00259 5.00106C6.63459 5.00106 6.33592 4.70239 6.33592 4.33439ZM6.33592 6.3339C6.33592 5.9659 6.63459 5.66724 7.00259 5.66724C7.37059 5.66724 7.66926 5.9659 7.66926 6.3339V9.66724C7.66926 10.0352 7.37059 10.3339 7.00259 10.3339C6.63459 10.3339 6.33592 10.0352 6.33592 9.66724V6.3339ZM7.00258 12.3337C4.06192 12.3337 1.66925 9.94099 1.66925 7.00033C1.66925 4.05966 4.06192 1.66699 7.00258 1.66699C9.94325 1.66699 12.3359 4.05966 12.3359 7.00033C12.3359 9.94099 9.94325 12.3337 7.00258 12.3337ZM7.0026 0.333984C3.3206 0.333984 0.335938 3.31865 0.335938 7.00065C0.335938 10.6827 3.3206 13.6673 7.0026 13.6673C10.6839 13.6673 13.6693 10.6827 13.6693 7.00065C13.6693 3.31865 10.6839 0.333984 7.0026 0.333984Z" />
        </svg>
    </div>
    <div *ngIf="active" class="tooltip-content" [ngClass]="[size, position]">
        <svg viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: absolute; width: 15px; height: 20px; font-size: 15px">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.0026 6.00065C7.6346 6.00065 7.33594 5.70198 7.33594 5.33398C7.33594 4.96598 7.6346 4.66732 8.0026 4.66732C8.3706 4.66732 8.66927 4.96598 8.66927 5.33398C8.66927 5.70198 8.3706 6.00065 8.0026 6.00065ZM8.66927 10.6673C8.66927 11.0353 8.3706 11.334 8.0026 11.334C7.6346 11.334 7.33594 11.0353 7.33594 10.6673V7.33398C7.33594 6.96598 7.6346 6.66732 8.0026 6.66732C8.3706 6.66732 8.66927 6.96598 8.66927 7.33398V10.6673ZM8.0026 1.33398C4.3206 1.33398 1.33594 4.31865 1.33594 8.00065C1.33594 11.6827 4.3206 14.6673 8.0026 14.6673C11.6839 14.6673 14.6693 11.6827 14.6693 8.00065C14.6693 4.31865 11.6839 1.33398 8.0026 1.33398Z"
                fill="white" />
        </svg>
        <div class="content-text-section">
            <div class="title">{{config.title}}</div>
            <div class="content" [innerHTML]="config.content"></div>
        </div>
    </div>
</div>