import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UnitService } from 'src/app/services/unit.service';
import { AuthService } from 'src/app/services/auth.service';
import { UnitList } from 'src/app/models/unit/unit-list.model';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { ListParams } from 'src/app/models/list-params/list-params.model';

@Component({
    selector: 'app-units-list',
    templateUrl: './units-list.component.html',
    styleUrls: ['./units-list.component.scss']
})
export class UnitsListComponent implements OnInit {
    headers = [
        { label:"Unit No", key:"unitNumber", sortable: true },
        { label:"Unit Title", key:"title", sortable: true },
        { label:"Age", key:"ageTitle", sortable: true },
        { label:"Goal", key:"goalTitle", sortable: true },
        { label:"Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    unitsList:UnitList;
    sortingParams:{ key?:string, asc?:boolean };
    
    constructor(
        private router:Router,
        private authService:AuthService,
        private unitService:UnitService,
        private dialogService:DialogService,
    ) { }

    ngOnInit():void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:ListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.unitService.listSc(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                this.unitsList = response;
            }, 
            errors => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors));
                if (errors.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }
}
