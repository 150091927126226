<header>
    <h1 class="title">
        <ng-content></ng-content>
    </h1>
    <div>
        <div class="notifications"></div>
        <div #userOptions class="user-options" [ngClass]="opened ? 'opened' : ''">
            <button (click)="toggleOptions()" class="dropdown-toggle-btn" data-cy="user-options-toolbar">
                <div class="thumb">
                    <img src="../../../assets/images/icons/user.svg" alt="user" />
                </div>
                <div class="user">
                    <div class="role">{{user?.role}}</div>
                    <div class="email">{{user?.username}}</div>
                </div>
            </button>
            <div class="dropdown">
                <button (click)="logout()" data-cy="user-options-logout-btn">Logout</button>
            </div>
        </div>
    </div>
</header>
