<div *ngIf="activitySorting.items" class="sorting-container">
    <h2 class="title">Category Title *</h2>
    <div class="question-container category-title">
        <app-textarea placeholder="e.g.: Joining a Group" [(value)]="activitySorting.leftCategoryTitle" maxChars="30"
            [error]="emptyMandatoryFields.includes('leftCategoryTitle') ? 'This field is required.' : ''"
            (maxCharsReached)="maxCharsValidation($event, 'left-title')"
            data-cy="category-reason-input"
        ></app-textarea>
        <app-textarea placeholder="e.g.: What to say or do to join in”" [(value)]="activitySorting.rightCategoryTitle"
            maxChars="30"
            [error]="emptyMandatoryFields.includes('rightCategoryTitle') ? 'This field is required.' : ''"
            (maxCharsReached)="maxCharsValidation($event, 'right-title')"
            data-cy="category-question-input"
        ></app-textarea>
    </div>
    <hr>
    <h2 class="title">Column Items</h2>
    <ng-container *ngFor="let question of activitySorting.items; let i = index">
        <div class="question-container">
            <span class="index-number">{{ i + 1 }} <b *ngIf="i == 0">*</b></span>
            <app-textarea placeholder="e.g. Saying Hello"
                [(value)]="question.left"
                maxChars="38"
                [error]="emptyMandatoryFields.includes('left' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'left-' + i)"
                data-cy="left-input"
            ></app-textarea>
            <app-textarea
                placeholder="Saying Hello and introducing yourself"
                [(value)]="question.right" maxChars="38"
                [error]="emptyMandatoryFields.includes('right' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'right-i' + i)"
                data-cy="right-input"
            ></app-textarea>
        </div>
        <hr>
    </ng-container>
    <div class="auto-check-section">
        <h3>Auto-check Feature</h3>
        <app-switch id="active" activeLabel="Active" [(value)]="activitySorting.isAutoCheck"></app-switch>
        <p>By default, answers will be marked as correct and incorrect after user completes activity. If there are more
            than one possible solutions, please mark the inactivate the auto-check feature.</p>
    </div>
</div>
