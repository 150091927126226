import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Content } from 'src/app/models/content/content.model';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { MiniGame } from 'src/app/models/mini-games/mini-game.model';
import { MiniGameService } from 'src/app/services/mini-game.service';
import { HttpService } from 'src/app/services/http.service';
import { ContentTypeService } from 'src/app/services/content-type.service';

@Component({
    selector: 'app-mini-games-editor',
    templateUrl: './mini-games-editor.component.html',
    styleUrls: ['./mini-games-editor.component.scss']
})
export class MiniGamesEditorComponent {
    miniGame:MiniGame = {
        title: "",
        configFile: "",
        configType: "json",
        contentDetail: {
            contentId: "", url: "", type: "", title: "", releaseDate: "" 
        }
    };
    availableConfigTypes = [
        { id: "json", label: "JSON", },
        { id: "xml", label: "XML", },
    ];
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    gameTypeId:string;
    emptyMandatoryFields = Array<string>();
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private httpService:HttpService, 
        private dialogService:DialogService,
        private miniGameService:MiniGameService,
        private contentTypeService:ContentTypeService, 
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;
            
            this.loadContentType();
            if (data.gameId === "new") {
                this.loading = false;
                return;
            }

            this.load(data.gameId);
        });
    }

    load(miniGameId:string) {
        this.miniGameService.get(miniGameId)
            .subscribe(response => {
                this.loading = false;
                this.miniGame = response.miniGame;

                if (!this.miniGame.tags)
                    this.miniGame.tags = [];

                if (!this.miniGame.configType)
                    this.miniGame.configType = "json";
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    loadContentType() {
        this.contentTypeService.get("game")
            .subscribe(response => {
                this.gameTypeId = response.contentType.contentTypeId
            });
    }

    getMediaReadyForUpload():Promise<any> {
        if (!this.miniGame.thumbnailFile)
            return Promise.resolve();
        
        return this.createPromiseForMedia(this.miniGame.thumbnailFile, "image")
            .then(url => { 
                var link = url;
                this.miniGame.thumbnail = link;
                this.miniGame.thumbnailFile = null;

                return url;
            });
    }
    
    createPromiseForMedia(file:File, type:"video"|"image"|"file"):Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url), 
                    error => reject(error)
                );
        });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        this.getMediaReadyForUpload()
            .then(() => {
                this.miniGameService.save(this.miniGame).subscribe(response => {
                    this.saving = false;
                    this.dialogService.showSuccessAlert("Save Successful.");
                    if (response)
                        this.router.navigate(["/mini-games/" + response.miniGameId]);
                }, errors => {
                    this.saving = false;
                    this.dialogService.showErrorAlert(formatError(errors?.error));
                });
            });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.miniGame.contentId)
            this.emptyMandatoryFields.push("content");
        if (!this.miniGame.title)
            this.emptyMandatoryFields.push("title");
        if (!this.miniGame.configFile)
            this.emptyMandatoryFields.push("configFile");
        if (!this.miniGame.thumbnailFile && !this.miniGame.thumbnail)
            this.emptyMandatoryFields.push("thumbnail");
        
        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
        
        return this.emptyMandatoryFields.length <= 0;
    }

    delete() {
        this.deleting = true;
        this.miniGameService.delete(this.miniGame.miniGameId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/mini-games"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.miniGame.title + " mini-game from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    removeContent() {
        this.miniGame.contentDetail = {
            url: "", 
            type: "", 
            title: "", 
            contentId: "", 
            releaseDate: "" 
        };
        this.miniGame.contentId = undefined;
    }

    onContentChange($event:Content) {
        if (!$event) {
            this.miniGame.contentDetail = {
                url: "", 
                type: "", 
                title: "", 
                contentId: "", 
                releaseDate: "" 
            };
            this.miniGame.contentId = undefined;
            return;
        }
        
        this.miniGame.contentId = $event.contentId;
        this.miniGame.contentDetail.url = $event.url;
        this.miniGame.contentDetail.type = $event.type;
        this.miniGame.contentDetail.title = $event.title;
        this.miniGame.contentDetail.contentId = $event.contentId;
    }

    goBack() {
        this.router.navigate(["/mini-games"]);
    }
}
