import { 
    OnInit, 
    Component, 
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { StorageService } from '../../services/storage.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    
    public user = { password: '', email: '' };
    
    readonly currYear = (new Date()).getFullYear();

    loading:boolean;
    emptyMandatoryFields = Array<string>();

    constructor(
        private router:Router,
        private auth:AuthService, 
        private storage:StorageService,
        private dialogService:DialogService,
    ) { }


    ngOnInit(): void {
        if (this.auth.isAuthenticated())
            this.router.navigate(['']);
    }

    tryLogin($ev) {
        $ev.preventDefault();
        this.loading = true;

        setTimeout(() => {
            this.emptyMandatoryFields = [];
            if (!this.user.email)
                this.emptyMandatoryFields.push("email");
            if (!this.user.password)
                this.emptyMandatoryFields.push("password");

            if (this.emptyMandatoryFields.length > 0) {
                this.loading = false;
                return;
            }

            this.auth.login({
                password: this.user.password,
                email: this.user.email
            }).subscribe((data) => {
                this.loading = false;
                this.storage.setToken(data.sessionToken);
                this.router.navigate(['']);
            },
            errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
            });
        }, 400);
    }
}