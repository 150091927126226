import { NgModule } from '@angular/core';
import { ListPaginatorComponent } from './list-paginator.component';
import { TextfieldModule } from '../../form/textfield/textfield.module';

@NgModule({
    declarations: [
        ListPaginatorComponent
    ],
    imports: [
        TextfieldModule,
    ],
    exports: [
        ListPaginatorComponent
    ]
})
export class ListPaginatorModule { }