<div class="link-content-container">
    <app-button class="pdf-btn" type="primary" iconUrl="../../../../assets/images/icons/print-machine.svg" (click)="downloadFile()">
        Download PDF
    </app-button>
    <div class="link-section">
        <div class="copy-section">
            <app-textfield 
                readonly="readonly"
                label="Link to Interactive content"
                [value]="urlLink"
                inputPaddingRight="90px"
            >
            </app-textfield>
            <app-button type="primary">
                <copy-to-clipboard [value]="urlLink">
                    COPY
                </copy-to-clipboard>
            </app-button>
        </div>
        <app-button class="open-btn" type="primary" iconUrl="../../../../assets/images/icons/globe.svg" (click)="openLink(urlLink)">
            Open
        </app-button>
    </div>
</div>