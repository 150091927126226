import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextfieldComponent } from './textfield.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        TextfieldComponent
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        TextfieldComponent
    ]
})
export class TextfieldModule { }