import { Component } from '@angular/core';
import { Goal } from 'src/app/models/goal/goal.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { PlanService } from 'src/app/services/plan.service';
import { GoalService } from 'src/app/services/goal.service';
import { UnitService } from 'src/app/services/unit.service';
import { LevelService } from 'src/app/services/level.service';
import { Content } from 'src/app/models/content/content.model';
import { DialogService } from 'src/app/services/dialog.service';
import { ContentService } from 'src/app/services/content.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { isEmptyObject } from 'src/app/utils/emptyObject.validator';
import { WhatToDoService } from 'src/app/services/what-to-do.service';
import { WhatToDo } from 'src/app/models/what-to-do/what-to-do.model';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { ContentTypeService } from 'src/app/services/content-type.service';
import { WhatToDoItem } from 'src/app/models/what-to-do/what-to-do-item.model';

@Component({
    selector: 'app-content-editor',
    templateUrl: './content-editor.component.html',
    styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent {
    content: Content = {
        url: "",
        type: "",
        title: "",
        goals: [],
        active: true,
        contentId: "",
        releaseDate: new Date().toISOString(),
    };
    activeTab = 0;
    saving: boolean;
    loading: boolean;
    deleting: boolean;
    contentTypeSlug: string;
    availableGoals: Array<Goal>;
    emptyMandatoryFields = Array<string>();
    availablePlans: Array<{ id: string, label: string }> = [];
    availableLevels: Array<{ id: string, label: string }> = [];
    availableSccAges: Array<{ id: string, label: string }> = [];
    availableSelAges: Array<{ id: string, label: string }> = [];
    availableSelUnits: Array<{ id: string, label: string }> = [];
    availableCurriculums: Array<{ id: string, label: string, slug: string }> = [];
    availableGoalsGrouped: Array<{
        header: string,
        options: Array<{ id: string, label: string }>
    }> = [];

    private scCurriculumId;
    private selCurriculumId;

    readonly wtdFieldsCount = 5;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ageService: AgeTrackService,
        private httpService: HttpService,
        private planService: PlanService,
        private goalService: GoalService,
        private unitService: UnitService,
        private authService: AuthService,
        private levelService: LevelService,
        private dialogService: DialogService,
        private contentService: ContentService,
        private whatToDoService: WhatToDoService,
        private curriculumService: CurriculumService,
        private contentTypeService: ContentTypeService,
    ) {
        this.route.params.subscribe(data => {
            this.loading = true;
            this.contentTypeSlug = data.contentType;
            this.loadAvailableCurriculums(data.contentId);
            this.loadAvailableGoals();
            this.loadAvailableLevels();
            this.loadAvailableSelUnits();
        });
    }

    loadAvailableCurriculums(contentId: string) {
        if (contentId === "new") {
            this.loading = false;

            this.loadContentTypes();
        }

        this.curriculumService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableCurriculums = response.curriculums.map(c => {
                    return {
                        slug: c.slug,
                        label: c.title,
                        id: c.curriculumId,
                    };
                });

                this.scCurriculumId = response.curriculums
                    .find(c => c.slug === environment.scCurriculumSlug).curriculumId;
                this.selCurriculumId = response.curriculums
                    .find(c => c.slug === environment.selCurriculumSlug).curriculumId;

                this.loadAvailableAges();
                if (contentId === "new" && this.availableCurriculums.length > 0) {
                    this.content.curriculums = [this.scCurriculumId];
                    this.loadAvailablePlans(contentId);
                } else {
                    this.load(contentId);
                }
            }, errors => {
                if (errors.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                } else {
                    this.goBack();
                }
            });
    }

    loadContentTypes() {
        this.contentTypeService.get(this.contentTypeSlug)
            .subscribe(response => {
                this.content.typeId = response.contentType.contentTypeId;
            });
    }

    loadAvailablePlans(contentId?: string) {
        this.planService.list({ limit: 1000 })
            .subscribe(response => {
                this.availablePlans = response.plans.map(c => {
                    return { id: c.planId, label: c.title };
                });

                if (contentId === "new" && this.availablePlans.length > 0) {
                    this.content.plans = [this.availablePlans[0].id];

                    this.content.sccToDo = {
                        parent: "Content",
                        parentId: contentId,
                        curriculumId: this.scCurriculumId,
                        items: []
                    };
                }

                this.loading = false;
            });
    }

    loadAvailableAges() {
        var sccId = this.availableCurriculums
            .find(c => c.label.indexOf("Social Communication") > -1).id;
        var selId = this.availableCurriculums
            .find(c => c.label.indexOf("Social Emotional") > -1).id;

        this.ageService.list({ limit: 1000, curriculum: sccId })
            .subscribe(response => {
                this.availableSccAges = response.ages.map(a => {
                    return { id: a.ageId, label: a.title };
                });
            });

        this.ageService.list({ limit: 1000, curriculum: selId })
            .subscribe(response => {
                this.availableSelAges = response.ages.map(a => {
                    return { id: a.ageId, label: a.title };
                });
            });
    }

    loadAvailableGoals() {
        this.goalService.list({ limit: 1000 })
            .subscribe(response => {
                var groupedGoals = [];
                this.availableGoals = response.goals;

                response.goals.forEach(g => {
                    var groupFound = groupedGoals
                        .find(gg => gg.header === g.skill?.title);
                    if (groupFound) {
                        groupFound.options.push({ id: g.goalId, label: g.title });
                    } else {
                        groupedGoals.push({
                            order: g.skill?.order,
                            header: g.skill?.title,
                            options: [{ id: g.goalId, label: g.title }]
                        });
                    }
                });

                groupedGoals = groupedGoals
                    .sort((a, b) => { return a.order > b.order ? 1 : -1 });
                this.availableGoalsGrouped = groupedGoals;
            });
    }

    loadAvailableSelUnits() {
        this.unitService.listSel({ limit: 1000 })
            .subscribe(response => {
                this.availableSelUnits = response.units.map(u => {
                    return { id: u.unitId, label: u.title };
                });;
            });
    }

    loadAvailableLevels() {
        this.levelService.list({ limit: 1000 })
            .subscribe(response => {
                this.availableLevels = response.levels.map(l => {
                    return { id: l.levelId, label: l.title };
                });
            });
    }

    load(contentId: string) {
        this.contentService.get(contentId)
            .subscribe(response => {
                this.content = response.content;
                if (!this.content.goals)
                    this.content.goals = [];
                if (!this.content.tags)
                    this.content.tags = [];
                if (!this.content.subtitles)
                    this.content.subtitles = [{ url: "", language: "English" }];
                if (!this.content.curriculums)
                    this.content.curriculums = [];

                if (!this.content.plans)
                    this.content.plans = [];
                else
                    this.content.plans = this.content.plans.filter(p => p);

                if (!this.content.sccToDo) {
                    this.content.sccToDo = {
                        parent: "Content",
                        parentId: contentId,
                        curriculumId: this.scCurriculumId,
                        items: []
                    }
                }

                this.loadAvailablePlans();
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    allFieldsAreValid(): boolean {
        this.emptyMandatoryFields = [];
        if (!this.content.title)
            this.emptyMandatoryFields.push("title");
        if (!this.content.releaseDate)
            this.emptyMandatoryFields.push("releaseDate");
        if (!this.content.curriculums || this.content.curriculums.length <= 0)
            this.emptyMandatoryFields.push("curriculums");
        if (!this.content.plans || this.content.plans.length <= 0)
            this.emptyMandatoryFields.push("plans");
        if (!this.content.file && !this.content.url)
            this.emptyMandatoryFields.push("file");
        if (this.contentTypeSlug === "edsw" || this.contentTypeSlug === "game") {
            if (!this.content.url)
                this.emptyMandatoryFields.push("url");
            if (!this.content.thumbnailFile && !this.content.thumbnail)
                this.emptyMandatoryFields.push("thumbnail");
        }

        const sccCurriculumId = this.availableCurriculums
            .find(c => c.slug === environment.scCurriculumSlug).id;
        const selCurriculumId = this.availableCurriculums
            .find(c => c.slug === environment.selCurriculumSlug).id;

        if (this.content.curriculums.includes(sccCurriculumId)) {
            if (!this.content.sccAges?.length)
                this.emptyMandatoryFields.push("sccAges");
        } else {
            this.content.sccAges = [];
        }

        if (this.content.curriculums.includes(selCurriculumId)) {
            if (!this.content.selAges?.length)
                this.emptyMandatoryFields.push("selAges");
        } else {
            this.content.selAges = [];
        }

        if (this.emptyMandatoryFields.length > 0) {
            if (
                this.emptyMandatoryFields[0] === "sccAges"
            ) {
                this.activeTab = 1;
            } else if (
                this.emptyMandatoryFields[0] === "selAges"
            ) {
                this.activeTab = 2;
            } else {
                this.activeTab = 0;
            }

            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    allWhatToDoAreValid(whatToDoArray: WhatToDoItem[], identifier: "sel" | "sc"): boolean {
        whatToDoArray.forEach((item, i) => {
            if (!item.type)
                return;

            var id = "wtd-" + identifier + "-" + i;
            if (!item.text)
                this.emptyMandatoryFields.push(id + "-text");

            if ((
                item.type === "worksheet" || item.type === "download" ||
                item.type === "workbook" || item.type === "visualTool"
            ) && !item.file && !item.url
            ) {
                this.emptyMandatoryFields.push(id + "-pdf");
            }

            if (item.type === "video") {
                if (!item.title)
                    this.emptyMandatoryFields.push(id + "-title");

                if (!item.file && !item.url)
                    this.emptyMandatoryFields.push(id + "-video");
            }

            if (item.type === "workbook") {
                if (!item.secondaryUrl)
                    this.emptyMandatoryFields.push(id + "-secondaryUrl");
            }

            if (item.type === "content") {
                if (!item.childId)
                    this.emptyMandatoryFields.push(id + "-childId");
            }
        });

        if (this.emptyMandatoryFields.length > 0) {
            if (this.emptyMandatoryFields[0].indexOf("wtd-sc") >= 0) {
                this.activeTab = 1;
            } else if (this.emptyMandatoryFields[0].indexOf("wtd-sel") >= 0) {
                this.activeTab = 2;
            }

            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload(): Promise<string>[] {
        var promises: Promise<string>[] = [];
        if (this.content.file) {
            var mediaType;
            if (this.contentTypeSlug === "video")
                mediaType = "video";
            if (this.contentTypeSlug === "activity")
                mediaType = "file";

            promises.push(
                this.createPromiseForMedia(this.content.file, mediaType)
                    .then(r => {
                        var link = r.secure_url;
                        this.content.url = link;
                        this.content.file = null;
                        this.content.thumbnail = link.replace(/\.[^\.]+$/, ".png");
                        if (mediaType === "video")
                            this.content.duration = r.duration;
                        if (mediaType === "file")
                            this.content.pages = r.pages;

                        return r;
                    })
            );
        }

        if (this.content.thumbnailFile) {
            promises.push(
                this.createPromiseForMedia(this.content.thumbnailFile, "image")
                    .then(r => {
                        this.content.thumbnail = r.secure_url;
                        this.content.thumbnailFile = null;
                        return r.secure_url;
                    })
            );
        }

        if (this.content.subtitles && this.content.subtitles[0]?.file) {
            promises.push(
                this.createPromiseForMedia(this.content.subtitles[0].file, "file")
                    .then(r => {
                        this.content.subtitles[0].url = r.secure_url;
                        this.content.subtitles[0].file = null;
                        return r.secure_url;
                    })
            );
        }

        if (this.content.skillReviewPdfFile) {
            promises.push(
                this.createPromiseForMedia(this.content.skillReviewPdfFile, "file")
                    .then(data => {
                        this.content.skillReviewFile = data.url;
                        this.content.skillReviewPdfFile = null;
                        this.content.skillReviewThumbnail = data.url.replace('.pdf', '.png');
                        return data;
                    })
            );
        }

        return promises;
    }

    getWhatToDoMediaReadyForUpload(whatToDoArray: WhatToDoItem[]): Promise<string>[] {
        var promises: Promise<string>[] = [];
        whatToDoArray.forEach(w => {
            if (
                w.type === "text" || w.type === "content" ||
                w.type === "interactiveactivity" || w.type === "minigame"
            ) {
                return;
            }

            if ((
                w.type === "worksheet" ||
                w.type === "download" ||
                w.type === "workbook"
            ) && w.file
            ) {
                promises.push(
                    this.createPromiseForMedia(w.file, "file")
                        .then(r => setResponse(w, r.secure_url))
                );
            } else if (w.type === "video" && w.file) {
                promises.push(
                    this.createPromiseForMedia(w.file, "video")
                        .then(r => setResponse(w, r.secure_url))
                );
            } else if (w.type === "visualTool") {
                if (w.file) {
                    promises.push(
                        this.createPromiseForMedia(w.file, "file")
                            .then(r => setResponse(w, r.secure_url))
                    );
                }

                if (w.thumbnailFile) {
                    promises.push(
                        this.createPromiseForMedia(w.thumbnailFile, "image")
                            .then(r => {
                                w.thumbnail = r.secure_url;
                                w.thumbnailFile = null;
                                return r.secure_url;
                            })
                    );
                }
            }
        });

        function setResponse(whatToDo: WhatToDoItem, url: string): string {
            whatToDo.url = url;
            whatToDo.file = null;
            whatToDo.thumbnail = url.replace(/\.[^\.]+$/, ".png");
            return url;
        }

        return promises;
    }

    createPromiseForMedia(file: File, type: "video" | "image" | "file"): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r),
                    error => reject(error)
                );
        });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        if (!this.allWhatToDoAreValid(this.content.sccToDo.items, "sc"))
            return;

        this.saving = true;
        Promise.all(this.getMediaReadyForUpload())
            .then(() => this.save());
    }

    saveWhatToDos(redirectAfterSave?: boolean) {
        var scPromises = this.getWhatToDoMediaReadyForUpload(this.content.sccToDo.items);
        Promise.all(scPromises).then(() => {
            var emptySccToDo = !this.content.sccToDo ||
                this.content.sccToDo.items.length <= 0 ||
                this.content.sccToDo.items.every(w => isEmptyObject(w));

            if (emptySccToDo) {
                onSave();
            } else {
                this.whatToDoService
                    .save(this.content.sccToDo)
                    .subscribe(
                        onSave,
                        errors => this.handleSaveErrors(errors)
                    );
            }
        });

        const onSave = () => {
            this.saving = false;
            this.dialogService.showSuccessAlert("Save Successful.");

            if (redirectAfterSave) {
                this.router.navigate([
                    `/contents/${this.contentTypeSlug}/${this.content.contentId}`
                ]);
            }
        }
    }

    save() {
        this.contentService.save(this.content).subscribe(response => {
            if (response) {
                this.content.contentId = response.contentId;
                this.content.sccToDo.parentId = response.contentId;
            }

            this.saveWhatToDos(response?.contentId);
        }, errors => this.handleSaveErrors(errors));
    }


    handleSaveErrors(errors: any) {
        this.saving = false;
        this.dialogService.showErrorAlert(formatError(errors?.error));
    }

    getGoal(goalId: string) {
        return this.availableGoals.find(g => g.goalId === goalId)?.title;
    }

    getUnit(unitId: string) {
        return this.availableSelUnits.find(u => u.id === unitId)?.label;
    }

    goBack() {
        this.router.navigate(["/"]);
    }

    delete() {
        this.deleting = true;
        this.contentService.delete(this.content.contentId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/contents"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " +
                this.content.title + " content from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    onSccToDoChange($event: WhatToDo) {
        this.content.sccToDo.items = $event.items;
    }
}
