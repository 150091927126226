import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {  }
    
    getItem(name) {
        if (isPlatformBrowser(this.platformId))
            return localStorage.getItem(name);
    }
       
    setItem(name, item){
        if (isPlatformBrowser(this.platformId))
            localStorage.setItem(name, item);
    }
    
    removeItem(name){
        if (isPlatformBrowser(this.platformId))
            localStorage.removeItem(name);
    }

    getToken() {
        if (isPlatformBrowser(this.platformId))
            return localStorage.getItem("@cms-sessionToken");
    }

    setToken(item) {
        if (isPlatformBrowser(this.platformId))
            return localStorage.setItem("@cms-sessionToken", item);
    }

    removeToken() {
        if (isPlatformBrowser(this.platformId))
            return localStorage.removeItem("@cms-sessionToken");
    }
}