import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Content } from 'src/app/models/content/content.model';

@Component({
    selector: 'content-edsw',
    templateUrl: './content-edsw.component.html',
    styleUrls: ['./content-edsw.component.scss']
})
export class ContentEdswComponent {
    @Input() content:Content;
    @Input() emptyMandatoryFields = Array<string>();
    
    @Output() contentChange = new EventEmitter<Content>();

    availableOrders = [
        { id: "1", label: "1" }
    ];
    constructor() { }
}
