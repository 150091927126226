import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { MiniGameService } from 'src/app/services/mini-game.service';
import { ListParams } from 'src/app/models/list-params/list-params.model';
import { MiniGameList } from 'src/app/models/mini-games/mini-game-list.model';

@Component({
  selector: 'app-mini-games-list',
  templateUrl: './mini-games-list.component.html',
  styleUrls: ['./mini-games-list.component.scss']
})
export class MiniGamesListComponent implements OnInit {
    headers = [
        { label:"Name", key:"title", sortable: true },
        { label:"Parent Game", key:"contentTitle", sortable: true },
        { label:"Date Created", key:"createdAt", sortable: true },
        { label:"Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    deletingMiniGameId:string;
    miniGamesList:MiniGameList;
    sortingParams:{ key?:string, asc?:boolean };

    constructor(
        private router:Router,
        private authService:AuthService,
        private dialogService:DialogService,
        private miniGameService:MiniGameService,
    ) { }

    ngOnInit(): void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:ListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.miniGameService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                response.miniGames.forEach(m => {
                    m.createdAt = 
                        new Date(m.createdAt).toLocaleDateString('en-US');
                });
                this.miniGamesList = response;
            }, 
            errors => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors));
                if (errors.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    delete(miniGameId:string) {
        this.deletingMiniGameId = miniGameId;
        this.miniGameService.delete(miniGameId).subscribe(() => {
            this.deletingMiniGameId = null;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.loadList();
        });
    }

    confirmDelete(miniGameId:string, miniGameTitle:string) {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(miniGameId),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                miniGameTitle + " mini-game from the Curriculum?",
            message: "This action cannot be undone."
        });
    }
}
