import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByNameAsc'
})
export class SortByNameAscPipe implements PipeTransform {

    transform(array: any[]): any[] {
        if (!Array.isArray(array)) {
            return array;
        }

        return array.sort((a, b) => {
            const titleA = a.title.toLowerCase();
            const titleB = b.title.toLowerCase();

            if (titleA < titleB) {
            return -1;
            }
            if (titleA > titleB) {
            return 1;
            }
            return 0;
        });
        }

}
