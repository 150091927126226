<div *ngIf="activityMatching.items" class="matching-container">
    <h2 class="title">Category Title *</h2>
    <div class="question-container category-title">
        <app-textarea placeholder="e.g.: Joining a Group" [(value)]="activityMatching.leftCategoryTitle"    maxChars="30" (maxCharsReached)="maxCharsValidation($event, 'category-left')"
            [error]="emptyMandatoryFields.includes('leftCategoryTitle') ? 'This field is required.' : ''"
            data-cy="category-reason-input"
        ></app-textarea>
        <app-textarea placeholder="e.g.: What to say or do to join in”" [(value)]="activityMatching.rightCategoryTitle"
            maxChars="30"
            [error]="emptyMandatoryFields.includes('rightCategoryTitle') ? 'This field is required.' : ''"
            (maxCharsReached)="maxCharsValidation($event, 'category-right')"
            data-cy="category-question-input"
        ></app-textarea>
    </div>
    <hr>
    <h2 class="title">Matching</h2>
    <ng-container *ngFor="let question of activityMatching.items; let i = index">
        <div class="question-container">
            <span class="index-number">{{ i + 1 }} <b *ngIf="i < 3">*</b></span>
            <app-textarea placeholder="e.g.: There is a fun group that sits together at lunch"
                [(value)]="question.left" maxChars="38"
                [error]="emptyMandatoryFields.includes('left' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'matching-left-' + i)"
                data-cy="reason-input"
            ></app-textarea>
            <app-textarea
                placeholder="e.g.: “I heard you talking about going to the game. I am going too. Can I sit with  you?”"
                [(value)]="question.right" maxChars="38"
                [error]="emptyMandatoryFields.includes('right' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'matching-right-' + i)"
                data-cy="question-input"
            ></app-textarea>
        </div>
        <hr>
    </ng-container>
    <div class="auto-check-section">
        <h3>Auto-check Feature</h3>
        <app-switch id="active" activeLabel="Active" [(value)]="activityMatching.isAutoCheck"></app-switch>
        <p>By default, answers will be marked as correct and incorrect after user completes activity. If there are more
            than one possible solutions, please mark the inactivate the auto-check feature.</p>
    </div>
</div>