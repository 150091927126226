import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-file-picker',
    templateUrl: './file-picker.component.html',
    styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent implements OnInit {
    @Input() id:string;
    @Input() file:File;
    @Input() label:string;
    @Input() error:string;
    @Input() value:string;
    @Input() inlineLabel:boolean;
    @Input() size:"medium"|"small" = "medium";
    @Input() type:"any"|"video"|"image"|"subtitle"|"pdf"|"pdf+image"|"image+pdf" = "any";
    @Input() isRequired: boolean = false;
    @Input() isDisabled: boolean = false;

    @Output() fileChange = new EventEmitter<File>();

    fileName:string;

    constructor() { }

    ngOnInit(): void {
        if (this.file)
            this.fileName = this.file.name;
    }

    onFileChange(files: FileList, inputLength?: number) {
        this.fileName = Array.from(files).map(f => f.name).join(', ');
        this.fileChange.emit(files.item(0));
        this.validateRequired(inputLength);
    }

    getMimeType() {
        if (this.type === "video")
            return "video/mp4";
        else if (this.type === "image")
            return "image/*";
        else if (this.type === "subtitle")
            return ".vtt";
        else if (this.type === "pdf")
            return "application/pdf";
        else if (this.type === "pdf+image" || this.type === "image+pdf")
            return "image/*,application/pdf";

        return "*";
    }

    removeFile() {
        this.fileName = "";
        this.fileChange.emit(null);
    }

    validateRequired(textLength: number): void {
        if (this.isRequired) {
            if (textLength === 0) {
                this.error = "This field is required."
            }
            if (textLength > 0) {
                this.error = "";
            }
        }
    }
}
