import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NamedFileListComponent } from './named-file-list.component';
import { ButtonModule } from '../button/button.module';
import { TextfieldModule } from '../textfield/textfield.module';

@NgModule({
    declarations: [
        NamedFileListComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        TextfieldModule,
    ],
    exports: [
        NamedFileListComponent
    ]
})
export class NamedFileListModule { }