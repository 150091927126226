import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { WhatToDo } from "../models/what-to-do/what-to-do.model";

@Injectable({
    providedIn: 'root'
})
export class WhatToDoService {
    constructor(private http:HttpService) { }

    get(toDoId:string):Observable<any> {
        return this.http.get('admin/to-do/' + toDoId);
    }

    save(toDo:WhatToDo):Observable<any> {
        var filteredToDo = Object.assign({}, toDo);
        filteredToDo.items = filteredToDo.items.map((i, idx) => {
            i.order = idx + 1;

            return i;
        }).filter(i => i.type);

        if (toDo.toDoId)
            return this.http.put('admin/to-do/' + toDo.toDoId, filteredToDo);
        
        return this.http.post('admin/to-do', filteredToDo);
    }

    delete(toDoId:string):Observable<any> {
        return this.http.delete('admin/to-do/' + toDoId);
    }
}
