import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { TagfieldModule } from 'src/app/shared/form/tagfield/tagfield.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ContentSeriesEditorComponent } from './content-series-editor.component';
import { RichtextareaModule } from 'src/app/shared/form/richtextarea/richtextarea.module';
import { ContentPickerModule } from 'src/app/shared/form/content-picker/content-picker.module';
import { MultiSelectModule } from 'src/app/shared/form/multi-select/multi-select.module';

@NgModule({
    declarations: [
        ContentSeriesEditorComponent
    ],
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        RouterModule,
        SelectModule,
        ButtonModule,
        TagfieldModule,
        TextfieldModule,
        MultiSelectModule,
        RichtextareaModule,
        ContentPickerModule,
    ],
})
export class ContentSeriesEditorModule { }