import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NavigationStart, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    isLoggedIn = false;

    constructor(
        private router:Router,
        private authService:AuthService
    ) { 
        this.router.events.subscribe(($ev) => {
            if (!($ev instanceof NavigationStart))
                return;

            this.isLoggedIn = this.authService.isAuthenticated();
        });
    }

    ngOnInit(): void {
        // Datadog Real User Monitoring
        this.initDatadogRum();
    }


    initDatadogRum() {
        datadogRum.init({
            applicationId: environment.ddApplicationId,
            clientToken: environment.ddToken,
            site: 'datadoghq.com',
            service: environment.ddServiceName,
            env: environment.ddEnv,
            // Specify a version number to identify the deployed version of your application in Datadog
            version: '4.2.0', 
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    }
}
