import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { HttpService } from './http.service';
import { Login, LoginRequest } from "../models/login/login.model";

@Injectable({
    providedIn: 'root'
})
export class AuthService {  
    constructor(
        private http:HttpService, 
        private storage:StorageService
    ) { }

    isAuthenticated():boolean {
        return !!this.storage.getToken();
    }

    login(obj:LoginRequest):Observable<Login> {                 
        return this.http.post('admin/sessions', { ...obj, app: "cms" });
    }

    clearSession() {
        this.storage.removeToken();
    }
}