import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "./http.service";


@Injectable({
    providedIn: 'root'
})
export class InteractiveActivityService {
    constructor(private http: HttpService) {}

    getList(params?): Observable<any> {
        return this.http.get('admin/interactive-activities', params)
    };

    getById(iaId: string): Observable<any> {
        return this.http.get('interactive-activities/' + iaId)
    };

    save(params?): Observable<any> {
        if (params?.interactiveActivityId) {
            return this.http.put('admin/interactive-activities/' + params.interactiveActivityId, params);
        }
        
        return this.http.post('admin/interactive-activities', params)
    };

    delete(iaId?: string): Observable<any> {
        return this.http.delete('admin/interactive-activities/' + iaId)
    };

}