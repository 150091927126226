export interface Tooltip {
    title?: string;
    content?: string;
    size?: "medium" | "large";
    position?: "left" | "right";
    isHoverEnabled?: boolean;
}

export const TooltipIA = {
    qa: {
        content: 'What should the student do in response to each question or statement? Example: For each situation, write the problem, and an answer.',
        isHoverEnabled: false
    },
    spinner: {
        content: 'Describe what to do when the spinner lands. The phrase “Each time you land on a situation:” will be displayed before this text.e.g.: Everytime the spinner stops on an emotion, name a symptom you can feel in your body for this emotion.',
        isHoverEnabled: false
    },
    diceRoller: {
        content: 'Describe how students will use the die for this activity. Every text following a line break will correspond to a numbered list item. E.g. if in this field you type in: Actors take turns rolling the dice to reveal a line and tone of voice. Actors will say their given line using the indicated tone of voice. In the online version, it will be displayed as: Actors take turns rolling the dice to reveal a line and tone of voice. Actors will say their given line using the indicated tone of voice. In the PDF version, it will be displayed as: 1) Actors take turns rolling the dice to reveal a line and tone of voice. 2) Actors will say their given line using the indicated tone of voice.',
        isHoverEnabled: false
    },
    description: {
        content: "The activity description describes the core concept of the skill you are working on. It will be displayed underneath the title.",
        isHoverEnabled: true
    },
    qaInstructions: {
        content: "What should the student do in response to each question or statement?" +
        "</br>Example: <i>For each situation, write the problem, and an answer.</i>",
        isHoverEnabled: true
    },
    spinnerInstructions: {
        content: "Describe what to do when the spinner lands. The phrase “Each time you land on a situation:” will be displayed before this text." +
        "</br>e.g.: <i>Everytime the spinner stops on an emotion, name a symptom you can feel in your body for this emotion.</i>",
        isHoverEnabled: true
    },
    'dice-rollerDescription': {
        content: "The activity description describes the core concept of the skill you are working on.<br><br>It will be only be displayed in the online interactive version underneath the title.",
        isHoverEnabled: true
    },
    'dice-rollerInstructions': {
        content: "Describe how students will use the die for this activity.</br></br> Every text following a line break will correspond to a numbered list item. "
        + "E.g. if in this field you type in: </br>Actors take turns rolling the dice to reveal a line and tone of voice.</br><Actors will say their given line using the indicated tone of voice.</br></br>"
        + " In the <b>online version</b>, it will be displayed as:</br><i>1. Actors take turns rolling the dice to reveal a line and tone of voice.</br>2. Actors will say their given line using the indicated tone of voice.</i></br></br>"
        + " In the <b>PDF version</b>, it will be displayed as:<br><i>1) Actors take turns rolling the dice to reveal a line and tone of voice.<br>2) Actors will say their given line using the indicated tone of voice.</i>",
        isHoverEnabled: true,
        size: "large"
    }
}