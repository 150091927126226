import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { LevelList } from "../models/level/level-list.model";
import { ListParams } from "../models/list-params/list-params.model";

@Injectable({
    providedIn: 'root'
})
export class LevelService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<LevelList> {
        return this.http.get('levels', params);
    }

    get(levelId:string):Observable<any> {
        return this.http.get('levels/' + levelId);
    }
}
