<div class="content {{layout}}">
    <label *ngIf="label">{{label}}</label>
    <div *ngIf="!content || !content.contentId" class="selectors">
        <app-button
            *ngIf="displayOriginalButton"
            type="primary"
            (click)="pickerOpened = true"
            [iconUrl]="displayBtnIcon ? '../../../../assets/images/icons/folder.svg' : ''"
        >
            {{ selectorTitle }}
        </app-button>
        <div class="error-message" *ngIf="error">{{error}}</div>
    </div>
    <div *ngIf="displayOriginalButton && content?.contentId || content?.bundleId" class="content-picker">
        <app-textfield
            disabled="true"
            label="Content Type"
            [(value)]="content.type || content.contentType"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            label="Content Title"
            [(value)]="content.title || content.contentTitle"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            label="Content Link"
            [(value)]="content.url || content.link"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-button
            type="danger"
            [loading]="deleting"
            iconUrl="../../../../assets/images/icons/delete.svg"
            (click)="onContentChange(undefined); pickerOpened = false"
        >
            Remove
        </app-button>
    </div>
</div>
<div class="overlay" *ngIf="pickerOpened">
    <div class="modal-content">
        <div class="title">{{title}}</div>
        <div class="content">
            <list-toolbar 
                searchSize="small"
                showCancelButton="true"
                (onSearchChange)="onSearchChange($event)"
                (onCancelCallback)="pickerOpened = false;onCancel()"
            ></list-toolbar>
            <ng-container *ngIf="!loading && contentsList">
                <list-view 
                    [headers]="headers" 
                    [sorting]="sorting"
                    (onSortChange)="onSortChange($event)" 
                >
                    <div *ngFor="let c of contentsList.contents;let i = index" class="row">
                        <div style="width: 180px; height: 80px;"><img [src]="c.thumbnail" /></div>
                        <div>{{c.title}}</div>
                        <div>{{c.type}}</div>
                        <div>
                            <img *ngIf="c.active == 'true'" src="../../../../assets/images/icons/status-active.svg" />
                            <img *ngIf="c.active == 'false'" src="../../../../assets/images/icons/status-inactive.svg" />
                        </div>
                        <div title="Select Content">
                            <button (click)="onContentChange(c);pickerOpened = false">
                                <img src="../../../../assets/images/icons/add-content.svg" alt="add" />
                            </button>
                        </div>
                    </div>
                </list-view>
            </ng-container>
            <app-loader *ngIf="loading"></app-loader>
            <list-paginator 
                [currentPage]="currentPage"
                [class]="loading ? 'hidden' : ''"
                (onPageChange)="onPageChange($event)"
                [totalPages]="contentsList?.totalPages" 
            ></list-paginator>
        </div>
    </div>
</div>