export const formatError = (error:any):string => {
    if (!error)
        return "";

    var message:string;
    if (Array.isArray(error)) {
        error.forEach(e => {
            message += getMessage(e) + "\n";
        })
    } else {
        message = getMessage(
            typeof error.error !== "string" ? error.error : error
        );
    }

    return message;
}

const getMessage = (errorObj):string => {
    return errorObj.message + "\n" + 
        "code: " + errorObj.supportCode;
};
