import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSummernoteModule } from 'ngx-summernote';
import { RichtextareaComponent } from './richtextarea.component';

@NgModule({
    declarations: [
        RichtextareaComponent
    ],
    imports: [
        CommonModule,
        NgxSummernoteModule
    ],
    exports: [
        RichtextareaComponent
    ]
})
export class RichtextareaModule { }