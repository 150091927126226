import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectModule } from '../../select/select.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { WtdPickerComponent } from './wtd-picker.component';
import { TextfieldModule } from '../../textfield/textfield.module';
import { RichtextareaModule } from '../../richtextarea/richtextarea.module';
import { ContentPickerModule } from '../../content-picker/content-picker.module';
import { WtdContentPickerModule } from './wtd-content-picker/wtd-content-picker.module';

@NgModule({
    declarations: [
        WtdPickerComponent,
    ],
    imports: [
        CommonModule,
        SelectModule,
        SharedModule,
        TextfieldModule,
        RichtextareaModule,
        ContentPickerModule,
        WtdContentPickerModule,
    ],
    exports: [
        WtdPickerComponent,
    ]
})
export class WtdPickerModule { }