import { Injectable } from '@angular/core';
import { 
    Router, 
    CanActivate, 
    ActivatedRouteSnapshot 
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        public router:Router, 
        public auth:AuthService, 
    ) {
    }

    canActivate(
        route:ActivatedRouteSnapshot
    ): boolean {
        if (!this.auth.isAuthenticated()) {
            if (route.queryParams)
                this.router.navigate(['login'], { queryParams: route.queryParams });
            else
                this.router.navigate(['login']);

            return false;
        } else {
            return true;
        }
    }
}
