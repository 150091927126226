<div class="content">
    <label *ngIf="label">{{label}}</label>
    <div class="input-section">
        <h6 *ngIf="inactiveLabel" [ngClass]="{'bold': !isChecked}">{{ inactiveLabel }}</h6>
        <div class="switch">
            <input 
                type="checkbox"
                [checked]="value"
                [id]="'ckb-' + id"
                class="switch-checkbox"
                (input)="onChange($event)"
            />            
            <label class="switch-label" [for]="'ckb-' + id" [ngClass]="[offColor === 'red' ? 'red-bg-color' : '', defaultView ? 'default-status' : '']">
                <span class="switch-inner"  [ngClass]="onColor === 'green' ? 'green-bg-color' : 'blue-bg-color'">
                </span>
                <span class="switch-switch" [ngClass]="onColor === 'green' ? 'green-bg-color' : 'blue-bg-color'"></span>
            </label>
        </div>
    <h6 *ngIf="activeLabel" [ngClass]="{'bold': isChecked}">{{ activeLabel }}</h6>
    </div>
</div>