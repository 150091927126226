<div class="content {{layout}}">
    <label *ngIf="label">{{label}}</label>
    <div *ngIf="!miniGame || !miniGame.miniGameId" class="selectors">
        <app-button
            type="primary"
            (click)="pickerOpened = true"
            iconUrl="../../../../assets/images/icons/folder.svg"
        >
            Select Content
        </app-button>
        <div class="error-message" *ngIf="error">{{error}}</div>
    </div>
    <div *ngIf="miniGame?.miniGameId" class="content-picker">
        <app-textfield
            disabled="true"
            label="Content Type"
            [(value)]="miniGame.type"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            label="Content Title"
            [(value)]="miniGame.title"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-textfield
            disabled="true"
            label="Content Link"
            [(value)]="miniGame.url"
            [inlineLabel]="layout === 'column'"
            [size]="layout === 'column' ? 'medium' : 'small'"
        ></app-textfield>
        <app-button
            type="danger"
            [loading]="deleting"
            iconUrl="../../../../assets/images/icons/delete.svg"
            (click)="onContentChange(undefined); pickerOpened = false"
        >
            Remove
        </app-button>
    </div>
</div>
<div class="overlay" *ngIf="pickerOpened">
    <div class="modal-content">
        <div class="title">{{title}}</div>
        <div class="content">
            <list-toolbar 
                searchSize="small"
                showCancelButton="true"
                (onSearchChange)="onSearchChange($event)"
                (onCancelCallback)="pickerOpened = false;onCancel()"
            ></list-toolbar>
            <ng-container *ngIf="!loading && miniGamesList">
                <list-view 
                    [headers]="headers" 
                    [sorting]="sorting"
                    (onSortChange)="onSortChange($event)" 
                >
                    <div *ngFor="let c of miniGamesList.miniGames;let i = index" class="row">
                        <div>{{c.title}}</div>
                        <div>{{c.contentDetail?.title}}</div>
                        <div>{{c.createdAt}}</div>
                        <div title="Select Content">
                            <button (click)="onMiniChange(c);pickerOpened = false">
                                <img src="../../../../assets/images/icons/add-content.svg" alt="add" />
                            </button>
                        </div>
                    </div>
                </list-view>
            </ng-container>
            <app-loader *ngIf="loading"></app-loader>
            <list-paginator 
                [currentPage]="currentPage"
                [class]="loading ? 'hidden' : ''"
                (onPageChange)="onPageChange($event)"
                [totalPages]="miniGamesList?.totalPages" 
            ></list-paginator>
        </div>
    </div>
</div>