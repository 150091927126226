import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { Goal } from "../models/goal/goal.model";
import { GoalList } from "../models/goal/goal-list.model";
import { GoalListParams } from "../models/goal/goal-list-params.model";

@Injectable({
    providedIn: 'root'
})
export class GoalService {
    constructor(private http:HttpService) { }

    list(params?:GoalListParams):Observable<GoalList> {
        return this.http.get('admin/goals', params);
    }

    get(goalId:string):Observable<any> {
        return this.http.get('admin/goals/' + goalId);
    }

    save(goal:Goal):Observable<any> {
        const filteredGoal = Object.assign({}, goal);
        filteredGoal.downloads = filteredGoal.downloads.filter(d => d.title && d.url);

        if (goal.goalId)
            return this.http.put('admin/goals/' + goal.goalId, filteredGoal);
        
        return this.http.post('admin/goals', filteredGoal);
    }

    delete(goalId:string):Observable<any> {
        return this.http.delete('admin/goals/' + goalId);
    }
}
