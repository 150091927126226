import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { SkillsListComponent } from './skills-list.component';
import { ListViewModule } from 'src/app/shared/list/list-view/list-view.module';
import { ListToolbarModule } from 'src/app/shared/list/list-toolbar/list-toolbar.module';
import { ListPaginatorModule } from 'src/app/shared/list/list-paginator/list-paginator.module';

@NgModule({
    declarations: [
        SkillsListComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        RouterModule,
        ListViewModule,
        ListToolbarModule,
        ListPaginatorModule,
    ],
})
export class SkillsListModule { }