import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivityFlashCards, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
  selector: 'app-activity-flashcards',
  templateUrl: './activity-flashcards.component.html',
  styleUrls: ['./activity-flashcards.component.scss'],
})
export class ActivityFlashcardsComponent implements OnInit {
  @Input() activityFlashcards: InteractiveActivityFlashCards;
  @Input() emptyMandatoryFields = Array<string>();

  @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

  constructor() {}

  tooltipFlashcards = {
    frontText: {
      content:
        '<b>Description or prompt for the front view of the flashcard that is displayed underneath the image.</b>',
      isHoverEnabled: true,
    },
    backText: {
      content:
        '<b>Description or prompt for the back view of the flashcard.</b></br></br><b>If there is NO image,</b> the back text is limited to 255 characters.</br></br>' +
        '<b>If there is an image, </b>the back text is limited to 108 characters.<br>',
      isHoverEnabled: true,
    },
  };

  ngOnInit(): void {
    if (Object.keys(this.activityFlashcards).length === 0) {
      this.activityFlashcards.items = [];
      this.activityFlashcards.items = Array.from({ length: 4 }, () => ({
        frontTitle: '',
        frontImage: '',
        frontTempImage: '',
        frontText: '',
        backTitle: '',
        backImage: '',
        backTempImage: '',
        backText: '',
      }));
    } else {
      if (this.activityFlashcards.items.length < 4) {
        let activityLength = this.activityFlashcards.items.length;
        let necessaryItems = Array.from({ length: 4 - activityLength }, () => ({
          frontTitle: '',
          frontImage: '',
          frontTempImage: '',
          frontText: '',
          backTitle: '',
          backImage: '',
          backTempImage: '',
          backText: '',
        }));
        this.activityFlashcards.items = [
          ...this.activityFlashcards.items,
          ...necessaryItems,
        ];
      }
    }
  }

  addFlashcard(): void {
    this.activityFlashcards.items.push({
      frontTitle: '',
      frontImage: '',
      frontText: '',
      backTitle: '',
      backImage: '',
      backText: '',
    });
  }

  deleteFlashcard(index: number): void {
    const cardsIndex = this.activityFlashcards.items.findIndex(
      (data, i) => i === index
    );
    this.activityFlashcards.items.splice(cardsIndex, 1);
  }

  maxCharsValidation(isMaxReached: boolean, field: string) {
    this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field });
  }
}
