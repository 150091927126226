<app-header>
    SEL Lessons
</app-header>
<div class="content">
    <list-toolbar 
        newItemButtonLabel="New Sel Lesson"
        (onSearchChange)="onSearchChange($event)"
    ></list-toolbar>
    <ng-container *ngIf="!loading && lessonsList">
        <list-view 
            [headers]="headers" 
            [sorting]="sorting"
            (onSortChange)="onSortChange($event)" 
        >
            <div *ngFor="let l of lessonsList.lessons" class="row">
                <div>{{l.title}}</div>
                <div>{{l.unit?.age?.title || "-"}}</div>
                <div>{{l.unit?.title || "-"}}</div>
                <div class="list-actions">
                    <a routerLink="/sel-lessons/{{l.lessonId}}" title="Edit Lesson">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0187 10.6787L13.3237 7.98367L15.2717 6.03467L17.9657 8.72867L16.0187 10.6787ZM9.07965 17.6247L6.10265 17.8957L6.36665 14.9397L11.9837 9.32267L14.6797 12.0187L9.07965 17.6247ZM19.4037 7.33767L19.4027 7.33667L16.6647 4.59867C15.9237 3.85967 14.6507 3.82467 13.9487 4.52967L4.95265 13.5257C4.62665 13.8507 4.42465 14.2827 4.38265 14.7397L4.00365 18.9097C3.97765 19.2047 4.08265 19.4967 4.29265 19.7067C4.48165 19.8957 4.73665 19.9997 4.99965 19.9997C5.03065 19.9997 5.06065 19.9987 5.09065 19.9957L9.26065 19.6167C9.71865 19.5747 10.1497 19.3737 10.4747 19.0487L19.4717 10.0517C20.1997 9.32167 20.1687 8.10367 19.4037 7.33767Z" />
                        </svg>
                    </a>
                    <copy-to-clipboard value="https://slp.everydayspeech.com/lessons/{{l.slug}}">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5271 3.89973C13.7091 1.71273 17.2301 1.31773 19.6621 3.21973C22.5751 5.49773 22.7661 9.74273 20.2351 12.2797L18.8121 13.7047C18.4191 14.0987 17.7831 14.0987 17.3901 13.7047C16.9971 13.3117 16.9971 12.6727 17.3901 12.2797L18.8121 10.8537C20.4671 9.19673 20.3771 6.44173 18.5401 4.90373C16.9131 3.54073 14.4711 3.80073 12.9721 5.30273L11.6991 6.57773C11.3071 6.97173 10.6691 6.97173 10.2771 6.57773C9.8841 6.18473 9.8801 5.54973 10.4541 4.97473L11.5271 3.89973ZM13.293 9.29303L9.293 13.293C8.902 13.684 8.902 14.316 9.293 14.707C9.488 14.902 9.744 15 10 15C10.256 15 10.512 14.902 10.707 14.707L14.707 10.707C15.098 10.316 15.098 9.68403 14.707 9.29303C14.316 8.90203 13.684 8.90203 13.293 9.29303ZM12.2803 17.3965L11.0063 18.6675C9.5053 20.1645 7.0583 20.4225 5.4303 19.0655C3.5913 17.5335 3.5003 14.7875 5.1573 13.1345L6.5823 11.7145C6.9753 11.3225 6.9753 10.6865 6.5823 10.2935C6.1893 9.90253 5.5513 9.90253 5.1573 10.2935L3.8823 11.5665C1.7103 13.7325 1.3293 17.2165 3.2073 19.6405C5.4783 22.5735 9.7383 22.7715 12.2803 20.2375L13.7053 18.8175C14.0983 18.4245 14.0983 17.7895 13.7053 17.3965C13.3113 17.0035 12.6743 17.0035 12.2803 17.3965Z"/>
                        </svg>
                    </copy-to-clipboard>
                </div>
            </div>
        </list-view>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
    <list-paginator 
        [currentPage]="currentPage"
        [class]="loading ? 'hidden' : ''"
        (onPageChange)="onPageChange($event)"
        [totalPages]="lessonsList?.totalPages" 
    ></list-paginator>
</div>
