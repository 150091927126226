<div class="search-container" [ngClass]="{'flex-gap-20': activityFilter}">
    <app-textfield [disabled]="disableSearch" [size]="searchSize" placeholder="Search..." (valueChange)="onChange($event)"
        iconUrl="../../../../assets/images/icons/search.svg" data-cy="search-input"></app-textfield>
    <div #typesBtnContainer *ngIf="activityFilter" class="types-container">
        <button class="type-filter" (click)="typeOptionsOpened = !typeOptionsOpened" data-cy="type-filter-btn" [disabled]="disableTypeFilter">
            <img src="../../../../assets/images/icons/filter.svg" alt="filter" class="icon-left" />
            Activity Type:
            <b *ngIf="typeSelected.length === 0">None</b>
            <b *ngIf="typeSelected.length === 9">All</b>
            <b *ngIf="typeSelected.length > 0 && typeSelected.length !== 9" data-cy="type-label-selected">{{ typeSelected.length > 1 ? typeSelected.length + ' selected' :
                typeSelected[0].label }}</b>
            <img src="../../../../assets/images/icons/chevron-up.svg" alt="arrow-up" class="icon-right" />
        </button>
        <div *ngIf="typeOptions && typeOptionsOpened" class="types-dropdown" data-cy="types-dropdown">
            <div 
                class="option" 
                *ngFor="let type of typeOptions; let i = index" 
                (click)="$event.preventDefault();typeFilterChange(type)"
                data-cy="type-options-dropdown"
            >
                <input [id]="type.id" type="checkbox" [checked]="optionIsChecked(type.id)"/>
                <label [for]="type.id" class="checkbox" data-cy="option-checkbox"></label>
                <label [for]="type.id" class="label">
                    {{ type.label }}
                </label>
            </div>
        </div>
    </div>
</div>
<div #actionsContainer class="actions-container">
    <app-button type="primary" *ngIf="newItemButtonLabel" iconUrl="../../../../assets/images/icons/plus.svg"
        (click)="newItemOptions ? optionsOpened = !optionsOpened : addNew()" data-cy="new-content-btn">
        {{newItemButtonLabel}}
    </app-button>
    <app-button type="warning" *ngIf="showCancelButton" tooltip="Go back without saving"
        iconUrl="../../../../assets/images/icons/cancel.svg" (click)="onCancelCallback && onCancelCallback.emit()">
        Cancel
    </app-button>
    <div *ngIf="newItemOptions && optionsOpened" class="options-dropdown" data-cy="item-options-dropdown">
        <div *ngFor="let o of newItemOptions" class="option" (click)="goTo(o.url)" data-cy="options-content">
            <img *ngIf="o.icon" [src]="o.icon" />
            <span>{{o.label}}</span>
        </div>
    </div>
</div>