import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListItemPickerComponent } from './list-item-picker.component';

@NgModule({
    declarations: [
        ListItemPickerComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ListItemPickerComponent
    ]
})
export class ListItemPickerModule { }