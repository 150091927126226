import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { AgeTrack } from 'src/app/models/age-track/age-track.model';
import { AgeTrackService } from 'src/app/services/age-track.service';
import { CurriculumService } from 'src/app/services/curriculum.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
    selector: 'app-sel-ages-editor',
    templateUrl: './sel-ages-editor.component.html',
    styleUrls: ['./sel-ages-editor.component.scss']
})
export class SelAgesEditorComponent {
    age: AgeTrack = {
        order: 0,
        ageId: "",
        title: "",
        btnColor: "",
        gradientEnd: "",
        primaryColor: "",
        gradientStart: "",
        secondaryColor: "",
        accentColorTwo: "",
        accentColorOne: "",
        accentColorThree: "",
        titleColor: "",
    };
    saving: boolean;
    loading: boolean;
    deleting: boolean;
    emptyMandatoryFields = Array<string>();
    availableOrders: Array<{ id: string, label: string }> = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private httpService: HttpService,
        private ageService: AgeTrackService,
        private dialogService: DialogService,
        private curriculumService: CurriculumService,
    ) {
        this.route.params.subscribe(data => {
            this.loading = true;
            if (data.ageId === "new")
                this.loadSelCurriculumData();
            else
                this.load(data.ageId);
        });
    }

    loadAvailableOrders(ageId?: string) {
        this.availableOrders = [];

        this.ageService.list({ curriculum: environment.selCurriculumSlug, })
            .subscribe(response => {
                for (var i = 0; i < response.ages.length; i++) {
                    var order = (i + 1).toString();
                    this.availableOrders.push({
                        id: order,
                        label: order
                    });
                }

                if (
                    ageId === "new" || !ageId ||
                    !this.age.order || response.ages.length <= 0
                ) {
                    var newOrder = (this.availableOrders.length + 1).toString();
                    this.availableOrders.push({
                        id: newOrder,
                        label: newOrder
                    });
                    this.age.order = this.availableOrders.length;
                }

                this.loading = false;
            });
    }

    loadSelCurriculumData() {
        this.curriculumService.get(environment.selCurriculumSlug)
            .subscribe(response => {
                this.age.curriculumId = response.curriculum.curriculumId;
                this.loadAvailableOrders();
            });
    }

    load(ageId: string) {
        this.ageService.get(ageId)
            .subscribe(response => {
                this.loading = false;
                this.age = response;
                this.loadAvailableOrders(ageId);
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    trySave() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        Promise.all(this.getMediaReadyForUpload())
            .then(() => {
                this.ageService.save(this.age).subscribe(response => {
                    this.saving = false;
                    this.dialogService.showSuccessAlert("Save Successful.");
                    if (response)
                        this.router.navigate(["/sel-ages/" + response.ageId]);
                }, errors => {
                    this.saving = false;
                    this.dialogService.showErrorAlert(formatError(errors?.error));
                });
            });
    }

    allFieldsAreValid(): boolean {
        this.emptyMandatoryFields = [];
        if (!this.age.title)
            this.emptyMandatoryFields.push("title");
        if (!this.age.scopePdfFile && !this.age.scopeFile)
            this.emptyMandatoryFields.push("scopeFile");
        if (!this.age.bannerFile && !this.age.banner)
            this.emptyMandatoryFields.push("banner");
        if (!this.age.imageFile && !this.age.image)
            this.emptyMandatoryFields.push("image");
        if (!this.age.gradeHeaderMascotFile && !this.age.mascotHeader)
            this.emptyMandatoryFields.push("mascotHeader");
        if (!this.age.titleColor)
            this.emptyMandatoryFields.push("titleColor");

        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }

        return this.emptyMandatoryFields.length <= 0;
    }

    getMediaReadyForUpload():Promise<string>[] {
        var promises:Promise<string>[] = [];
        if (this.age.scopePdfFile) {
            promises.push(
                this.createPromiseForMedia(this.age.scopePdfFile, "file")
                    .then(url => { 
                        this.age.scopeFile = url; 
                        this.age.scopePdfFile = null;
                        return url;
                    })
            );
        }

        if (this.age.bannerFile) {
            promises.push(
                this.createPromiseForMedia(this.age.bannerFile, "image")
                    .then(url => {
                        this.age.banner = url;
                        this.age.bannerFile = null;
                        return url;
                    })
            );
        }

        if (this.age.imageFile) {
            promises.push(
                this.createPromiseForMedia(this.age.imageFile, "image")
                    .then(url => {
                        this.age.image = url;
                        this.age.imageFile = null;
                        return url;
                    })
            );
        }

        if (this.age.mascotFile) {
            promises.push(
                this.createPromiseForMedia(this.age.mascotFile, "file")
                    .then(url => {
                        this.age.mascot = url;
                        this.age.mascotFile = null;
                        return url;
                    })
            );
        }

        if (this.age.gradeHeaderMascotFile) {
            promises.push(
                this.createPromiseForMedia(this.age.gradeHeaderMascotFile, "file")
                    .then(url => {
                        this.age.mascotHeader = url;
                        this.age.gradeHeaderMascotFile = null;
                        return url;
                    })
            );
        }

        if (this.age.implementationGuideFile) {
            promises.push(
                this.createPromiseForMedia(this.age.implementationGuideFile, "file")
                    .then(url => {
                        this.age.implementationGuide = url;
                        this.age.implementationGuideFile = null;
                        return url;
                    })
            );
        }

        return promises;
    }

    createPromiseForMedia(
        file: File, 
        type: "video" | "image" | "file"
    ): Promise<string> {
        return new Promise((resolve, reject) => {
            this.httpService.uploadMedia(file, type)
                .subscribe(
                    r => resolve(r.secure_url),
                    error => reject(error)
                );
        });
    }

    goBack() {
        this.router.navigate(["/sel-ages"]);
    }
}
