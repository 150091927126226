<app-textfield
    label="EDSW Chapter URL"
    [(value)]="content.url"
    [error]="emptyMandatoryFields.includes('url') ? 'This field is required.' : ''"
    data-cy="edsw-url-input"
></app-textfield>
<app-select
    size="small"
    label="Chapter Order"
    [(value)]="content.contentId"
    [options]="availableOrders"
></app-select>
<app-textfield
    label="Title"
    [(value)]="content.title"
    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
    data-cy="title-input"
></app-textfield>
<app-richtextarea
    label="Description"
    data-cy="description-input"
    placeholder="Place your text"
    [(value)]="content.description"    
>
</app-richtextarea>