import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TagfieldComponent } from './tagfield.component';

@NgModule({
    declarations: [
        TagfieldComponent
    ],
    imports: [
        FormsModule,
        CommonModule
    ],
    exports: [
        TagfieldComponent
    ]
})
export class TagfieldModule { }