<div class="thumb-preview">
    <video *ngIf="content.url" [src]="content.url" controls></video>
    <img *ngIf="!content.url" src="../../../../assets/images/icons/video-placeholder.svg" />
</div>
<app-file-picker
    id="video"
    type="video"
    label="Main content"
    [value]="content.url"
    (fileChange)="content.file = $event; !$event && content.url = ''"
    [error]="emptyMandatoryFields.includes('file') ? 'This field is required.' : ''"
>
</app-file-picker>
<app-interactive-vcw-picker 
    label="Interactive VCW"
    [(interactiveActivityId)]="content.interactiveActivityId"
></app-interactive-vcw-picker>
<app-textfield
    label="Title"
    [(value)]="content.title"
    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
    data-cy="title-input"
></app-textfield>
<app-richtextarea
    label="Description"
    data-cy="description-input"
    placeholder="Place your text"
    [(value)]="content.description"    
>
</app-richtextarea>
<app-file-picker
    id="subtitle"
    type="subtitle"
    label="Subtitle"
    inlineLabel="true"
    [value]="content.subtitles[0].url"
    (fileChange)="content.subtitles[0].file = $event; !$event && content.subtitles[0].url = ''"
>
</app-file-picker>
<app-textfield
    size="small"
    label="language"
    inlineLabel="true"
    [(value)]="content.subtitles[0].language"
></app-textfield>
<app-file-picker
    type="pdf"
    id="skill-review-pdf"
    label="Skill Review PDF"
    [(value)]="content.skillReviewFile"
    (fileChange)="content.skillReviewPdfFile = $event; !$event && content.skillReviewFile = ''"
    [error]="emptyMandatoryFields.includes('skillReviewFile') ? 'This field is required.' : ''"
></app-file-picker>
