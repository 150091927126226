import { WhatToDo } from 'src/app/models/what-to-do/what-to-do.model';
import { LessonStep, SelLesson } from 'src/app/models/sel-lesson/sel-lesson.model';
import { WhatToDoItem } from 'src/app/models/what-to-do/what-to-do-item.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-wtd-section',
    templateUrl: './wtd-section.component.html',
    styleUrls: ['./wtd-section.component.scss']
})
export class WtdSectionComponent implements OnInit {
    @Input() label: string;
    @Input() mainError?: string;
    @Input() errors: Array<string>;
    @Input() numberOfFields: number;
    @Input() viewMode: "wtd" | "steps";
    @Input() type: "sel" | "sc" | "step";
    @Input() whatToDo: WhatToDo | SelLesson;
    
    @Output() whatToDoChange = new EventEmitter<WhatToDo | SelLesson>();

    items: WhatToDoItem[] | LessonStep[];

    constructor() { }

    ngOnInit(): void {
        this.items = this.isWtd(this.whatToDo) 
            ? this.whatToDo.items 
            : this.whatToDo.steps;
            
        if (typeof this.numberOfFields === "string")
            this.numberOfFields = parseInt(this.numberOfFields);

        if (!this.items)
            this.items = [];

        if (this.items.length < this.numberOfFields) {
            var itemsCount = this.items.length;
            for (var i = 0; i < this.numberOfFields - itemsCount; i++)
                this.items.push({});
        }
    }

    isWtd(t: WhatToDo | SelLesson): t is WhatToDo {
        return this.type !== "step";
    }

    isWtdItemArray(t: WhatToDoItem[] | LessonStep[]): t is WhatToDoItem[] {
        return this.type !== "step";
    }

    getErrorsForIndex(i: number): Array<string> | undefined {
        if (!this.errors)
            return undefined;

        var id = (this.type === "step" ? "step-" : "wtd-") + this.type + "-" + i;
        return this.errors.some(e => e.indexOf(id) >= 0) ?
            this.errors.filter(e => e.startsWith(id)) : undefined;
    }

    onWhatToDoChange($event: WhatToDoItem | LessonStep, i: number) {
        this.items[i] = $event;
        if (this.isWtd(this.whatToDo)) {
            if (this.isWtdItemArray(this.items))
                this.whatToDo.items = this.items;
        } else {
            if (!this.isWtdItemArray(this.items))
                this.whatToDo.steps = this.items;
        }

        this.whatToDoChange.emit(this.whatToDo);
    }
}
