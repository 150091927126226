<app-header>
    <a routerLink="/mini-games">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!miniGame.miniGameId ? "New" : miniGame.title}} Mini Game</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
        <app-button
            type="danger" 
            [loading]="deleting"
            (click)="confirmDelete()"
            *ngIf="miniGame.miniGameId"
            iconUrl="../../../../assets/images/icons/delete.svg"
        >
            Delete
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <div class="column">
                <div class="game-picker">
                    <div class="title">Parent Game</div>
                    <app-content-picker
                        layout="row"
                        title="Games"
                        *ngIf="gameTypeId"
                        [type]="gameTypeId"
                        [content]="miniGame.contentDetail"
                        (contentChange)="onContentChange($event)"
                        [error]="emptyMandatoryFields.includes('content') ? 'This field is required.' : ''"
                    ></app-content-picker>
                    <div class="game-field {{emptyMandatoryFields.includes('content') && !miniGame.contentDetail?.contentId ? 'error' : ''}}">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" [class]="miniGame.contentDetail.contentId ? 'icon filled' : 'icon'">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.183 12.4615C20.6362 12.4615 21.0196 12.3065 21.3333 11.9963C21.6471 11.6862 21.8039 11.2985 21.8039 10.8332C21.8039 10.368 21.6471 9.98462 21.3333 9.68308C21.0196 9.38154 20.6362 9.23077 20.183 9.23077C19.7298 9.23077 19.342 9.38584 19.0196 9.696C18.6972 10.0062 18.5359 10.3852 18.5359 10.8332C18.5359 11.2812 18.6928 11.6646 19.0065 11.9834C19.3203 12.3022 19.7124 12.4615 20.183 12.4615ZM15.8431 15.6923C16.2963 15.6923 16.6797 15.5372 16.9935 15.2271C17.3072 14.9169 17.4641 14.5292 17.4641 14.064C17.4641 13.5988 17.3072 13.2154 16.9935 12.9138C16.6797 12.6123 16.2963 12.4615 15.8431 12.4615C15.39 12.4615 15.0022 12.6166 14.6797 12.9268C14.3573 13.2369 14.1961 13.616 14.1961 14.064C14.1961 14.512 14.3529 14.8954 14.6667 15.2142C14.9804 15.5329 15.3725 15.6923 15.8431 15.6923ZM10.9281 13.5212V11.4018H7.66013V8.17108H5.46405V11.4018H2.19608V13.5212H5.46405V16.752H7.66013V13.5212H10.9281ZM21.8039 6C22.3965 6 22.9107 6.21538 23.3464 6.64615C23.7821 7.07693 24 7.58523 24 8.17108V16.752C24 17.3378 23.7821 17.8462 23.3464 18.2769C22.9107 18.7077 22.3965 18.9231 21.8039 18.9231H2.19608C1.60348 18.9231 1.08933 18.7077 0.653595 18.2769C0.217863 17.8462 0 17.3378 0 16.752V8.17108C0 7.58523 0.217863 7.07693 0.653595 6.64615C1.08933 6.21538 1.60348 6 2.19608 6H21.8039Z"/>
                        </svg>
                        <span>{{miniGame.contentDetail.contentId ? miniGame.contentDetail.title : "Select game..."}}</span>
                    </div>
                    <app-button
                        type="danger" 
                        (click)="removeContent()"
                        *ngIf="miniGame.contentDetail.contentId"
                        iconUrl="../../../../assets/images/icons/delete.svg"
                    >
                        Remove
                    </app-button>
                </div>
                <app-textfield
                    label="Title"
                    [(value)]="miniGame.title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
                <app-select
                    label="Config type"
                    [(value)]="miniGame.configType"
                    [options]="availableConfigTypes"
                ></app-select>
                <app-textarea
                    label="Mini Game Config"
                    [(value)]="miniGame.configFile"
                    [error]="emptyMandatoryFields.includes('configFile') ? 'This field is required.' : ''"
                ></app-textarea>
                <app-tagfield
                    label="Tags"
                    [(values)]="miniGame.tags"
                ></app-tagfield>
            </div>
            <div class="column">
                <div class="thumb-preview">
                    <img 
                        class="{{!miniGame.thumbnail ? 'placeholder' : ''}}"
                        [src]="miniGame.thumbnail || '../../../../assets/images/icons/video-image-placeholder.svg'" 
                    />
                </div>
                <app-file-picker
                    id="image"
                    type="image"
                    label="Thumbnail"
                    [value]="miniGame.thumbnail"
                    (fileChange)="miniGame.thumbnailFile = $event; !$event && miniGame.thumbnail = ''"
                    [error]="emptyMandatoryFields.includes('thumbnail') ? 'This field is required.' : ''"
                ></app-file-picker>
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
