import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContentActivityComponent } from './content-activity.component';
import { ListViewModule } from 'src/app/shared/list/list-view/list-view.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { RichtextareaModule } from 'src/app/shared/form/richtextarea/richtextarea.module';
import { ActivityPickerModule } from 'src/app/shared/form/activity-picker/activity-picker.module';

@NgModule({
    declarations: [
        ContentActivityComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ListViewModule,
        PdfViewerModule,
        TextfieldModule,
        RichtextareaModule,
        ActivityPickerModule,
    ],
    exports: [
        ContentActivityComponent
    ]
})
export class ContentActivityModule { }