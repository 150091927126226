<label *ngIf="label">{{label}}</label>
<div class="options-container {{layout}} {{error ? 'error' : ''}}">
    <ng-container *ngIf="groupedOptions">
        <div class="group" *ngFor="let group of groupedOptions">
            <div class="header">
                {{group.header}}
            </div>
            <div class="option" *ngFor="let o of group.options">
                <input 
                    [id]="o.id"
                    type="checkbox" 
                    [checked]="optionIsChecked(o.id)"
                    (input)="onChange(o.id, $event.target.checked)"
                />
                <label [for]="o.id" class="checkbox"></label>
                <label [for]="o.id" class="label">
                    {{o.label}}
                </label>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="options">
        <div class="option" *ngFor="let o of options">
            <input 
                [id]="o.id"
                type="checkbox" 
                [checked]="optionIsChecked(o.id)"
                (input)="onChange(o.id, $event.target.checked)"
            />
            <label [for]="o.id" class="checkbox"></label>
            <label [for]="o.id" class="label">
                {{o.label}}
            </label>
        </div>
    </ng-container>
</div>
<div class="error-message" *ngIf="error">{{error}}</div>