<div *ngIf="activityMultipleChoice.items" class="multiple-choice-container">
    <ng-container *ngFor="let question of activityMultipleChoice.items; let i = index">
        <div class="header-section">
            <h2 class="title" data-cy="questions-title">Question {{ i + 1 }}</h2>
            <app-button
                *ngIf="i >= 5"
                type="transparent"
                iconUrl="../../../../assets/images/icons/trash.svg"
                size="small"
                [disableBtn]="question.answers.length >= 6"
                (click)="deleteQuestion(i)"
            >Delete Question
            </app-button>
        </div>
        <div class="questions-container">
            <app-textarea
                placeholder="E.g. Which of the following choices are acceptable ways to start an email to your teacher?"
                [(value)]="question.prompt" maxChars="200" label="Prompt"
                [error]="emptyMandatoryFields.includes('prompt' + i) ? 'This field is required.' : ''"
                (maxCharsReached)="maxCharsValidation($event, 'prompt-' + i)"
                isRequired="true"
                data-cy="prompt-input"
            ></app-textarea>
            <div class="tooltip-section">
                <p>Answer options (3 to 6 options)</p>
                <app-tooltip *ngIf="tooltip" [config]="tooltip"></app-tooltip>
            </div>
            <ng-container *ngFor="let answer of question.answers; let answersIndex = index">
                <div class="answers-container">
                    <div class="letters-index">
                        <h3>{{ getLetterFromNumber(answersIndex + 1) }}</h3>
                    </div>
                    <app-textfield [label]="'Answer ' + (answersIndex + 1) + ' Text'" placeholder="Type in an answer here"
                        [(value)]="answer.value" maxChars="45" [isRequired]="answersIndex <= 2"
                        [error]="emptyMandatoryFields.includes('value' + i + answersIndex) ? 'This field is required.' : ''"
                        (maxCharsReached)="maxCharsValidation($event, 'answer-' + i + '-' + answersIndex)"
                        data-cy="answers-input"
                    ></app-textfield>
                    <app-switch class="toggle" id="{{i + '-' + answersIndex}}" label="Answer Type" activeLabel="Correct"
                    inactiveLabel="Wrong" offColor="red" [(value)]="answer.isCorrect"
                        data-cy="is-correct-asnwer-btn"
                    ></app-switch>
                    <app-button
                        *ngIf="answersIndex > 2"
                        type="transparent"
                        iconUrl="../../../../assets/images/icons/trash.svg"
                        (click)="deleteAnswer(answersIndex, i)"
                        data-cy="add-answer-btn"
                    >Delete Answer
                    </app-button>
                </div>
            </ng-container>
            <app-button
                type="transparent"
                iconUrl="../../../../assets/images/icons/plus-blue.svg"
                [disableBtn]="question.answers.length >= 6"
                (click)="addAnswer(i)"
                data-cy="add-answer-btn"
            >Add an answer
            </app-button>
        </div>
        <hr>
    </ng-container>
    <app-button 
        type="primary"
        iconUrl="../../../../assets/images/icons/plus.svg"
        (click)="addQuestion()"
        [disableBtn]="isAddQuestionDisabled"
        data-cy="add-question-btn"
    >Add Question
    </app-button>
</div>