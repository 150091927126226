import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivity, InteractiveActivityQa, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
    selector: 'app-activity-qa',
    templateUrl: './activity-qa.component.html',
    styleUrls: ['./activity-qa.component.scss']
})
export class ActivityQaComponent implements OnInit {

    @Input() activityQa: InteractiveActivityQa;
    @Input() emptyMandatoryFields = Array<string>();
   
    @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();

    constructor() { }

    ngOnInit(): void {
        if (Object.keys(this.activityQa).length === 0) {
            this.activityQa.items = [];
            this.activityQa.items = Array.from({length: 5}, () => ({question: ''}))
            this.activityQa.instructions = '';
            this.activityQa.reviewPrompt = '';
        } else {
            if (this.activityQa.items.length < 5) {
                let activityLength = this.activityQa.items.length;
                let necessaryItems = Array.from({length: (5 - activityLength)}, () => ({question: ''}))
                this.activityQa.items = [...this.activityQa.items, ...necessaryItems];
            }
        }
    }

    maxCharsValidation(isMaxReached: boolean, field: string) {
        this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field });
    }
}
