import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'list-toolbar',
    templateUrl: './list-toolbar.component.html',
    styleUrls: ['./list-toolbar.component.scss']
})
export class ListToolbarComponent implements OnInit {
    @ViewChild('actionsContainer') actionsContainer;
    @ViewChild('typesBtnContainer') typesBtnContainer;

    @Input() newItemButtonLabel = "";
    @Input() showCancelButton: boolean;
    @Input() searchSize: "small" | "medium" = "medium";
    @Input() newItemOptions: Array<{ label: string, icon?: string, url: string }>;
    @Input() typeOptions: Array<{ id: number, label: string }>;
    @Input() activityFilter: boolean = false;
    @Input() disableSearch: boolean = false;
    @Input() disableTypeFilter: boolean = false;

    @Output() onSearchChange = new EventEmitter<string>();
    @Output() onCancelCallback = new EventEmitter<void>();
    @Output() onTypeFilterChange = new EventEmitter<{ id: number, label: string }[]>();

    optionsOpened: boolean;
    typeOptionsOpened: boolean;
    typeSelected: Array<{ id: number, label: string }> = [];

    constructor(private router: Router) { }

    ngOnInit(): void { }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLDivElement): void {
        if (
            this.optionsOpened &&
            !this.actionsContainer.nativeElement.contains(targetElement)
        ) {
            this.optionsOpened = false;
        }

        if (
            this.typeOptionsOpened &&
            !this.typesBtnContainer.nativeElement.contains(targetElement)
        ) {
            this.typeOptionsOpened = false;
        }
    }

    addNew() {
        this.router.navigate([this.router.url + "/new"]);
    }

    goTo(url: string) {
        this.router.navigate([url]);
    }

    onChange($term) {
        this.onSearchChange.emit($term)
    }

    optionIsChecked(id: number) {
        if (!this.typeSelected)
            return false;

        let typeFiltered = this.typeSelected.findIndex(f => f.id === id);
        return typeFiltered !== -1;
    }

    typeFilterChange(type: { id: number, label: string }) {
        const typeIndex = this.typeSelected.findIndex(f => f.id === type.id);
        if (typeIndex === -1) {
            this.typeSelected.push(type);

            type.label.toLowerCase() === 'all' ? Object.assign(this.typeSelected, this.typeOptions) : null;
        } else {
            this.typeSelected.splice(typeIndex, 1);
            const allIndex = this.typeSelected.findIndex(f => f.label.toLowerCase() === 'all');
            if (allIndex !== -1)
                this.typeSelected.splice(allIndex, 1);

            type.label.toLowerCase() === 'all' ? this.typeSelected = []: null;
        }

        this.onTypeFilterChange.emit(this.typeSelected);
    }
}
