import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivityMultipleChoice, MaxCharsObject } from 'src/app/models/interactive-activity/interactive-activity.model';

@Component({
    selector: 'app-activity-multiple-choice',
    templateUrl: './activity-multiple-choice.component.html',
    styleUrls: ['./activity-multiple-choice.component.scss']
})
export class ActivityMultipleChoiceComponent implements OnInit {
    
    @Input() activityMultipleChoice: InteractiveActivityMultipleChoice;
    @Input() emptyMandatoryFields = Array<string>();
    
    @Output() maxCharsReached = new EventEmitter<MaxCharsObject>();
    
    isAddQuestionDisabled: boolean;
    isAddAnswerDisabled: boolean = false;
    tooltip = {
        content: '<b>Answer choices will be displayed in the activity in the order they appear on the CMS.</b></br></br><b>If one answer is marked as Correct:</b>' +
        '<ul><li>the question will be considered a single-choice question</li> <li>the instruction shown above the prompt will be “Select the correct answer.”</li></ul><br>' +
        '<b>If two or more answers are marked as Correct:</b>' +
        '<ul><li>the question will be considered a multiple-choice question</li> <li>the instruction shown above the prompt will be “Select all answers that apply.”</li></ul>',
        isHoverEnabled: true,
        size: 'extra-large'
    }

    constructor() { }

    ngOnInit(): void {
        if (Object.keys(this.activityMultipleChoice).length === 0) {
            this.activityMultipleChoice.items = [];
            this.activityMultipleChoice.items = Array.from({length: 5}, () => (
                {
                    prompt: '',
                    answers: [{ value: "", isCorrect: false }, { value: "", isCorrect: false }, { value: "", isCorrect: false },]
                }
            ));
        } else {
            if (this.activityMultipleChoice.items.length < 5) {
                let activityLength = this.activityMultipleChoice.items.length;
                let necessaryItems = Array.from({length: (5 - activityLength)}, () => (
                    {
                        prompt: '',
                        answers: [{ value: "", isCorrect: false }, { value: "", isCorrect: false }, { value: "", isCorrect: false },]
                    }
                ));
                this.activityMultipleChoice.items = [...this.activityMultipleChoice.items, ...necessaryItems];
            }
        };

        this.isAddQuestionDisabled = this.activityMultipleChoice.items.length === 10 ? true : false;
    }

    getLetterFromNumber(index: number): String {
        const charFromNumber = String.fromCharCode(index + 64);

        return charFromNumber;
    }

    addAnswer(questionIndex: number): void {
        if (this.activityMultipleChoice.items[questionIndex].answers.length < 6) {
            this.activityMultipleChoice.items[questionIndex].answers.push(
                { value: "", isCorrect: false }
            );
        }
    }

    deleteAnswer(answerIndex: number, questionIndex: number) {
        this.activityMultipleChoice.items[questionIndex].answers.splice(answerIndex, 1);
    }

    addQuestion(): void {
        this.activityMultipleChoice.items.push(
            {
                prompt: "",
                answers: [{ value: "", isCorrect: false }, { value: "", isCorrect: false }, { value: "", isCorrect: false }]
            }
        );
        this.isAddQuestionDisabled = this.activityMultipleChoice.items.length === 10 ? true : false;
    }

    deleteQuestion(questionIndex: number) {
        this.activityMultipleChoice.items.splice(questionIndex, 1);
    }

    maxCharsValidation(isMaxReached: boolean, field: string) {
        this.maxCharsReached.emit({ isMaxCharsReached: isMaxReached, fieldName: field });
    }

}
