import { NgModule } from "@angular/core";
import { LottieModule } from 'ngx-lottie';
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SpinnerModule } from "./spinner/spinner.module";
import { SelectModule } from "./form/select/select.module";
import { ButtonModule } from "./form/button/button.module";
import { HeaderComponent } from "./header/header.component";
import { LoaderComponent } from "./loader/loader.component";
import { TagfieldModule } from "./form/tagfield/tagfield.module";
import { TextareaModule } from "./form/textarea/textarea.module";
import { TextfieldModule } from "./form/textfield/textfield.module";
import { ColorPickerModule } from "./form/color-picker/color-picker.module";
import { MultiSelectModule } from "./form/multi-select/multi-select.module";
import { RichtextareaModule } from "./form/richtextarea/richtextarea.module";
import { FilePickerComponent } from "./form/file-picker/file-picker.component";
import { LottiePlayerComponent } from './lottie-player/lottie-player.component';
import { NamedFileListModule } from "./form/named-file-list/named-file-list.module";
import { CopyToClipboardModule } from "./copy-to-clipboard/copy-to-clipboard.module";
import { ListItemPickerModule } from "./form/list-item-picker/list-item-picker.module";
import { TooltipModule } from "./tooltip/tooltip.module";
import { InteractiveLinkContentModule } from "./interactive-link-content/interactive-link-content.module";
import { RadioButtonModule } from "./radio-btn/radio-btn.module";
import { SwitchModule } from "./form/switch/switch.module";
import { FilePickerModule } from "./form/file-picker/file-picker.module";
import { InteractiveVcwPickerModule } from "./form/interactive-vcw-picker/interactive-vcw-picker.module";

@NgModule({
    declarations: [
        HeaderComponent,
        LoaderComponent,
        LottiePlayerComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SelectModule,
        ButtonModule,
        LottieModule,
        SpinnerModule,
        TextareaModule,
        TagfieldModule,
        TextfieldModule,
        MultiSelectModule,
        ColorPickerModule,
        RichtextareaModule,
        NamedFileListModule,
        ListItemPickerModule,
        CopyToClipboardModule,
        TooltipModule,
        InteractiveLinkContentModule,
        RadioButtonModule,
        SwitchModule,
        FilePickerModule,
        InteractiveVcwPickerModule,
    ],
    exports: [
        HeaderComponent,
        LoaderComponent,
        LottiePlayerComponent,
        RadioButtonModule,
        ButtonModule,
        SwitchModule,
        FilePickerModule,
        InteractiveVcwPickerModule
    ]
})

export class SharedModule {   

}