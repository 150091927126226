<app-header>
    <a routerLink="/interactive-activities" data-cy="back-to-content-btn">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back" />
    </a>
    <span *ngIf="!loading" class="content-title" data-cy="content-title">
        {{ !activity.interactiveActivityId ? "New Interactive Activity Content" : activity.title }}
    </span>
    <div *ngIf="!loading" class="actions">
        <app-button type="primary" [loading]="saving" (click)="trySave()" iconUrl="../../../../assets/images/icons/save.svg" data-cy="save-content-btn">
            Save
        </app-button>
        <app-button type="warning" (click)="goBack()" tooltip="Go back without saving" iconUrl="../../../../assets/images/icons/cancel.svg">
            Cancel
        </app-button>
    </div>
</app-header>
<div class="interactive-container">
    <app-loader *ngIf="loading"></app-loader>
    <div *ngIf="!loading" class="activity-container">
        <h3 class="activity-slug" data-cy="activity-title">{{ interactiveTypes[activityTypeSlug] }}</h3>
        <div class="d-flex">
            <div class="activity-info">
                <h3>Worksheet Creation Characteristics</h3>
                <pre>{{worksheetCharacteristics}}</pre>

                <h3>Linked Content</h3>
                <p *ngIf="!activity.contentTitle">No content has been linked to this activity yet.</p>
                <div *ngIf="activity.contentTitle" class="linked-content-container">
                    <p>#</p>
                    <p class="linked-content">{{activity.contentTitle}}</p>
                    <a
                        [routerLink]="['/contents/' + activity.contentTypeSlug] + '/' + activity.contentId" target="_blank"
                        aria-label="redirect link for linked content"
                    >
                    <img src="../../../../assets/images/icons/redirect-link.svg"></a>
                </div>
                <app-textfield 
                    [(value)]="activity.title"
                    [error]="errors.includes('title') ? 'This field is required' : ''"
                    label="Activity Title"
                    isRequired="true"
                    placeholder="Descriptive title for the skill you're working on. It will appear at the top."
                    maxChars="60" 
                    (maxCharsReached)="maxCharsValidation({ isMaxCharsReached: $event, fieldName: 'activity-title' })"
                    data-cy="activity-input">
                </app-textfield>
                <app-textarea
                    [(value)]="activity.description"
                    [error]="errors.includes('description') ? 'This field is required' : ''"
                    [tooltip]="activityTypeSlug == 'dice-roller' ? tooltipIA[activityTypeSlug + 'Description'] : tooltipIA['description']"
                    label="Activity Description"
                    placeholder="Describe the skill you're focusing on for this activity. Ex: Listening to your teacher"
                    maxChars="180"
                    isRequired="true"
                    (maxCharsReached)="maxCharsValidation({ isMaxCharsReached: $event, fieldName: 'description' })"
                    data-cy="description-input">
                </app-textarea>
                <ng-container *ngIf="activeInstructionsArea()">
                    <app-textarea
                        [(value)]="activity.instructions"
                        [error]="errors.includes('instructions') ? 'This field is required' : ''"
                        label="Activity Instructions"
                        placeholder="Describe the skill"
                        [maxChars]="activityTypeSlug == 'dice-roller' ? '300' : '180'"
                        isRequired="true"
                        [tooltip]="tooltipIA[activityTypeSlug + 'Instructions']"
                        (maxCharsReached)="maxCharsValidation({ isMaxCharsReached: $event, fieldName: 'instructions' })"
                        data-cy="instructions-input">
                    </app-textarea>
                </ng-container>
                <div *ngIf="activeStaticInstructions()" class="instructions-description">
                    <h3>{{ instructionsData[activityTypeSlug].title }}</h3>
                    <div [innerHTML]="instructionsData[activityTypeSlug].description"></div>
                </div>
            </div>
            <div *ngIf="activity.interactiveWorksheetsUrl" class="interactive-section">
                <app-interactive-link-content 
                    [urlLink]="activity.interactiveWorksheetsUrl"
                    [pdfUrl]="activity.pdfUrl"
                    [pdfName]="activity.title"
                >
                </app-interactive-link-content>
            </div>
        </div>
        <hr>
        <div *ngIf="!loading" class="activity-content">
            <ng-container *ngIf="activityTypeSlug === 'qa' && activity?.configuration.activityQa">
                <app-activity-qa [(activityQa)]="activity?.configuration.activityQa" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)"></app-activity-qa>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'fill-in-the-blanks' && activity?.configuration?.activityFillBlanks">
                <app-activity-fill-blanks [(activityFillBlanks)]="activity?.configuration.activityFillBlanks" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)">
                </app-activity-fill-blanks>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'spinner' && activity?.configuration.activitySpinner">
                <app-activity-spinner [(activitySpinner)]="activity?.configuration.activitySpinner" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)"></app-activity-spinner>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'matching' && activity?.configuration.activityMatching">
                <app-activity-matching [(activityMatching)]="activity?.configuration.activityMatching" [emptyMandatoryFields]="emptyMandatoryFields"(maxCharsReached)="maxCharsValidation($event)"></app-activity-matching>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'sorting' && activity?.configuration.activitySorting">
                <app-activity-sorting [(activitySorting)]="activity?.configuration.activitySorting" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)"></app-activity-sorting>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'flashcards' && activity?.configuration.activityFlashcards">
                <app-activity-flashcards [(activityFlashcards)]="activity?.configuration.activityFlashcards" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)"></app-activity-flashcards>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'dice-roller' && activity?.configuration.activityDiceRoller">
                <app-activity-dice-roller [(activityDiceRoller)]="activity?.configuration.activityDiceRoller" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)">
                </app-activity-dice-roller>
            </ng-container>
            <ng-container *ngIf="activityTypeSlug === 'multiple-choice' && activity?.configuration.activityMultipleChoice">
                <app-activity-multiple-choice [(activityMultipleChoice)]="activity?.configuration.activityMultipleChoice" [emptyMandatoryFields]="emptyMandatoryFields" (maxCharsReached)="maxCharsValidation($event)">
                </app-activity-multiple-choice>
            </ng-container>
        </div>
    </div>
</div>