<app-textfield
    label="Game URL"
    [(value)]="content.url"
    [error]="emptyMandatoryFields.includes('url') ? 'This field is required.' : ''"
    data-cy="game-url-input"
></app-textfield>
<app-textarea
    label="Game Config"
    [(value)]="content.configFile"
></app-textarea>
<app-textfield
    label="Title"
    [(value)]="content.title"
    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
    data-cy="title-input"
></app-textfield>
<app-richtextarea
    label="Description"
    data-cy="description-input"
    placeholder="Place your text"
    [(value)]="content.description"    
>
</app-richtextarea>