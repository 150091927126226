import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../form/button/button.module';
import { ListToolbarComponent } from './list-toolbar.component';
import { TextfieldModule } from '../../form/textfield/textfield.module';

@NgModule({
    declarations: [
        ListToolbarComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        TextfieldModule,
    ],
    exports: [
        ListToolbarComponent
    ]
})
export class ListToolbarModule { }