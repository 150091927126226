<div class="{{inlineLabel ? 'inline' : ''}}">
    <label *ngIf="label">{{label}}</label>
    <select 
        [disabled]="disabled" 
        (change)="onChange($event)"
        [ngClass]="[error ? 'error' : '', feedback || '', size || '']"
    >
        <option 
            [value]="option.id" 
            *ngFor="let option of options" 
            [selected]="option.id == value"
        >
            {{option.label}}
        </option>
    </select>
    <div *ngIf="error">{{error}}</div>
</div>