import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Skill } from 'src/app/models/skill/skill.model';
import { SkillService } from 'src/app/services/skill.service';
import { DialogService } from 'src/app/services/dialog.service';
import { formatError } from 'src/app/utils/errorMessage.formatter';

@Component({
    selector: 'skills-editor',
    templateUrl: './skills-editor.component.html',
    styleUrls: ['./skills-editor.component.scss']
})
export class SkillsEditorComponent {
    skill:Skill = {
        order: 0,
        title: "",
        skillId: "",
        subtitle: "",
        primaryColor: "",
        secondaryColor: ""
    };
    saving:boolean;
    loading:boolean;
    deleting:boolean;
    emptyMandatoryFields = Array<string>();
    availableOrders:Array<{id:string, label:string}> = [];
    
    constructor(
        private router:Router,
        private route:ActivatedRoute, 
        private skillService:SkillService,
        private dialogService:DialogService
    ) { 
        this.route.params.subscribe(data => {
            this.loading = true;
            this.availableOrders = [];
            this.skillService.list()
                .subscribe(response => {
                    for (var i = 0; i < response.totalResults; i++) {
                        var order = (i + 1).toString();
                        this.availableOrders.push({
                            id: order, 
                            label: order
                        });
                    }

                    if (data.skillId === "new") {
                        this.loading = false;
                        var newOrder = (this.availableOrders.length + 1).toString();
                        this.availableOrders.push({
                            id: newOrder, 
                            label: newOrder
                        });
                        this.skill.order = this.availableOrders.length;
                        return;
                    }

                    this.load(data.skillId);
                });
        });
    }

    load(skillId:string) {
        this.skillService.get(skillId)
            .subscribe(response => {
                this.loading = false;
                this.skill = response.skill;
                if (!this.skill.tags)
                    this.skill.tags = [];
            }, errors => {
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors?.error));
                this.goBack();
            });
    }

    allFieldsAreValid():boolean {
        this.emptyMandatoryFields = [];
        if (!this.skill.title)
            this.emptyMandatoryFields.push("title");
        if (!this.skill.primaryColor)
            this.emptyMandatoryFields.push("primaryColor");
        if (!this.skill.secondaryColor)
            this.emptyMandatoryFields.push("secondaryColor");

        if (this.emptyMandatoryFields.length > 0) {
            setTimeout(() => {
                var errorMessage = document.querySelector(".error-message");
                errorMessage?.scrollIntoView({ behavior: "smooth" });
            }, 1000);
        }
        
        return this.emptyMandatoryFields.length <= 0;
    }

    save() {
        if (!this.allFieldsAreValid())
            return;

        this.saving = true;
        this.skillService.save(this.skill).subscribe(response => {
            this.saving = false;
            this.dialogService.showSuccessAlert("Save Successful.");
            if (response)
                this.router.navigate(["/skills/" + response.skillId]);
        }, errors => {
            this.saving = false;
            this.dialogService.showErrorAlert(formatError(errors?.error));
        });
    }

    delete() {
        this.deleting = true;
        this.skillService.delete(this.skill.skillId).subscribe(() => {
            this.deleting = false;
            this.dialogService.showSuccessAlert("Delete Successful.");
            this.router.navigate(["/skills"]);
        });
    }

    confirmDelete() {
        this.dialogService.showConfirmationDialog({
            type: "danger",
            confirmButtonLabel: "Delete",
            onConfirmCallback: () => this.delete(),
            dialogIconUrl: "../../../../assets/images/icons/alert-circle.svg",
            headerMessage: "Warning: Deleting a skill will REMOVE " + 
                "related goals from the Full Curriculum page",
            confirmButtonIconUrl: "../../../../assets/images/icons/delete.svg",
            title: "Are you sure you want to remove " + 
                this.skill.title + " skill from the Curriculum?",
            message: "This action cannot be undone."
        });
    }

    goBack() {
        this.router.navigate(["/skills"]);
    }
}
