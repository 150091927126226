import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InteractiveActivityListParams } from 'src/app/models/interactive-activity/interactive-activity-list-params.model';
import { ActivitiesResponseList, IATypes, InteractiveActivity, InteractiveActivityResponse } from 'src/app/models/interactive-activity/interactive-activity.model';
import { InteractiveActivityService } from 'src/app/services/interactive-activity.service';

@Component({
  selector: 'app-interactive-vcw-picker',
  templateUrl: './interactive-vcw-picker.component.html',
  styleUrls: ['./interactive-vcw-picker.component.scss']
})
export class InteractiveVcwPickerComponent implements OnInit {

    @Input() interactiveActivityId: string;
    @Input() label: string;
    @Input() error: string;
    @Output() linkChange = new EventEmitter<string>();
    @Output() interactiveActivityIdChange = new EventEmitter<string>();
    @Output() contentChange = new EventEmitter<InteractiveActivity>();
    
    headers = [
        { label:"Title", key:"title", sortable: true },
        { label:"Date", key:"releaseDate", sortable: true },
        { label:"Select" },
    ];
    content: InteractiveActivity;
    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    activitiesList: ActivitiesResponseList;
    selectedPicker: "content";
    sortingParams: { key?: string, asc?: boolean };
    activitiesFilter: IATypes[];

    constructor(private iaService: InteractiveActivityService) { }

    ngOnInit(): void {
        this.loading = true;
        this.interactiveActivityId ? this.loadListById(this.interactiveActivityId) : this.loadList();
        
        if (this.content)
            this.selectedPicker = "content";
    }

    loadList(hideLoader?: boolean):void {
        var params: InteractiveActivityListParams = { page: this.currentPage, limit: 10 };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        params['interactiveActivityTypes'] = "mixed-activity";

        if (!hideLoader)
            this.loading = true;

        this.iaService.getList(params)
            .subscribe((response: ActivitiesResponseList) => {
                this.sorting = false;
                this.loading = false;

                response.interactiveActivities.forEach(c => {
                    c.createdAt = 
                        new Date(c.createdAt).toLocaleDateString('en-US');
                    c.updatedAt = 
                        new Date(c.updatedAt).toLocaleDateString('en-US');
                });
                this.activitiesList = response;
            });
    }

    loadListById(activityId: string) {
        this.iaService.getById(activityId).subscribe(
            (response: InteractiveActivityResponse) => {
                this.sorting = false;
                this.loading = false;

                this.content = response.interactiveActivity;
            }
        )
    }

    showContentPicker() {
        this.selectedPicker = "content";
    }

    onPageChange(newPage: number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term: string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key: string, asc: boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }

    onSelectContent(selectedContent: InteractiveActivity) {
        this.content = selectedContent;
        this.selectedPicker = undefined;
        this.contentChange.emit(selectedContent);
        this.interactiveActivityId = selectedContent.interactiveActivityId;
        this.interactiveActivityIdChange.emit(selectedContent.interactiveActivityId)
    }

    removeLinkedIa() {
        this.interactiveActivityId = null;
        this.content = undefined;
        this.selectedPicker = undefined;
        this.interactiveActivityIdChange.emit(null);

        if (!this.activitiesList)
            this.loadList();

        this.cleanIAListVariables();
    }

    cleanIAListVariables() {
        if (this.searchTerm || this.activitiesFilter.length > 0 || this.sortingParams) {
            this.searchTerm = undefined;
            this.activitiesFilter = [];
            this.sortingParams = undefined;
            this.loadList();
        }
    }
}
