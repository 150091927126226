<button 
    [shadow]="false"
    show-delay="200"
    placement="bottom" 
    [tooltip]="tooltip" 
    [tooltip-class]="tooltip ? 'tp' : 'tp-hidden'"
    [ngClass]="[type, layout, size, iconUrl ? 'icon' : '']" 
    [class.disable-btn]="disableBtn"
    [disabled]="disableBtn"
>
    <img *ngIf="iconUrl && !loading" src="{{iconUrl}}" alt="ico" />
    <app-spinner *ngIf="loading"></app-spinner>
    <ng-content></ng-content>
</button>
