import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SkillService } from 'src/app/services/skill.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SkillList } from 'src/app/models/skill/skill-list.model';
import { formatError } from 'src/app/utils/errorMessage.formatter';
import { ListParams } from 'src/app/models/list-params/list-params.model';

@Component({
  selector: 'skills-list',
  templateUrl: './skills-list.component.html',
  styleUrls: ['./skills-list.component.scss']
})
export class SkillsListComponent implements OnInit {
    headers = [
        { label:"No", key:"order", sortable: true },
        { label:"Skill Name", key:"title", sortable: true },
        { label:"Actions" },
    ];

    currentPage = 1;
    searchTerm = "";
    sorting = false;
    loading = false;
    skillsList:SkillList;
    sortingParams:{ key?:string, asc?:boolean };

    constructor(
        private router:Router,
        private authService:AuthService,
        private skillService:SkillService,
        private dialogService:DialogService,
    ) { }

    ngOnInit():void {
        this.loadList();
    }

    loadList(hideLoader?:boolean):void {
        var params:ListParams = { page: this.currentPage };
        if (this.searchTerm) 
            params.text = this.searchTerm;
        
        if (this.sortingParams) {
            params.orderBy = this.sortingParams.key;
            params.sort = this.sortingParams.asc ? "asc" : "desc";
        }

        if (!hideLoader)
            this.loading = true;

        this.skillService.list(params)
            .subscribe(response => {
                this.sorting = false;
                this.loading = false;
                this.skillsList = response;
            }, 
            errors => {
                this.sorting = false;
                this.loading = false;
                this.dialogService.showErrorAlert(formatError(errors));
                if (errors.status === 401) {
                    this.authService.clearSession();
                    this.router.navigate(['/login']);
                }
            }
        );
    }

    onPageChange(newPage:number) {
        this.currentPage = newPage;
        this.loadList();
    }

    onSearchChange(term:string) {
        this.searchTerm = term;
        this.currentPage = 1;
        this.loadList();
    }

    onSortChange(params:{ key:string, asc:boolean }) {
        this.sorting = true;
        this.sortingParams = params;
        this.loadList(true);
    }
}
