<app-header>
    <a routerLink="/contents" data-cy="back-to-content-btn">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading" class="content-title" data-cy="content-title">
        {{!content.contentId ? 
            "New " + 
                (contentTypeSlug === "edsw" ? "EDSW" : contentTypeSlug) + 
                " content" 
            : 
            content.title
        }}
    </span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
            data-cy="save-content-btn"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <div class="tabs">
        <button 
            (click)="activeTab = 0"
            [class]="activeTab === 0 ? 'active' : ''">
            Content Info
        </button>
        <button
            (click)="activeTab = 1" 
            [class]="activeTab === 1 ? 'active' : ''"
            data-cy="social-communication-btn"
        >
            Social Communication
        </button>
        <button
            (click)="activeTab = 2" 
            [class]="activeTab === 2 ? 'active' : ''"
        >
            SEL Curriculum
        </button>
    </div>
    <ng-container *ngIf="!loading">
        <div class="flex-row" *ngIf="activeTab === 0">
            <div class="column">
                <content-activity 
                    [(content)]="content" 
                    *ngIf="contentTypeSlug === 'activity'"
                    [emptyMandatoryFields]="emptyMandatoryFields"
                ></content-activity>

                <content-video 
                    [(content)]="content" 
                    *ngIf="contentTypeSlug === 'video'"
                    [emptyMandatoryFields]="emptyMandatoryFields"
                ></content-video>

                <content-game 
                    [(content)]="content" 
                    *ngIf="contentTypeSlug === 'game'"
                    [emptyMandatoryFields]="emptyMandatoryFields"
                ></content-game>

                <content-edsw 
                    [(content)]="content" 
                    *ngIf="contentTypeSlug === 'edsw'"
                    [emptyMandatoryFields]="emptyMandatoryFields"
                ></content-edsw>

                <app-tagfield
                    label="Tags"
                    [(values)]="content.tags"
                ></app-tagfield>
            </div>
            <div class="column">
                <ng-container *ngIf="content.thumbnail || contentTypeSlug === 'edsw' || contentTypeSlug === 'game'">
                    <div class="thumb-preview">
                        <img 
                            class="{{!content.thumbnail ? 'placeholder' : ''}}"
                            [src]="content.thumbnail || '../../../../assets/images/icons/video-image-placeholder.svg'" 
                        />
                    </div>
                    <app-file-picker
                        id="image"
                        type="image"
                        label="Thumbnail"
                        [value]="content.thumbnail"
                        (fileChange)="content.thumbnailFile = $event; !$event && content.thumbnail = ''"
                        [error]="emptyMandatoryFields.includes('thumbnail') ? 'This field is required.' : ''"
                    ></app-file-picker>
                </ng-container>
                <app-textfield
                    type="date"
                    size="small"
                    label="Release Date"
                    [(value)]="content.releaseDate"
                    iconUrl="../../../../assets/images/icons/calendar.svg"
                    [error]="emptyMandatoryFields.includes('releaseDate') ? 'This field is required.' : ''"
                ></app-textfield>
                <app-multi-select
                    size="small"
                    label="Curriculum"
                    [options]="availableCurriculums"
                    [(selectedOptions)]="content.curriculums"
                    *ngIf="availableCurriculums && content.curriculums"
                    [error]="emptyMandatoryFields.includes('curriculums') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-multi-select
                    size="small"
                    label="Plan"
                    [options]="availablePlans"
                    [(selectedOptions)]="content.plans"
                    *ngIf="availablePlans && content.plans"
                    [error]="emptyMandatoryFields.includes('plans') ? 'This field is required.' : ''"
                ></app-multi-select>
                <app-list-item-picker
                    label="Difficulty"
                    data-cy="difficulty-picker"
                    [options]="availableLevels"
                    [(selectedOptions)]="content.levels"
                ></app-list-item-picker>
                <app-switch
                    id="active"
                    label="Active"
                    [(value)]="content.active"
                ></app-switch>
            </div>
        </div>
        <ng-container *ngIf="activeTab === 1">
            <div class="flex-row">
                <div class="column">
                    <app-list-item-picker
                        label="Age"
                        data-cy="age-picker"
                        [options]="availableSccAges"
                        [(selectedOptions)]="content.sccAges"
                        [error]="emptyMandatoryFields.includes('sccAges') ? 'This field is required.' : ''"
                    ></app-list-item-picker>
                    <div class="selected-items">
                        <div class="item" *ngFor="let g of content.goals">
                            {{getGoal(g)}}
                        </div>
                    </div>
                </div>
                <div class="column fill">
                    <app-list-item-picker
                        layout="alt"
                        label="Goals"
                        [(selectedOptions)]="content.goals"
                        [groupedOptions]="availableGoalsGrouped"
                    ></app-list-item-picker>
                </div>
            </div>  
            <div class="flex-row divider">
                <app-wtd-section
                    type="sc"
                    label="WTD"
                    [whatToDo]="content.sccToDo"
                    [errors]="emptyMandatoryFields"
                    [numberOfFields]="wtdFieldsCount"
                    (whatToDoChange)="onSccToDoChange($event)"
                ></app-wtd-section>
            </div>   
        </ng-container> 
        <ng-container *ngIf="activeTab === 2">
            <div class="flex-row">
                <div class="column">
                    <app-list-item-picker
                        label="Age"
                        [options]="availableSelAges"
                        [(selectedOptions)]="content.selAges"
                        [error]="emptyMandatoryFields.includes('selAges') ? 'This field is required.' : ''"
                    ></app-list-item-picker>
                </div>
            </div>  
        </ng-container> 
        <div class="dangerous-area" *ngIf="content.contentId">
            <app-button
                type="danger"
                [loading]="deleting"
                (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
