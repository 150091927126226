<app-header>
    <a routerLink="/sel-ages">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!age.ageId ? "New" : age.title}} Age Track</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <div class="column">
                <app-select
                    size="small"
                    label="Order"
                    [(value)]="age.order"
                    [options]="availableOrders"
                ></app-select>
                <app-textfield
                    [(value)]="age.title"
                    label="Age Track Title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
                <app-file-picker
                    type="image"
                    size="medium"
                    id="grade-header-mascot"
                    [value]="age.mascotHeader"
                    label="Grade Header Mascot (SVG)"
                    (fileChange)="age.gradeHeaderMascotFile = $event; !$event && age.mascotHeader = ''"
                    [error]="emptyMandatoryFields.includes('mascotHeader') ? 'This field is required.' : ''"
                ></app-file-picker>
                <app-file-picker
                    type="pdf"
                    size="medium"
                    id="scope-pdf"
                    [value]="age.scopeFile"
                    label="Scope and Sequence Document"
                    (fileChange)="age.scopePdfFile = $event; !$event && age.scopeFile = ''"
                    [error]="emptyMandatoryFields.includes('scopeFile') ? 'This field is required.' : ''"
                ></app-file-picker>
                <app-file-picker
                    type="pdf"
                    size="medium"
                    id="implementation-guide"
                    [value]="age.implementationGuide"
                    label="Implementation Guide Document (PDF) (Optional)"
                    (fileChange)="age.implementationGuideFile = $event; !$event && age.implementationGuide = ''"
                    [error]="emptyMandatoryFields.includes('implementationGuide') ? 'This field is required.' : ''"
                ></app-file-picker>
                <app-color-picker
                    placeholder="#000000"
                    label="Primary color"
                    [(value)]="age.primaryColor"
                    [error]="emptyMandatoryFields.includes('primaryColor') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    placeholder="#000000"
                    label="Background-light"
                    [(value)]="age.secondaryColor"
                    [error]="emptyMandatoryFields.includes('secondaryColor') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    placeholder="#000000"
                    label="Gradient start"
                    [(value)]="age.gradientStart"
                    [error]="emptyMandatoryFields.includes('gradientStart') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    placeholder="#000000"
                    label="Gradient end"
                    [(value)]="age.gradientEnd"
                    [error]="emptyMandatoryFields.includes('gradientEnd') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    placeholder="#000000"
                    label="Assessment"
                    [(value)]="age.accentColorOne"
                    [error]="emptyMandatoryFields.includes('accentColorOne') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    label="Progress Bar"
                    placeholder="#000000"
                    [(value)]="age.accentColorTwo"
                    [error]="emptyMandatoryFields.includes('accentColorTwo') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    label="Hint"
                    placeholder="#000000"
                    [(value)]="age.accentColorThree"
                    [error]="emptyMandatoryFields.includes('accentColorThree') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    label="Button Color"
                    placeholder="#000000"
                    [(value)]="age.btnColor"
                    [error]="emptyMandatoryFields.includes('btnColor') ? 'This field is required.' : ''"
                ></app-color-picker>
                <app-color-picker
                    label="Title Text"
                    placeholder="#000000"
                    [(value)]="age.titleColor"
                    [error]="emptyMandatoryFields.includes('titleColor') ? 'This field is required.' : ''"
                ></app-color-picker>
            </div>
            <div class="column">
                <div class="thumb-preview">
                    <img 
                        class="{{!age.banner ? 'placeholder' : ''}}"
                        [src]="age.banner || '../../../../assets/images/icons/image-placeholder-large.svg'" 
                    />
                </div>
                <app-file-picker
                    id="banner"
                    type="image"
                    label="Banner"
                    [value]="age.banner"
                    (fileChange)="age.bannerFile = $event; !$event && age.banner = ''"
                    [error]="emptyMandatoryFields.includes('banner') ? 'This field is required.' : ''"
                ></app-file-picker>
                <div class="thumb-picker">
                    <img [src]="age.image || '../../../../assets/images/icons/image-placeholder.svg'" />
                    <app-file-picker
                        id="image"
                        type="json"
                        size="small"
                        [value]="age.image"
                        label="Mascot Image"
                        (fileChange)="age.imageFile = $event; !$event && age.image = ''"
                        [error]="emptyMandatoryFields.includes('image') ? 'This field is required.' : ''"
                    ></app-file-picker>
                </div>
                <div class="thumb-picker">
                    <app-lottie-player
                        *ngIf="age.mascot"
                        height="120"
                        [loop]="true"
                        [autoplay]="true"
                        [url]="age.mascot"
                        [showControls]="true"
                    ></app-lottie-player>
                    <img *ngIf="!age.mascot" src="../../../../assets/images/icons/image-placeholder.svg" />
                    <app-file-picker
                        type="json"
                        size="small"
                        id="mascot-json"
                        [value]="age.mascot"
                        label="Mascot Lottie File (JSON) (Optional)"
                        (fileChange)="age.mascotFile = $event; !$event && age.mascot = ''"
                    ></app-file-picker>
                </div>
            </div>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
