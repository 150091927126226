import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { Skill } from "../models/skill/skill.model";
import { SkillList } from "../models/skill/skill-list.model";
import { ListParams } from "../models/list-params/list-params.model";

@Injectable({
    providedIn: 'root'
})
export class SkillService {
    constructor(private http:HttpService) { }

    list(params?:ListParams):Observable<SkillList> {
        return this.http.get('admin/skills', params);
    }

    get(skillId:string):Observable<any> {
        return this.http.get('admin/skills/' + skillId);
    }

    save(skill:Skill):Observable<any> {
        if (skill.skillId)
            return this.http.put('admin/skills/' + skill.skillId, skill);
        
        return this.http.post('admin/skills', skill);
    }

    delete(skillId:string):Observable<any> {
        return this.http.delete('admin/skills/' + skillId);
    }
}
