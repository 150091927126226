<app-header>
    <a routerLink="/units">
        <img src="../../../../assets/images/icons/chevron-left.svg" alt="back"/>
    </a>
    <span *ngIf="!loading">{{!unit.unitId ? "New" : ""}} Social Communication Unit</span>
    <div *ngIf="!loading" class="actions">
        <app-button 
            type="primary" 
            [loading]="saving"
            (click)="trySave()"
            iconUrl="../../../../assets/images/icons/save.svg"
        >
            Save
        </app-button>
        <app-button 
            type="warning" 
            (click)="goBack()"
            tooltip="Go back without saving"
            iconUrl="../../../../assets/images/icons/cancel.svg"
        >
            Cancel
        </app-button>
    </div>
</app-header>
<div class="form">
    <ng-container *ngIf="!loading">
        <div class="flex-row">
            <app-select
                size="small"
                label="Parent Goal"
                [(value)]="unit.goalId"
                [options]="availableGoals"
                (valueChange)="onGoalChange($event)"
            ></app-select>
            <app-select
                size="small"
                label="Age"
                [(value)]="unit.ageId"
                [options]="availableAges"
                (valueChange)="onAgeChange($event)"
            ></app-select>
            <app-select
                size="small"
                [(value)]="unit.order"
                [options]="availableOrders"
                label="Order in Parent Goal"
            ></app-select>
        </div>
        <div class="flex-row">
            <div class="column">
                <app-textfield
                    label="Unit No (optional)"
                    [(value)]="unit.unitNumber"
                ></app-textfield>
                <app-textfield
                    label="Unit title"
                    [(value)]="unit.title"
                    [error]="emptyMandatoryFields.includes('title') ? 'This field is required.' : ''"
                ></app-textfield>
            </div>
        </div>
        <div class="flex-row">
            <div class="contents-container">
                <ng-container *ngFor="let c of unit.contentDetails;let i = index">
                    <div class="content-title">
                        {{contentsTitles[i]}}
                    </div>
                    <app-content-picker
                        layout="row"
                        [title]="contentsTitles[i]"
                        [content]="unit.contentDetails[i]"
                        (contentChange)="onContentDetailsChange($event, i)"
                        [error]="emptyMandatoryFields.includes('content' + i) ? 'This field is required.' : ''"
                    ></app-content-picker>
                </ng-container>
            </div>
        </div>
        <div class="dangerous-area" *ngIf="unit.unitId">
            <app-button
                type="danger"
                [loading]="deleting"
                (click)="confirmDelete()"
                iconUrl="../../../../assets/images/icons/delete.svg"
            >
                Delete
            </app-button>
        </div>
    </ng-container>
    <app-loader *ngIf="loading"></app-loader>
</div>
