import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-tagfield',
    templateUrl: './tagfield.component.html',
    styleUrls: ['./tagfield.component.scss']
})
export class TagfieldComponent implements OnInit {
    @Input() label:string;
    @Input() error:string;
    @Input() disabled:boolean;
    @Input() placeholder = "";
    @Input() values:Array<string> = [];
    @Input() size:"small"|"medium"|"large" = "medium";
    @Input() feedback:"success"|"info"|"warning"|"error";

    @Output() valuesChange = new EventEmitter<Array<string>>();

    focusing = false;
    currentValue = "";

    constructor() { }

    ngOnInit(): void {
        if (!this.values)
            this.values = [];
    }

    onChange() {
        this.valuesChange.emit(this.values);
    }

    addTag() {
        this.values.push(this.currentValue);
        this.currentValue = "";
        this.onChange();
    }

    deleteTag(idx:number) {
        this.values.splice(idx, 1);
        this.onChange();
    }
}
