import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilePickerComponent } from './file-picker.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
    declarations: [
        FilePickerComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
    ],
    exports: [
        FilePickerComponent
    ]
})
export class FilePickerModule { }