import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { SkillsEditorComponent } from './skills-editor.component';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ButtonModule } from 'src/app/shared/form/button/button.module';
import { TagfieldModule } from 'src/app/shared/form/tagfield/tagfield.module';
import { TextareaModule } from 'src/app/shared/form/textarea/textarea.module';
import { TextfieldModule } from 'src/app/shared/form/textfield/textfield.module';
import { ColorPickerModule } from '../../../shared/form/color-picker/color-picker.module';

@NgModule({
    declarations: [
        SkillsEditorComponent
    ],
    imports: [
        FormsModule,
        SharedModule,
        CommonModule,
        ButtonModule,
        RouterModule,
        SelectModule,
        TextareaModule,
        TagfieldModule,
        TextfieldModule,
        ColorPickerModule
    ],
})
export class SkillsEditorModule { }