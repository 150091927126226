import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private http: HttpClient, private storage: StorageService) { }

    put(request, obj = {}):Observable<any> {
        var options = {
            headers: this.getHeaders()
        };

        return this.http.put(
            environment.apiUrl + request, 
            JSON.stringify(obj), 
            options
        );
    }

    post(request, obj = {}):Observable<any> {
        var options = {
            headers: this.getHeaders()
        };

        return this.http.post(
            environment.apiUrl + request,
            JSON.stringify(obj), 
            options
        );
    }

    get(request, params?):Observable<any> {
        var options:any = {
            headers: this.getHeaders()
        };

        if (params)
            options.params = params;

        return this.http.get(environment.apiUrl + request, options);
    }

    delete(request, params?):Observable<any> {
        var options:any = {
            headers: this.getHeaders()
        };

        if (params)
            options.params = params;

        return this.http.delete(environment.apiUrl + request, options);
    }

    uploadMedia(file:File, type:"video"|"image"|"file"):Observable<any> {
        var preset = "";
        if (type === "video")
            preset = "cms-video-preset";
        else if (type === "image")
            preset = "cms-image-preset";
        else
            preset = "cms-file-preset";        
        
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", preset);
        
        return this.http.post(
            environment.cloudinaryUrl,
            formData
        );
    }

    getHeaders():HttpHeaders {
        var headers = new HttpHeaders({
            'Content-Type': 'application/json'            
        });
        
        const token = this.storage.getToken();
        if (token)
            headers = headers.append("Authorization", token);

        return headers;
    } 
}
