import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SccFeaturedContentComponent } from './scc-featured-content.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListToolbarModule } from 'src/app/shared/list/list-toolbar/list-toolbar.module';
import { SelectModule } from 'src/app/shared/form/select/select.module';
import { ContentPickerModule } from 'src/app/shared/form/content-picker/content-picker.module';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    SccFeaturedContentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ListToolbarModule,
    SelectModule,
    ContentPickerModule,
    PipesModule
  ]
})
export class SccFeaturedContentModule { }
